import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_HOTEL_WIDGETS, RECEIVE_GET_HOTEL_WIDGET } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, templateId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_WIDGETS:
      return new OrderedMap(data.hotel_widgets.map(widget => [widget.id, fromJS(widget)]))

    case RECEIVE_GET_HOTEL_WIDGET:
      return state.set(data.hotel_widget.id, fromJS(data.hotel_widget))

    default:
      return state
  }
}
