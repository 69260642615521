import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loadHotelNpsDetails, loadHotelTags, createHotelNpsDetails } from '../actions/HotelActions'
import { fromJS, is } from 'immutable'
import Select from 'react-select'
import Textarea from 'react-textarea-autosize'
import alertify from 'alertifyjs'

const dummyItem = fromJS({
  lower_rating_scale: 0,
  upper_rating_scale: 0,
  is_passive: false,
  assign_default_team: false,
  is_resolvable: false,
  bot_response: '',
  tag: {}
})

const dummyError = fromJS({
  tagErr: '',
  lowerRatingErr: '',
  upperRatingErr: '',
  botResponseErr: ''
})

class EditNPSSettings extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      lowerRatingScale: 0,
      upperRatingScale: 0,
      npsTags: fromJS([dummyItem]),
      selectedHotelTags: [],
      isSaving: false,
      errors: fromJS([])
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelNpsDetails(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
    dispatch(loadHotelTags(selectedHotelId))
  }

  componentDidUpdate = prevProps => {
    if (prevProps && this.props && !is(prevProps.npsSettings, this.props.npsSettings)) {
      this.setState({
        lowerRatingScale: this.props.npsSettings.get('lower_rating_scale'),
        upperRatingScale: this.props.npsSettings.get('upper_rating_scale'),
        npsTags: fromJS(this.props.npsSettings.get('nps_tags')),
        errors: fromJS(Array(this.props.npsSettings.get('nps_tags').length).fill({}))
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({ [e.target.name + 'Err']: '' })
  }

  handleNpsTagChange = (e, index) => {
    let updatedTags = this.state.npsTags
    let updatedErrors = this.state.errors
    const value = e.target.value

    if (e.target.name === 'tagLowerRatingScale') {
      updatedTags = updatedTags.setIn([index, 'lower_rating_scale'], value)
      updatedErrors = updatedErrors.setIn([index, 'lowerRatingErr'], '')
    } else if (e.target.name === 'tagUpperRatingScale') {
      updatedTags = updatedTags.setIn([index, 'upper_rating_scale'], value)
      updatedErrors = updatedErrors.setIn([index, 'upperRatingErr'], '')
    } else if (e.target.name === 'botResponse') {
      updatedTags = updatedTags.setIn([index, 'bot_response'], value)
      updatedErrors = updatedErrors.setIn([index, 'botResponseErr'], '')
    }

    this.setState({ npsTags: updatedTags, errors: updatedErrors })
  }

  handleCheckboxChange = (name, index) => {
    let updatedTags = this.state.npsTags
    if (name === 'isResolvable') {
      updatedTags = updatedTags
        .setIn([index, 'is_resolvable'], true)
        .setIn([index, 'assign_default_team'], false)
    } else if (name === 'assignDefaultTeam') {
      updatedTags = updatedTags
        .setIn([index, 'assign_default_team'], true)
        .setIn([index, 'is_resolvable'], false)
    }
    this.setState({ npsTags: updatedTags })
  }

  getTagOptions = hotelTags => {
    if (!hotelTags || !hotelTags.size) return []
    const options = []
    hotelTags.get('tags').map(tag => {
      options.push({ value: tag, label: tag.name })
    })
    return options
  }

  onSelectHotelTag = (option, index) => {
    this.setState({
      npsTags: this.state.npsTags.setIn([index, 'tag'], fromJS(option.value)),
      errors: this.state.errors.setIn([index, 'tagErr'], '')
    })
  }

  addNewItem = () => {
    this.setState({
      npsTags: this.state.npsTags.unshift(dummyItem),
      errors: this.state.errors.unshift(dummyError)
    })
  }

  removeItem = index => {
    this.setState({
      npsTags: this.state.npsTags.splice(index, 1),
      errors: this.state.errors.splice(index, 1)
    })
  }

  handleSave = () => {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const { lowerRatingScale, upperRatingScale } = this.state
    const updatedNpsTags = this.getTagsToSend()
    const params = {
      lower_rating_scale: lowerRatingScale,
      upper_rating_scale: upperRatingScale,
      nps_tags: updatedNpsTags
    }
    if (!this.validateNpsDetails()) return
    this.setState({ isSaving: true })
    dispatch(
      createHotelNpsDetails(selectedHotelId, params, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error updating NPS details.')
        }

        alertify.success('NPS details successfully updated.')
        this.handleCancel()
      })
    )
  }

  validateNpsDetails = () => {
    const { lowerRatingScale, upperRatingScale, npsTags, errors } = this.state
    let newErrors = errors
    let isValid = true
    if (!lowerRatingScale || lowerRatingScale < 0 || lowerRatingScale > upperRatingScale) {
      this.setState({ lowerRatingScaleErr: 'Please enter a valid value' })
      isValid = false
    } else if (!upperRatingScale || upperRatingScale < 1) {
      isValid = false
      this.setState({ upperRatingScaleErr: 'Please enter a valid value' })
    }
    npsTags.map((npsTag, index) => {
      if (!npsTag.getIn(['tag', 'id'])) {
        newErrors = newErrors.setIn([index, 'tagErr'], 'Please select a tag')
        isValid = false
      } else if (!npsTag.get('lower_rating_scale') || npsTag.get('lower_rating_scale') < 0) {
        newErrors = newErrors.setIn([index, 'lowerRatingErr'], 'Please enter a valid value')
        isValid = false
      } else if (!npsTag.get('upper_rating_scale') || npsTag.get('upper_rating_scale') < 1) {
        newErrors = newErrors.setIn([index, 'upperRatingErr'], 'Please enter a valid value')
        isValid = false
      } else if (npsTag.get('lower_rating_scale') > npsTag.get('upper_rating_scale')) {
        newErrors = newErrors.setIn(
          [index, 'lowerRatingErr'],
          'Lower rating scale cannot be greater than upper rating scale'
        )
        isValid = false
      } else if (npsTag.get('lower_rating_scale') < lowerRatingScale) {
        newErrors = newErrors.setIn(
          [index, 'lowerRatingErr'],
          "Tag lower rating scale cannot be less than hotel's lower rating scale"
        )
        isValid = false
      } else if (npsTag.get('upper_rating_scale') > upperRatingScale) {
        newErrors = newErrors.setIn(
          [index, 'upperRatingErr'],
          "Tag upper rating scale cannot be greater than hotel's upper rating scale"
        )
        isValid = false
      } else if (!npsTag.get('bot_response')) {
        newErrors = newErrors.setIn([index, 'botResponseErr'], 'Please add the bot response')
        isValid = false
      }
    })
    this.setState({ errors: newErrors })
    return isValid
  }

  isPristine = () => {
    const { lowerRatingScale, upperRatingScale, npsTags } = this.state
    let isValid = true
    if (!lowerRatingScale || lowerRatingScale < 0 || lowerRatingScale > upperRatingScale)
      isValid = false
    else if (!upperRatingScale) isValid = false
    npsTags.map(npsTag => {
      if (
        !npsTag.getIn(['tag', 'id']) ||
        !npsTag.get('lower_rating_scale') ||
        !npsTag.get('upper_rating_scale') ||
        npsTag.get('lower_rating_scale') > npsTag.get('upper_rating_scale') ||
        npsTag.get('lower_rating_scale') < lowerRatingScale ||
        npsTag.get('upper_rating_scale') > upperRatingScale ||
        !npsTag.get('bot_response')
      ) {
        isValid = false
      }
    })
    return isValid
  }

  getTagsToSend = () => {
    let updatedTags = this.state.npsTags
    updatedTags = this.state.npsTags.map(npsTag =>
      npsTag.set('tag_id', npsTag.getIn(['tag', 'id']))
    )
    return updatedTags
  }

  handleCancel = () => {
    const { history, match } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/nps-details`)
  }

  render() {
    const { hotelTags } = this.props
    const {
      lowerRatingScale,
      upperRatingScale,
      npsTags,
      isSaving,
      lowerRatingScaleErr,
      upperRatingScaleErr,
      errors
    } = this.state

    return (
      <div className="settings-container nps-settings">
        <h2 className="tmargin0em">Edit NPS Settings</h2>
        <div>
          <div className="d-flex-center">
            <div style={{ width: '20%' }}>Lower Rating Scale</div>
            <div style={{ width: '20%' }}>
              <input
                className="settings-search-input"
                type="number"
                name="lowerRatingScale"
                onChange={this.handleChange}
                value={lowerRatingScale}
                placeholder="Lower rating scale"
              />
              <div className="nps-error">{lowerRatingScaleErr}</div>
            </div>
          </div>
          <div className="d-flex-center">
            <div style={{ width: '20%' }}>Upper Rating Scale</div>
            <div style={{ width: '20%' }}>
              <input
                className="settings-search-input"
                type="number"
                name="upperRatingScale"
                onChange={this.handleChange}
                value={upperRatingScale}
                placeholder="Upper rating scale"
              />
              <div className="nps-error">{upperRatingScaleErr}</div>
            </div>
          </div>
          <div className="display-flex-column" style={{ marginBottom: '2rem' }}>
            <div className="button pull-right" onClick={this.addNewItem}>
              Add New
            </div>
            <h3>Tags</h3>
          </div>
          {npsTags.map((npsTag, index) => {
            const tagValue = { value: npsTag.get('tag'), label: npsTag.getIn(['tag', 'name']) }
            return (
              <div className="nps-tag-wrapper">
                <div style={{ width: '100%' }}>
                  <div className="tag-selector">
                    <div style={{ width: '20%' }}>Tag</div>
                    <div style={{ width: '50%' }}>
                      <Select
                        options={this.getTagOptions(hotelTags)}
                        value={tagValue}
                        onChange={option => this.onSelectHotelTag(option, index)}
                        placeholder={'Select tag...'}
                        style={{ width: '100%' }}
                      />
                      <div className="nps-error margin0">
                        {errors.size ? errors.getIn([index, 'tagErr']) : ''}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '20%' }}>Lower Rating Scale</div>
                    <div style={{ width: '20%' }}>
                      <input
                        className="settings-search-input"
                        type="number"
                        name="tagLowerRatingScale"
                        onChange={e => this.handleNpsTagChange(e, index)}
                        value={npsTag.get('lower_rating_scale')}
                        placeholder="Lower rating scale"
                      />
                      <div className="nps-error">
                        {errors.size ? errors.getIn([index, 'lowerRatingErr']) : ''}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '20%' }}>Upper Rating Scale</div>
                    <div style={{ width: '20%' }}>
                      <input
                        className="settings-search-input"
                        type="number"
                        name="tagUpperRatingScale"
                        onChange={e => this.handleNpsTagChange(e, index)}
                        value={npsTag.get('upper_rating_scale')}
                        placeholder="Upper rating scale"
                      />
                      <div className="nps-error">
                        {errors.size ? errors.getIn([index, 'upperRatingErr']) : ''}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
                    <div style={{ width: '20%' }}>Bot Response</div>
                    <div style={{ width: '70%' }}>
                      <Textarea
                        rows={2}
                        maxRows={5}
                        name="botResponse"
                        value={npsTag.get('bot_response')}
                        onChange={e => this.handleNpsTagChange(e, index)}
                        required
                      />
                      <div className="nps-error margin0">
                        {errors.size ? errors.getIn([index, 'botResponseErr']) : ''}
                      </div>
                    </div>
                  </div>
                  <fieldset className="form-item">
                    <div>
                      <input
                        type="radio"
                        checked={npsTag.get('assign_default_team')}
                        onChange={() => this.handleCheckboxChange('assignDefaultTeam', index)}
                      />
                      &nbsp;&nbsp; Assign Default Team
                    </div>
                  </fieldset>
                  <fieldset className="form-item">
                    <div>
                      <input
                        type="radio"
                        checked={npsTag.get('is_resolvable')}
                        onChange={() => this.handleCheckboxChange('isResolvable', index)}
                      />
                      &nbsp;&nbsp; Resolve conversation
                    </div>
                  </fieldset>
                </div>
                <div>
                  <img
                    alt="delete"
                    src="/icons/delete-grey.svg"
                    onClick={() => this.removeItem(index)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            )
          })}
          {npsTags.length != 0 && (
            <div style={{ margin: '4rem 0' }}>
              <button
                disabled={isSaving}
                type="submit"
                className="button"
                onClick={this.handleSave}
              >
                Save
              </button>
              <button
                disabled={isSaving}
                type="cancel"
                className="button"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  npsSettings: state.npsSettings,
  hotelTags: state.hotelTags
})

export default connect(mapStateToProps)(EditNPSSettings)
