import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from './Loader'
import { loadHotelNpsDetails } from '../actions/HotelActions'

class NPSSettings extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelNpsDetails(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  getIcon = value => `icons/${value ? 'check-green.svg' : 'cross-red.svg'}`

  render() {
    const { match, npsSettings } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading } = this.state

    return (
      <div className="settings-container nps-settings display-flex-column">
        <Loader isLoading={isLoading} />

        <Link
          to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/nps-details/edit`}
          className="button pull-right"
        >
          {npsSettings && npsSettings.get('hotel_id') ? 'Edit' : 'Add New'}
        </Link>
        <h2 className="tmargin0em">NPS Settings</h2>
        {npsSettings && npsSettings.get('hotel_id') && (
          <div>
            <div className="d-flex-center margin0">
              <h4 className="margin0">Lower Rating Scale:</h4> &nbsp;&nbsp;&nbsp;
              {npsSettings.get('lower_rating_scale')}
            </div>
            <div className="d-flex-center margin0">
              <h4 className="margin0">Upper Rating Scale:</h4> &nbsp;&nbsp;&nbsp;
              {npsSettings.get('upper_rating_scale')}
            </div>
            <h3>Tags</h3>
            {npsSettings.get('nps_tags').map(npsTag => {
              const tag = npsTag.tag
              return (
                <div className="nps-tag-container">
                  <div className="tag" style={{ backgroundColor: tag.color }}>
                    {tag.name}
                  </div>
                  <div className="d-flex-center">
                    <div className="tag-label">Bot Response:</div>
                    <div className="bot-response" width="80%">
                      {npsTag.bot_response}
                    </div>
                  </div>
                  <div className="d-flex-center">
                    <div className="tag-label">Lower rating scale:</div>
                    <div className="rating-scale"> {npsTag.lower_rating_scale}</div>
                  </div>
                  <div className="d-flex-center">
                    <div className="tag-label">Upper rating scale:</div>
                    <div className="rating-scale">{npsTag.upper_rating_scale}</div>
                  </div>
                  <div className="d-flex-center">
                    <div className="tag-label">Resolve conversation:</div>
                    <div>
                      {' '}
                      <img src={this.getIcon(npsTag.is_resolvable)} width="24" height="24" />
                    </div>
                  </div>
                  <div className="d-flex-center">
                    <div className="tag-label">Assign Default Team:</div>
                    <div>
                      {' '}
                      <img src={this.getIcon(npsTag.assign_default_team)} width="24" height="24" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  npsSettings: state.npsSettings
})

export default connect(mapStateToProps)(NPSSettings)
