/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { Map, fromJS } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_LOGIN,
  RECEIVE_GET_ME,
  RECEIVE_UPDATE_ME,
  RECEIVE_ME_AVATAR_UPDATE
} = ActionTypes

const initialState = new Map()

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOGIN:
    case RECEIVE_GET_ME:
    case RECEIVE_UPDATE_ME:
      return state.merge(fromJS(action.data))

    case RECEIVE_ME_AVATAR_UPDATE:
      return state.merge(fromJS(action.data))

    default:
      return state
  }
}
