/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { Map } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_LOGIN, RECEIVE_LOGOUT } = ActionTypes

const accessToken = window.localStorage.getItem('accessToken')

const initialState = new Map({
  accessToken: accessToken
})

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOGIN:
      const { access_token } = action.data
      if (access_token && state.get('accessToken') !== access_token) {
        window.localStorage.setItem('accessToken', access_token)
        return state.set('accessToken', access_token)
      }
      return state

    case RECEIVE_LOGOUT:
      window.localStorage.removeItem('accessToken')
      return state.set('accessToken', null)

    default:
      return state
  }
}
