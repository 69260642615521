/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2019
 */

const ReplyTemplateCategories = {
  account_update: 'Account Update',
  payment_update: 'Payment Update',
  personal_finance_update: 'Personal Finance Update',
  shipping_update: 'Shipping Update',
  reservation_update: 'Reservation Update',
  issue_resolution: 'Issue Resolution',
  appointment_update: 'Appointment Update',
  transportation_update: 'Transportation Update',
  ticket_update: 'Ticket Update',
  alert_update: 'Alert Update'
}

export default ReplyTemplateCategories
