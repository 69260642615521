/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { is } from 'immutable'
import { push } from 'react-router-redux'

import { saveAccount, deleteAccount } from '../actions/AccountActions'

class EditAccount extends React.Component {
  static propTypes = {
    account: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      account: props.account
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      account: nextProps.account
    })
  }

  handleChange = ev => {
    this.setState({
      account: this.state.account.set(ev.target.name, ev.target.value)
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch } = this.props
    const { account } = this.state

    this.setState({ isSaving: true, error: null })
    dispatch(
      saveAccount(account.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body && body.error })
        }
        const accountId = body && body.id
        dispatch(push(`/account/${accountId}`))
      })
    )
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({ account: this.props.account })
  }

  handleDelete = ev => {
    ev.preventDefault()
    const confirmed = window.confirm('Are you sure you want to delete this account?')
    if (!confirmed) return

    const { dispatch } = this.props
    const { account } = this.state

    this.setState({ isDeleting: true, error: null })
    dispatch(
      deleteAccount(account.get('id'), (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body && body.error })
        }
        dispatch(push(`/account`))
      })
    )
  }

  isPristine = () => {
    const { isSaving } = this.state

    return is(this.state.account, this.props.account) || isSaving
  }

  render() {
    const { account, error } = this.state

    if (!account) return null

    return (
      <div className="account-settings">
        <div className="bmargin2em">
          {account.get('id') ? (
            [
              <Link
                key="create-new-hotel-link"
                to={`/account/${account.get('id')}/hotel/new`}
                className="button pull-right"
              >
                Create New Business
              </Link>,
              <h1 key="component-heading">Account</h1>
            ]
          ) : (
            <h1>Create New Account</h1>
          )}
        </div>
        <form onSubmit={this.handleSubmit}>
          <h1>
            <input
              type="text"
              name="name"
              value={account.get('name') || ''}
              onChange={this.handleChange}
              placeholder="Account Name"
            />
          </h1>

          <fieldset>
            <legend>Contact Info</legend>
            <input
              type="text"
              name="phone"
              value={account.get('phone') || ''}
              onChange={this.handleChange}
              placeholder="Phone Number"
            />
            <input
              type="email"
              name="email"
              value={account.get('email') || ''}
              onChange={this.handleChange}
              placeholder="Email ID"
            />
            <input
              type="url"
              name="website"
              value={account.get('website') || ''}
              onChange={this.handleChange}
              placeholder="Website URL"
            />
          </fieldset>

          {error && <fieldset className="error">{error}</fieldset>}

          <fieldset>
            <button disabled={this.isPristine()} type="submit" className="button">
              Save
            </button>
            <button type="cancel" className="button" onClick={this.handleReset}>
              Reset
            </button>
            {
              // account.get('id') && (
              //   <div className="pull-right">
              //     <button
              //       disabled={isDeleting}
              //       type="button"
              //       className="button button-delete delete pull-right"
              //       onClick={this.handleDelete}
              //     >
              //       Delete
              //     </button>
              //   </div>
              // )
            }
          </fieldset>
        </form>
      </div>
    )
  }
}

export default EditAccount
