/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2019
 */

const Languages = {
  af: 'afrikaans',
  sq: 'albanian',
  ar: 'arabic',
  be: 'belarusian',
  bg: 'bulgarian',
  ca: 'catalan',
  'zh-CN': 'chinese simplified',
  'zh-TW': 'chinese traditional',
  hr: 'croatian',
  cs: 'czech',
  da: 'danish',
  nl: 'dutch',
  en: 'english',
  et: 'estonian',
  tl: 'filipino',
  fi: 'finnish',
  fr: 'french',
  gl: 'galician',
  de: 'german',
  el: 'greek',
  iw: 'hebrew',
  hi: 'hindi',
  hu: 'hungarian',
  is: 'icelandic',
  id: 'indonesian',
  ga: 'irish',
  it: 'italian',
  ja: 'japanese',
  ko: 'korean',
  la: 'latin',
  lv: 'latvian',
  lt: 'lithuanian',
  mk: 'macedonian',
  ms: 'malay',
  mt: 'maltese',
  no: 'norwegian',
  fa: 'persian',
  pl: 'polish',
  pt: 'portuguese',
  ro: 'romanian',
  ru: 'russian',
  sr: 'serbian',
  sk: 'slovak',
  sl: 'slovenian',
  es: 'spanish',
  sw: 'swahili',
  sv: 'swedish',
  th: 'thai',
  tr: 'turkish',
  uk: 'ukrainian',
  vi: 'vietnamese',
  cy: 'welsh',
  yi: 'yiddish',
  un: 'unknown'
}

export default Languages
