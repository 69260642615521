import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_TAGS,
  RECEIVE_GET_BRAND_TAGS,
  RECEIVE_GET_BRAND_TAG,
  RECEIVE_BRAND_TAG_DELETE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_TAGS:
      return new OrderedMap(data)

    case RECEIVE_GET_BRAND_TAGS:
      return new OrderedMap(data.tags.map(tag => [tag.id, fromJS(tag)]))

    case RECEIVE_GET_BRAND_TAG:
      return state.set(data.tag.id, fromJS(data.tag))

    case RECEIVE_BRAND_TAG_DELETE:
      return state.remove(data.tag_id)

    default:
      return state
  }
}
