/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { Map } from 'immutable'

const initialState = Map({
  name: '',
  business_type: undefined,
  phone: '',
  imessage: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  address: '',
  email: '',
  website: '',
  enabled: true,
  classification: '',
  hotel_timezone: '',
  account_id: undefined,
  fallback_text: Map({
    salutation: null,
    last_name: null
  })
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
