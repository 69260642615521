/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import alertify from 'alertifyjs'

import Spinner from './Spinner'
import Loader from '../components/Loader'
import { loadUsers } from '../actions/SettingActions'

class SettingUsers extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string })
  }

  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      pageLength: 50
    }
  }

  componentDidMount() {
    this.fetchAdminUsers('isLoading')
  }

  fetchAdminUsers = loaderType => {
    const { dispatch } = this.props
    const { page, pageLength } = this.state
    const nextPage = page + 1

    this.setState({ page: nextPage, [loaderType]: true })
    dispatch(
      loadUsers(nextPage, pageLength, (statusCode, body) => {
        this.setState({ [loaderType]: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error loading admin users.')
        }

        const adminUsers = (body && body.admin_users) || []
        this.setState({ moreUsersExists: !_.isEmpty(adminUsers) })
      })
    )
  }

  getUserName = user => {
    return `${user.get('first_name')} ${user.get('last_name')}`
  }

  handleScroll = ev => {
    const { loadingMoreUsers, moreUsersExists } = this.state
    const target = ev.target
    const scrollBottom =
      this.adminUsersListWraper.clientHeight <= Math.ceil(target.scrollTop) + target.clientHeight

    if (scrollBottom && !loadingMoreUsers && moreUsersExists) {
      this.fetchAdminUsers('loadingMoreUsers')
    }
  }

  render() {
    const { users } = this.props
    const { isLoading, loadingMoreUsers } = this.state

    const sortedUsers = users.toList().sortBy(user => _.toLower(this.getUserName(user)))

    return (
      <div className="admin-user-settings display-flex-column" onScroll={this.handleScroll}>
        <Loader isLoading={isLoading} />
        <div ref={node => (this.adminUsersListWraper = node)}>
          <div className="header-items">
            <Link to={`/settings/users/new`} className="button pull-right">
              Add User
            </Link>

            <h1>
              Users <small className="entity-count">({users.size})</small>
            </h1>
          </div>

          <div className="staff-members">
            <table className="w-100pct">
              <tbody>
                {sortedUsers.map(user => (
                  <tr key={user.get('id')} className="staff-member">
                    <td>
                      <Link to={`/settings/users/${user.get('id')}`}>
                        <strong className="name">{this.getUserName(user)}</strong>
                        <small className="email">{user.get('email')}</small>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="spinner__wrapper">
          {loadingMoreUsers ? (
            <div className="messages__spinner list-style-none">
              <Spinner />
            </div>
          ) : (
            <div className="filler"> </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps)(SettingUsers)
