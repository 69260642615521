/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'

import RegExpUtils from '../utils/RegExpUtils'

class HighlightSearchMatches extends React.Component {
  render() {
    const { query, messageText } = this.props

    const exp = new RegExp(RegExpUtils.filter(query), 'i')

    return !query ? (
      <span>{messageText}</span>
    ) : (
      <span
        dangerouslySetInnerHTML={{
          __html: messageText.replace(exp, str => `<mark class='matched-text'>${str}</mark>`)
        }}
      />
    )
  }
}

export default HighlightSearchMatches
