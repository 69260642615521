/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { connect } from 'react-redux'

import EditAccount from './EditAccount'

class CreateNewAccount extends React.Component {
  render() {
    const { dispatch, account } = this.props

    return (
      <div className="item-container-main">
        <EditAccount dispatch={dispatch} account={account} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  account: state.account
})

export default connect(mapStateToProps)(CreateNewAccount)
