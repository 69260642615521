/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import HotelPlatform from './HotelPlatform'
import HotelPlatformConstants from '../constants/HotelPlatformConstants'
import RegExpUtils from '../utils/RegExpUtils'

import { loadIntegratedPlatforms } from '../actions/HotelPlatformActions'

class PlatformList extends React.Component {
  static propTypes = {
    platforms: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      query: ''
    }
  }

  addNewPlatform = platform => ev => {
    this.props.selectPlatform(platform)
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  render() {
    const { platforms } = this.props
    const { query } = this.state

    const exp = new RegExp(RegExpUtils.filter(query), 'i')
    const filteredPlatforms = platforms.filter(
      platform => !query || (platform.get('uiName') || '').search(exp) !== -1
    )

    return (
      <div>
        <h2 className="tmargin0em">Add New Platform </h2>
        <div>
          <input
            className="settings-search-input"
            type="search"
            onChange={this.handleSearch}
            value={query}
            placeholder="Search platform"
          />
        </div>

        <ul className="platform-list">
          {filteredPlatforms.map(platform => (
            <li key={platform.get('uiKey')} className="item">
              <img src={platform.get('logoUrl')} className={`logo ${platform.get('uiKey')}`} />
              <button
                className="button button-small create-button"
                onClick={this.addNewPlatform(platform)}
              >
                Add {platform.get('uiName')}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

class AddNewPlatform extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      platforms: HotelPlatformConstants
    }
  }

  componentDidMount() {
    const { dispatch, match, hotelPlatforms } = this.props
    const { selectedHotelId } = match && match.params
    if (!hotelPlatforms || hotelPlatforms.isEmpty()) {
      dispatch(loadIntegratedPlatforms(selectedHotelId, () => {}))
    }
  }

  selectPlatform = platform => {
    this.setState({ platform: platform.merge(platform.get('platformData')) })
  }

  render() {
    const { dispatch, match, hotelPlatforms } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { platforms, platform } = this.state
    return (
      <div className="settings-container display-flex-column">
        <div className="add-new-platform">
          {platform ? (
            <HotelPlatform
              dispatch={dispatch}
              selectedAccountId={selectedAccountId}
              selectedHotelId={selectedHotelId}
              platform={platform}
              hotelPlatforms={hotelPlatforms}
              platformsCount={hotelPlatforms.size + 1}
              platforms={platforms}
            />
          ) : (
            <PlatformList platforms={platforms} selectPlatform={this.selectPlatform} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelPlatforms: state.hotelPlatforms
})

export default connect(mapStateToProps)(AddNewPlatform)
