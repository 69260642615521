/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loader from './Loader'
import { loadFeatureFlags, updateFeatureFlag } from '../actions/HotelActions'

class HotelFeatureFlags extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { dispatch, selectedHotelId } = this.props

    this.setState({ isLoading: true })
    dispatch(
      loadFeatureFlags(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  toggleFlag = (flagKey, flag) => ev => {
    const { dispatch, selectedHotelId } = this.props

    const featureFlags = {
      [flagKey]: {
        value: !flag.get('value')
      }
    }

    this.setState({ updatingFlag: flagKey, error: null })
    dispatch(
      updateFeatureFlag(selectedHotelId, flagKey, featureFlags, (statusCode, body) => {
        this.setState({ updatingFlag: false })
        if (statusCode !== 200) {
          return this.setState({ error: body && body.error })
        }
      })
    )
  }

  render() {
    const { featureFlags } = this.props
    const { isLoading, updatingFlag, error } = this.state

    const sortedFlags = featureFlags.sortBy(
      (flag, key) => _.toLower(flag.get('display_name')) || key
    )

    return (
      <div className="section">
        <Loader isLoading={isLoading} />

        <h3 className="bmargin0-5em">Feature Flags </h3>

        <div className="feature-flags-wrapper">
          {sortedFlags.entrySeq().map(([flagKey, flag]) => (
            <div
              key={flagKey}
              className={`flag-item ${updatingFlag === flagKey && 'custom-disabled'}`}
              onClick={this.toggleFlag(flagKey, flag)}
            >
              <div className={`flag-select ${flag.get('value') ? 'selected' : ''}`}>
                {flag.get('value') && <img src={`/icons/select-tick.svg`} />}
              </div>
              <div className="flag-name">
                {flag.get('display_name') || flagKey.replace(/_/g, ' ')}
              </div>
              <div className="description" title={flag.get('description')}>
                {flag.get('description')}
              </div>
            </div>
          ))}
          {error && <div className="error tmargin1em">{error}</div>}
        </div>
      </div>
    )
  }
}

export default HotelFeatureFlags
