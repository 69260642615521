/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

/* eslint no-mixed-operators: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { Map } from 'immutable'

import ToggleShowPassword from '../components/ToggleShowPassword'
import { login } from '../actions/AuthActions'
import MeUtils from '../utils/MeUtils'

class Login extends React.Component {
  static propTypes = {
    location: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      data: new Map({
        email: '',
        password: ''
      }),
      error: null,
      showPassword: false
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch, location, history } = this.props
    const { state } = location
    const { data } = this.state

    dispatch(
      login(data.toJS(), (statusCode, body) => {
        if (statusCode !== 200) {
          return this.setState({ error: body && body.error })
        }

        if (MeUtils.is2faInactive(body)) {
          history.push((state && state.nextPathname) || '/account')
        } else if (body.two_fa_setup_needed) {
          history.replace({
            pathname: '/setup-2fa',
            state: { qrCodeUri: body.qr_code_uri }
          })
        } else if (body.otp_required) {
          history.replace('/otp-2fa')
        }
      })
    )
  }

  handleChange = ev => {
    const { data } = this.state
    this.setState({
      data: data.set(ev.target.name, ev.target.value),
      error: null
    })
  }

  isPristine = () => {
    const { data } = this.state
    return !data.get('email').length || !data.get('password').length
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const { data, error, showPassword } = this.state

    const passwordType = showPassword ? 'text' : 'password'

    return (
      <div className="login">
        <form onSubmit={this.handleSubmit} className="card">
          <label htmlFor="email" className="subtle">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={this.handleChange}
            value={data.get('email')}
            required
          />

          <label htmlFor="password" className="subtle">
            Password
          </label>
          <input
            type={passwordType}
            name="password"
            id="password"
            className="rpadding3em"
            onChange={this.handleChange}
            value={data.get('password')}
            required
          />

          {data.get('password') && (
            <ToggleShowPassword
              showPassword={showPassword}
              handleShowPassword={this.handleShowPassword}
            />
          )}

          {error && <div className="error">{error}</div>}
          <button type="submit" disabled={this.isPristine()} className="button">
            Login!
          </button>

          <p>
            Forgot your password? — <Link to="/forgot_password">Reset Password</Link>!
          </p>
        </form>
      </div>
    )
  }
}

export default withRouter(Login)
