/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import auth from './auth'
import me from './me'
import account from './account'
import accounts from './accounts'
import businessType from './businessType'
import businessTypes from './businessTypes'
import permissions from './permissions'
import featureFlags from './featureFlags'
import hotel from './hotel'
import hotels from './hotels'
import hotelPlatforms from './hotelPlatforms'
import hotelIntegrations from './hotelIntegrations'
import hotelSettings from './hotelSettings'
import hotelTeam from './hotelTeam'
import hotelTeams from './hotelTeams'
import hotelTimezones from './hotelTimezones'
import hotelUser from './hotelUser'
import hotelUsers from './hotelUsers'
import loader from './loader'
import loginCreds from './loginCreds'
import replyTemplate from './replyTemplate'
import replyTemplates from './replyTemplates'
import user from './user'
import users from './users'
import quickReply from './quickReply'
import quickReplies from './quickReplies'
import hotelVerifiedIntegration from './hotelVerifiedIntegration'
import webWidget from './webWidget'
import webWidgets from './webWidgets'
import subProperty from './hotelSubProperty'
import subProperties from './hotelSubProperties'
import npsSettings from './npsSettings'
import hotelTags from './hotelTags'
import hotelTag from './hotelTag'
import salutations from './salutations'

export default combineReducers({
  router: routerReducer,
  auth,
  me,
  account,
  accounts,
  businessType,
  businessTypes,
  permissions,
  featureFlags,
  hotel,
  hotels,
  hotelPlatforms,
  hotelIntegrations,
  hotelSettings,
  hotelTeam,
  hotelTeams,
  hotelTimezones,
  hotelUser,
  hotelUsers,
  loader,
  loginCreds,
  replyTemplate,
  replyTemplates,
  user,
  users,
  quickReply,
  quickReplies,
  hotelVerifiedIntegration,
  webWidget,
  webWidgets,
  subProperty,
  subProperties,
  npsSettings,
  hotelTags,
  hotelTag,
  salutations
})
