/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import alertify from 'alertifyjs'

import { createHotelUsers } from '../actions/HotelActions'
import { Map, List, fromJS } from 'immutable'
import ParserUtils from '../utils/ParserUtils'
import ErrorCreateHotelUser from './ErrorCreateHotelUser'

class ParseStep extends React.Component {
  static propTypes = {
    onParsed: PropTypes.func,
    raw: PropTypes.string,
    users: PropTypes.object
  }

  constructor(props) {
    super(props)

    const { raw, users } = this.props
    this.state = {
      data: new Map({
        raw: raw,
        users: users
      })
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const data = this.state.data
    this.props.onParsed(data.get('raw'), data.get('users'))
  }

  handleChange = ev => {
    let data = this.state.data.set(ev.target.name, ev.target.value)
    const raw = data.get('raw')

    const users = fromJS(ParserUtils.parseEmails(raw)).map(user =>
      user.merge({ title: '', teams: new List() })
    )

    data = data.set('users', fromJS(users))
    this.setState({ data })
  }

  render() {
    const isDisabled = !this.state.data.get('users').count()

    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Add hotel staff members here.</h1>

        <p>
          At a minimum, we need an email address for each staff member. Put one person per line, and
          feel free to include their names too.
        </p>

        <p>
          <textarea
            name="raw"
            id="raw"
            onChange={this.handleChange}
            value={this.state.data.get('raw')}
            placeholder="conor@theluxehotelsf.com, Conor Muirhead"
            required
          />
        </p>

        <div className="actions">
          <button disabled={isDisabled} type="submit" className="button pull-right">
            Next →
          </button>
        </div>
      </form>
    )
  }
}

class ConfirmStep extends React.Component {
  static propTypes = {
    onConfirmed: PropTypes.func,
    onBack: PropTypes.func,
    users: PropTypes.object
  }

  constructor(props) {
    super(props)

    const { users } = this.props

    this.state = {
      users,
      error: new List()
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch, selectedHotelId } = this.props
    const { users } = this.state

    this.setState({ isSaving: true, error: null })
    dispatch(
      createHotelUsers(selectedHotelId, users.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: fromJS(body.response) })
        }

        alertify.success(`User${users.size > 1 ? 's' : ''} successfully created.`)
        setTimeout(() => {
          this.props.onConfirmed()
        }, 1000)
      })
    )
  }

  handleChange = (index, user) => {
    const { users, error } = this.state
    const newUsers = users.set(index, user)

    const updatedError = error.set(index, new Map({ index: index }))
    this.setState({ users: newUsers, error: updatedError })
  }

  handleBack = ev => {
    ev.preventDefault()
    this.props.onBack()
  }

  isDisabled = () => {
    return !this.state.users.every(
      user =>
        user.get('first_name') && user.get('last_name') && user.get('title') && user.get('email')
    )
  }

  render() {
    const { users, isSaving } = this.state
    const error = this.state.error || new List()

    return (
      <form onSubmit={this.handleSubmit}>
        <h1>What are the roles of these people?</h1>

        <ul className="block-list">
          {users.map((user, i) => (
            <li key={i}>
              <ConfirmUser
                error={
                  error.has(i)
                    ? error
                        .filter(err => err && err.get('index') === i)
                        .map(err => err && err.get('messages'))
                        .first()
                    : null
                }
                user={user}
                onChange={this.handleChange.bind(null, i)}
              />
            </li>
          ))}
        </ul>

        <div className="actions">
          <button
            type="submit"
            disabled={this.isDisabled() || isSaving}
            className="button pull-right"
          >
            {isSaving ? <span> Sending invites... </span> : <span> Next, send invites →</span>}
          </button>
          <button
            type="cancel"
            className="button pull-right rmargin0-5em"
            onClick={this.handleBack}
          >
            ← Back
          </button>
        </div>
      </form>
    )
  }
}

class ConfirmUser extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    error: PropTypes.object,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null
    }
  }

  componentWillReceiveProps(nextProps) {
    const { error } = nextProps
    this.setState({ error: error })
  }

  handleChange = ev => {
    const { name, value } = ev.target
    const updatedUser = this.props.user.set(name, value)
    this.props.onChange(updatedUser)
    this.setState({ error: null })
  }

  render() {
    const { user } = this.props
    const { error } = this.state

    return (
      <div className="account-settings">
        <div className="column-layout">
          <div className="account-info">
            <div className="name">
              <input
                className={error && error.get('first_name') ? 'error' : ''}
                placeholder="Add first name..."
                type="text"
                name="first_name"
                value={user.get('first_name') || ''}
                onChange={this.handleChange}
              />
              {error && error.get('first_name') ? (
                <ErrorCreateHotelUser error={error.get('first_name')} />
              ) : null}

              <input
                className={error && error.get('last_name') ? 'error' : ''}
                placeholder="Add last name..."
                type="text"
                name="last_name"
                value={user.get('last_name') || ''}
                onChange={this.handleChange}
              />
              {error && error.get('last_name') ? (
                <ErrorCreateHotelUser error={error.get('last_name')} />
              ) : null}

              <span className="title-span-left">(</span>
              <input
                className={error && error.get('title') ? 'error' : ''}
                placeholder="Add title..."
                type="text"
                name="title"
                value={user.get('title') || ''}
                onChange={this.handleChange}
              />
              <span>)</span>
              {error && error.get('title') ? (
                <ErrorCreateHotelUser error={error.get('title')} />
              ) : null}
            </div>

            <input
              className={`w-60pct ${error && error.get('email') ? 'error' : ''}`}
              placeholder="Email address..."
              type="text"
              name="email"
              value={user.get('email') || ''}
              onChange={this.handleChange}
            />
            {error && error.get('email') ? (
              <ErrorCreateHotelUser error={error.get('email')} />
            ) : null}
            <input
              className={`w-60pct ${error && error.get('phone') ? 'error' : ''}`}
              placeholder="Mobile number..."
              type="text"
              name="phone"
              value={user.get('phone') || ''}
              onChange={this.handleChange}
            />
            {error && error.get('phone') ? (
              <ErrorCreateHotelUser error={error.get('phone')} />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

class CreateNewHotelUser extends React.Component {
  static propTypes = {
    history: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      raw: '',
      users: new List()
    }
  }

  handleParsed = (raw, users) => {
    this.setState({ step: 1, raw, users })
  }

  handleConfirmed = () => {
    const { dispatch, match } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    this.setState({ step: 0 })
    dispatch(push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff`))
  }

  handleBack = () => {
    this.setState({ step: this.state.step - 1 })
  }

  render() {
    const { step, raw, users } = this.state
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    return (
      <div className="settings-container new-staff-settings">
        {step === 0 ? <ParseStep raw={raw} users={users} onParsed={this.handleParsed} /> : null}
        {step === 1 ? (
          <ConfirmStep
            dispatch={dispatch}
            selectedHotelId={selectedHotelId}
            users={users}
            onConfirmed={this.handleConfirmed}
            onBack={this.handleBack}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelUser: state.hotelUser
})

export default connect(mapStateToProps)(CreateNewHotelUser)
