/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2018
 */

const AdminUserUtils = {
  getUserName(user) {
    if (!user) return 'Admin User'
    return `${user.get('first_name') || 'Admin'} ${user.get('last_name') || 'User'}`
  }
}

export default AdminUserUtils
