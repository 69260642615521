/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { loadMe } from '../actions/MeActions'

class PrivateRoute extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      loggedIn: false
    }
  }

  componentWillMount() {
    const { dispatch, history } = this.props

    if (window.localStorage.getItem('accessToken')) {
      this.setState({ loggedIn: true })
      dispatch(
        loadMe((statusCode, body) => {
          if (statusCode === 401) {
            return history.replace('/login')
          }
        })
      )
    }
  }

  render() {
    const { component: Component, ...props } = this.props

    if (this.state.loggedIn) {
      return <Component {...props} />
    }

    return <Redirect to="/login" />
  }
}

export default withRouter(connect()(PrivateRoute))
