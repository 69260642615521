/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'

class ToggleShowPassword extends React.Component {
  static propTypes = {
    showPassword: PropTypes.bool.isRequired,
    handleShowPassword: PropTypes.func.isRequired
  }

  render() {
    const { showPassword, handleShowPassword } = this.props

    return (
      <img
        src={`/icons/${showPassword ? 'eye-active' : 'eye'}32x32.svg`}
        className="toggle-show-password"
        title={`${showPassword ? 'Hide' : 'Show'} password`}
        onClick={handleShowPassword}
        alt=""
      />
    )
  }
}

export default ToggleShowPassword
