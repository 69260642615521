/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Map } from 'immutable'

import { resetPassword } from '../actions/AuthActions'

class ForgotPassword extends React.Component {
  static propTypes = {
    location: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      data: new Map({
        email: ''
      }),
      error: null,
      success: false
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch } = this.props
    const { data } = this.state

    this.setState({ error: null, success: false })
    dispatch(
      resetPassword(data.get('email'), (error, resp, body) => {
        if (resp.statusCode !== 204) {
          return this.setState({ error: body && body.error, success: false })
        }
        this.setState({
          data: new Map({
            email: ''
          }),
          success: true
        })
      })
    )
  }

  handleChange = ev => {
    const { data } = this.state
    this.setState({
      data: data.set(ev.target.name, ev.target.value)
    })
  }

  isPristine = () => {
    const { data } = this.state
    return !data.get('email').length
  }

  render() {
    const { data, success, error } = this.state

    return (
      <div className="login">
        <form onSubmit={this.handleSubmit} className="card">
          <label htmlFor="email" className="subtle">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={this.handleChange}
            value={data.get('email')}
            required
            placeholder="email@example.com"
          />

          {error && <div className="error">{error}</div>}

          {success && (
            <div className="success">
              Check your inbox! You just received an email with your new
              password!
            </div>
          )}

          <button
            type="submit"
            disabled={this.isPristine()}
            className="button remind-me"
          >
            Reset Password
          </button>

          <br />
          <br />
          <Link to="login">&#8592; Login</Link>
        </form>
      </div>
    )
  }
}

export default connect()(ForgotPassword)
