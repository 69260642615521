/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { loadMe } from '../actions/MeActions'

class PublicRoute extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      loggedIn: false
    }
  }

  validLoginCreds = () => {
    const { loginCreds } = this.props
    return loginCreds.get('email') && loginCreds.get('password')
  }

  componentWillMount() {
    if (window.localStorage.getItem('accessToken')) {
      this.setState({ loggedIn: true })
      this.props.dispatch(loadMe())
    }
  }

  render() {
    const { location, component: Component, ...props } = this.props

    if (this.state.loggedIn) {
      return <Redirect to="/" {...props} />
    }

    if (
      !this.validLoginCreds() &&
      location.pathname !== '/login' &&
      location.pathname !== '/forgot_password'
    ) {
      return <Redirect to="/login" {...props} />
    }

    return <Component {...props} />
  }
}

const mapStateToProps = state => ({
  loginCreds: state.loginCreds
})

export default connect(mapStateToProps)(PublicRoute)
