/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import QR from 'qrcode.react'

class SetUp2FA extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  handleNext = () => {
    const { history } = this.props
    history.replace({
      pathname: '/otp-2fa',
      state: { enableUser2fa: true }
    })
  }
  render() {
    const { state } = this.props.location
    const qrCodeUri = state && state.qrCodeUri

    return (
      <div className="setup-2fa">
        <form onSubmit={this.handleSubmit} className="setup card">
          <h3 className="heading">Follow these 3 steps to enable two-factor authentication:</h3>
          <ol>
            <li>
              <span>Download the Google Authenticator app for </span>
              <span>
                <a
                  href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iOS
                </a>
              </span>
              <span> or </span>
              <span>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </a>
              </span>
              <span>.</span>
            </li>
            <li>
              <div>Open the Google Authenticator app and scan the below QR code.</div>
              <div className="qrcode">
                <QR value={qrCodeUri} size={192} level="M" />
              </div>
            </li>
            <li>
              Once you have added Glowing.io to the Google Authenticator app, click the Next button
              below to enter your OTP on the next screen.
            </li>
          </ol>
          <div>
            <button type="button" className="button" onClick={this.handleNext}>
              Next
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default withRouter(SetUp2FA)
