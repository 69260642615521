/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, May 2018
 */

import { Map, fromJS } from 'immutable'
import _ from 'lodash'

import HotelPlatformConstants from '../constants/HotelPlatformConstants'
import HotelIntegrationConstants from '../constants/HotelIntegrationConstants'
import Languages from '../constants/Languages'

const HotelUtils = {
  getStaffName(user) {
    if (!user || user.isEmpty()) return ''

    return `${user.get('first_name')} ${user.get('last_name')}`
  },
  platformUiObject(key) {
    return (
      HotelPlatformConstants.filter(platform => platform.get('uiKey') === key).first() || new Map()
    )
  },
  integrationUiObject(integrationType) {
    return (
      HotelIntegrationConstants.filter(
        integration => integration.get('integration_type') === integrationType
      ).first() || new Map()
    )
  },
  getLanguagesList() {
    return fromJS(Languages)
      .sortBy((name, _key) => name)
      .map((label, value) => new Map({ value, label: _.capitalize(label) }))
      .toList()
  },
  getIntegrationName(integrationType) {
    const index = HotelIntegrationConstants.findIndex(
      integration => integration.get('integration_type') === integrationType
    )
    return HotelIntegrationConstants.getIn([index, 'integrationKey'])
  }
}

export default HotelUtils
