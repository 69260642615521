/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_ACCOUNTS,
  RECEIVE_GET_ACCOUNT,
  RECEIVE_DELETE_ACCOUNT,
  REQUEST_GET_ACCOUNT_HOTELS
  // RECEIVE_GET_ACCOUNT_HOTELS,
  // RECEIVE_GET_ACCOUNT_HOTEL,
  // RECEIVE_DELETE_ACCOUNT_HOTEL,
  // RECEIVE_UPDATE_HOTEL_LOGO
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, accountId } = action

  switch (type) {
    case RECEIVE_GET_ACCOUNTS:
      return new OrderedMap(
        data.accounts.map(account => [account.id, fromJS(account)])
      )

    case RECEIVE_GET_ACCOUNT:
      return state.set(data.id, fromJS(data))

    case RECEIVE_DELETE_ACCOUNT:
      return state.remove(accountId)

    case REQUEST_GET_ACCOUNT_HOTELS:
      const updated = state.get(accountId).set('loadingHotels', data)
      return data
        ? state.set(accountId, updated)
        : state.set(accountId, updated.set('hotelsLoaded', true))

    // case RECEIVE_GET_ACCOUNT_HOTELS:
    //   const hotels = new OrderedMap(
    //     fromJS(data.hotels).map(hotel => [hotel.get('id'), hotel])
    //   )
    //   const updatedAccount = state.get(accountId).set('hotels', hotels)
    //   return state.set(accountId, updatedAccount)

    // case RECEIVE_GET_ACCOUNT_HOTEL:
    //   const accountHotels =
    //     state.get(accountId).get('hotels') || new OrderedMap()
    //   const updatedAccountHotels = accountHotels.set(
    //     data.hotel.id,
    //     fromJS(data.hotel)
    //   )
    //   return state.set(
    //     accountId,
    //     state.get(accountId).set('hotels', updatedAccountHotels)
    //   )

    // case RECEIVE_UPDATE_HOTEL_LOGO:
    //   const accountLogo = state.get(accountId)
    //   const updatedHotelLogo = accountLogo
    //     .get('hotels')
    //     .get(hotelId)
    //     .set('logo', data.logo)
    //   const updatedAccountHotelsLogo = accountLogo
    //     .get('hotels')
    //     .set(hotelId, updatedHotelLogo)
    //   return state.set(
    //     accountId,
    //     state.get(accountId).set('hotels', updatedAccountHotelsLogo)
    //   )

    // case RECEIVE_DELETE_ACCOUNT_HOTEL:
    //   const accountDel = state.get(accountId)
    //   const updatedAccountHotelsDel = accountDel.get('hotels').remove(hotelId)
    //   const updatedAccountDel = accountDel.set(
    //     'hotels',
    //     updatedAccountHotelsDel
    //   )
    //   return state.set(accountId, updatedAccountDel)

    default:
      return state
  }
}
