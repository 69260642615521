/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import EditHotelWrapper from './EditHotelWrapper'
import HotelStaff from './HotelStaff'
import HotelTeams from './HotelTeams'
import HotelPlatforms from './HotelPlatforms'
import AddNewPlatform from './AddNewPlatform'
import EditHotelPlatform from './EditHotelPlatform'
import HotelIntegrations from './HotelIntegrations'
import AddNewIntegration from './AddNewIntegration'
import EditHotelIntegration from './EditHotelIntegration'
import HotelSettings from './HotelSettings'
import CreateNewHotelUser from './CreateNewHotelUser'
import CreateNewHotelTeam from './CreateNewHotelTeam'
import EditHotelUser from './EditHotelUser'
import EditReplyTemplate from './EditReplyTemplate'
import ReplyTemplates from './ReplyTemplates'
import HotelOptInSettings from './HotelOptInSettings'
import EditQuickReply from './EditQuickReply'
import QuickReplies from './QuickReplies'
import EditLandingPageWrapper from './EditLandingPageWrapper'

import { loadAccountHotels } from '../actions/HotelActions'
import { updateLoaderState } from '../actions/LoaderActions'

import AccountUtils from '../utils/AccountUtils'
import EditWebWidget from './EditWebWidget'
import WebWidgets from './WebWidgets'
import HotelSubProperties from './HotelSubProperties'
import EditHotelSubProperty from './EditHotelSubProperty'
import NPSSettings from './NPSSettings'
import EditNPSSettings from './EditNPSSettings'
import { loadFeatureFlags } from '../actions/HotelActions'
import HotelSalutations from './HotelSalutations'

class Hotel extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      selectedAccountId: PropTypes.string,
      selectedHotelId: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      active: 0
    }
  }

  componentDidMount = () => {
    const { dispatch, match, featureFlags } = this.props
    const selectedHotelId = match && match.params.selectedHotelId
    if (featureFlags.isEmpty()) {
      dispatch(loadFeatureFlags(selectedHotelId))
    }
  }

  componentDidUpdate() {
    const { dispatch, match, accounts } = this.props
    const accountId = match.params.selectedAccountId

    const account = accounts.get(accountId)

    // Fetch account's hotel data if not already fetched
    if (AccountUtils.shouldLoadAccHotels(account)) {
      dispatch(updateLoaderState(true))
      dispatch(
        loadAccountHotels(accountId, () => {
          dispatch(updateLoaderState(false))
        })
      )
    }
  }

  setActive = tabIndex => {
    this.setState({ active: tabIndex })
  }

  isFeatureFlagEnabled = flag => {
    const { featureFlags } = this.props
    if (featureFlags.isEmpty()) return false
    const featureFlag = featureFlags.get(flag)
    return featureFlag ? featureFlag.get('value') : false
  }

  render() {
    const { match, hotels, featureFlags } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params

    const hotel = hotels && hotels.get(selectedHotelId)

    return hotel ? (
      <div className="item-container-main">
        <div className="hotel-settings-nav">
          <div className="">
            <nav className="tabs">
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/edit`}
              >
                Edit Business
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/landing-page`}
              >
                Landing Page
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/staff`}
              >
                Staff
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/teams`}
              >
                Teams
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/platforms`}
              >
                Platforms
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/integrations`}
              >
                Integrations
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`}
              >
                Reply Templates
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/quick-replies`}
              >
                Quick Reply
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/guest-privacy`}
              >
                Privacy
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/settings`}
              >
                Settings
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/web-widget`}
              >
                Web Widget
              </NavLink>
              <NavLink
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/sub-properties`}
              >
                Sub Properties
              </NavLink>
              {this.isFeatureFlagEnabled('NPS_BOT_ENABLED') && (
                <NavLink
                  activeClassName="active"
                  className={`link`}
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/nps-details`}
                >
                  NPS Settings
                </NavLink>
              )}
              {this.isFeatureFlagEnabled('CUSTOM_SALUTATIONS_ENABLED') && (
                <NavLink
                  activeClassName="active"
                  className={`link`}
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/salutations`}
                >
                  Salutations
                </NavLink>
              )}
            </nav>
          </div>
        </div>

        <Switch>
          <Redirect
            from="/account/:selectedAccountId/hotel/:selectedHotelId"
            exact
            to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/edit`}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/landing-page"
            component={EditLandingPageWrapper}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/staff/new"
            component={CreateNewHotelUser}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/staff/:hotelUserId"
            component={EditHotelUser}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/staff"
            component={HotelStaff}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/teams"
            component={HotelTeams}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/teams/new"
            component={CreateNewHotelTeam}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/platforms/new"
            component={AddNewPlatform}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/platforms/:platformId"
            component={EditHotelPlatform}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/platforms"
            component={HotelPlatforms}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/integrations/new"
            component={AddNewIntegration}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/integrations/:integrationId"
            component={EditHotelIntegration}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/integrations"
            component={HotelIntegrations}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/reply-templates/new"
            component={EditReplyTemplate}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/reply-templates/:templateId"
            component={EditReplyTemplate}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/reply-templates"
            component={ReplyTemplates}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/guest-privacy"
            component={HotelOptInSettings}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/settings"
            component={HotelSettings}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/edit"
            component={EditHotelWrapper}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/quick-replies"
            component={QuickReplies}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/quick-replies/:quickReplyId"
            component={EditQuickReply}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/quick-replies/new"
            component={EditQuickReply}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/web-widget"
            component={WebWidgets}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/web-widget/new"
            component={EditWebWidget}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/web-widget/:widgetId"
            component={EditWebWidget}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/sub-properties"
            component={HotelSubProperties}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/sub-properties/new"
            component={EditHotelSubProperty}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/sub-properties/:subPropertyId"
            component={EditHotelSubProperty}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/nps-details"
            component={NPSSettings}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/nps-details/edit"
            component={EditNPSSettings}
          />
          <Route
            exact
            path="/account/:selectedAccountId/hotel/:selectedHotelId/salutations"
            component={HotelSalutations}
          />
        </Switch>
      </div>
    ) : null
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  hotels: state.hotels,
  featureFlags: state.hotelSettings.get('featureFlags')
})

export default connect(mapStateToProps)(Hotel)
