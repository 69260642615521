/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { is, fromJS, Map, List } from 'immutable'
import Select from 'react-select'
import alertify from 'alertifyjs'

import ToggleShowPassword from './ToggleShowPassword'
import {
  saveUser,
  deleteUser,
  updateUserRole,
  updateUserPassword,
  resetUser2fa
} from '../actions/SettingActions'
import MeUtils from '../utils/MeUtils'
import AdminUserUtils from '../utils/AdminUserUtils'

class EditUser extends React.Component {
  static propTypes = {
    user: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      user: props.user,
      showPassword: false,
      rolesList: ['super_admin', 'admin', 'user']
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user
    })
  }

  handleChange = ev => {
    this.setState({
      user: this.state.user.set(ev.target.name, ev.target.value)
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch } = this.props
    const { user } = this.state

    const updateUser = user.get('id')
      ? user.set('roles', user.get('roles').map(role => role.get('name')))
      : user.set('roles', new List(['user']))

    this.setState({ isSaving: true, error: null })
    dispatch(
      saveUser(updateUser.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({ error: body })
        }

        alertify.success(`User successfully created.`)
        setTimeout(() => {
          dispatch(push(`/settings/users`))
        }, 1000)
      })
    )
  }

  handleCancel = ev => {
    ev.preventDefault()
    this.props.history.push(`/settings/users`)
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({
      user: this.props.user,
      error: null,
      errorUpdatingPassword: null,
      errorUpdatingRole: null
    })
  }

  handleDelete = ev => {
    ev.preventDefault()
    const confirmed = window.confirm('Are you sure you want to delete this user?')
    if (!confirmed) return

    const { dispatch } = this.props
    const { user } = this.state

    this.setState({ isDeleting: true, error: null })
    dispatch(
      deleteUser(user.get('id'), (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 204) {
          return this.setState({ error: body })
        }

        alertify.success(`User successfully deleted.`)
        setTimeout(() => {
          dispatch(push(`/settings/users`))
        }, 1000)
      })
    )
  }

  handlePasswordUpdate = ev => {
    ev.preventDefault()

    const { dispatch } = this.props
    const { user } = this.state

    this.setState({ updatingPassword: true, errorUpdatingPassword: null })
    dispatch(
      updateUserPassword(user.get('id'), user.toJS(), (statusCode, body) => {
        this.setState({ updatingPassword: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingPassword: body && body.error })
        }

        alertify.success('User password successfully updated.')
      })
    )
  }

  onSelectUserRole = option => {
    const { dispatch } = this.props
    const { user } = this.state

    const data = {
      roles: [option && option.value]
    }

    this.setState({ updatingRole: true, errorUpdatingRole: null })
    dispatch(
      updateUserRole(user.get('id'), data, (statusCode, body) => {
        this.setState({ updatingRole: false })
        if (statusCode !== 200) {
          return this.setState({ errorUpdatingRole: body && body.error })
        }

        alertify.success('User role successfully updated.')
      })
    )
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleReset2fa = ev => {
    ev.preventDefault()
    const { dispatch } = this.props
    const { user } = this.state

    const userName = AdminUserUtils.getUserName(user)
    const confirmMsg = `Are you sure you want to reset 2FA setup for <b>${userName}</b>?`

    alertify.confirm(confirmMsg).set({
      onok: () => {
        this.setState({ reseting2fa: true })
        dispatch(
          resetUser2fa(user.get('id'), (statusCode, body) => {
            this.setState({ reseting2fa: false })
            if (statusCode !== 200) {
              return alertify.error((body && body.error) || 'Error resetting users 2FA.')
            }

            alertify.success('Users 2FA successfuly reset.')
          })
        )
      }
    })
  }

  isPristine = () => {
    const { user, isSaving } = this.state
    return is(user, this.props.user) || isSaving
  }

  render() {
    const { me } = this.props
    const {
      user,
      rolesList,
      isSaving,
      error,
      errorUpdatingRole,
      updatingRole,
      showPassword,
      updatingPassword,
      errorUpdatingPassword,
      isDeleting,
      reseting2fa
    } = this.state

    if (!user) return <div />

    const isMeSuperAdmin = MeUtils.isSuperAdmin(me)

    const userRoles = fromJS(rolesList).map(
      role => new Map({ value: role, label: role.replace('_', ' ') })
    )

    const userRole = (user.get('roles') && user.get('roles').first()) || new Map()

    const selectedUserRole = userRoles
      .filter(role => role.get('value') === userRole.get('name'))
      .first()

    return (
      <div className="admin-user-settings bmargin5em">
        <form onSubmit={this.handleSubmit}>
          <h1>{user.get('id') ? 'Update User' : 'Create New User'}</h1>

          <div className="w-80pct">
            <div className="name column-layout">
              <input
                type="text"
                name="salutation"
                value={user.get('salutation') || ''}
                onChange={this.handleChange}
                className="rmargin0-5em"
                placeholder="Salutation"
              />
              <input
                type="text"
                name="first_name"
                value={user.get('first_name') || ''}
                onChange={this.handleChange}
                className="rmargin0-5em"
                placeholder="First Name"
              />
              <input
                type="text"
                name="last_name"
                value={user.get('last_name') || ''}
                onChange={this.handleChange}
                placeholder="Last Name"
              />
            </div>

            <input
              disabled={!!user.get('id')}
              type="text"
              className=""
              name="email"
              value={user.get('email') || ''}
              onChange={this.handleChange}
              placeholder="Email ID"
            />
          </div>

          {error && error.salutation ? (
            <div className="error">Salutation {error.salutation}</div>
          ) : null}
          {error && error.first_name ? (
            <div className="error">First Name {error.first_name}</div>
          ) : null}
          {error && error.last_name ? (
            <div className="error">Last Name {error.last_name}</div>
          ) : null}
          {error && error.email ? <div className="error">Email {error.email}</div> : null}
          {error && error.phone ? <div className="error">{error.phone}</div> : null}
          {error && error.roles ? <div className="error">{error.roles}</div> : null}

          <div className="actions">
            <button disabled={this.isPristine()} type="submit" className="button">
              Save Changes
            </button>
            <button disabled={isSaving} type="cancel" className="button" onClick={this.handleReset}>
              Reset
            </button>
            {isMeSuperAdmin && user.get('id') && (
              <div className="pull-right">
                <button
                  disabled={isDeleting}
                  type="button"
                  className="button button-delete delete pull-right"
                  onClick={this.handleDelete}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </form>

        {isMeSuperAdmin && user.get('id') && (
          <div>
            <div className="w-80pct">
              <h2 className="sub-section-heading">Change User Password</h2>
              <form disabled={isSaving} onSubmit={this.handlePasswordUpdate}>
                <fieldset className="password-fields">
                  <legend>User password</legend>
                  <input
                    type="password"
                    name="password"
                    value={user.get('password') || ''}
                    placeholder="Enter new password..."
                    onChange={this.handleChange}
                  />
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password_confirmation"
                    className="rpadding3em"
                    value={user.get('password_confirmation') || ''}
                    placeholder="Confirm new password..."
                    onChange={this.handleChange}
                  />
                  {user.get('password_confirmation') ? (
                    <ToggleShowPassword
                      showPassword={showPassword}
                      handleShowPassword={this.handleShowPassword}
                    />
                  ) : null}
                </fieldset>

                <div className="actions">
                  <button className="button" disabled={updatingPassword}>
                    Update
                  </button>
                  <button
                    disabled={isSaving}
                    type="cancel"
                    className="button"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
                {errorUpdatingPassword && <div className="error">{errorUpdatingPassword}</div>}
              </form>
            </div>

            <div className="w-80pct">
              <h2 className="sub-section-heading bmargin0-5em">Update User Role</h2>
              <fieldset className={updatingRole ? 'custom-disabled' : ''}>
                <Select
                  name="user-role"
                  options={userRoles && userRoles.toJS()}
                  value={selectedUserRole && selectedUserRole.toJS()}
                  onChange={this.onSelectUserRole}
                  placeholder={'Select user role...'}
                />
              </fieldset>

              {errorUpdatingRole && <div className="error">{errorUpdatingRole}</div>}
            </div>

            <div className="w-80pct">
              <h2 className="sub-section-heading">2FA Authentication</h2>
              <small>
                If this user no longer can access his/her device with the Google Authenticator app,
                click the Reset 2FA button below to allow the user to re-scan the QR code on another
                device.
              </small>
              <fieldset className="tmargin1em">
                <button className="button" onClick={this.handleReset2fa} disabled={reseting2fa}>
                  Reset 2FA
                </button>
              </fieldset>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  me: state.me
})

export default withRouter(connect(mapStateToProps)(EditUser))
