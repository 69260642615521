/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import Account from './Account'
import Tags from './Tags'
import { loadAccountHotels } from '../actions/HotelActions'

class Property extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ selectedAccountId: PropTypes.string })
  }

  componentWillUpdate(nextProps, nextState) {
    const { dispatch, match, accounts } = this.props
    // This needs to be done only once on page reload after accounts data is fetched
    if (accounts.isEmpty() && !nextProps.accounts.isEmpty()) {
      const accountId = match.params.selectedAccountId
      dispatch(loadAccountHotels(accountId))
    }
  }

  render() {
    const { match, accounts } = this.props
    const { selectedAccountId } = match && match.params

    const account = selectedAccountId ? accounts.get(selectedAccountId) : new Map()

    return account ? (
      <div className="item-container-main">
        <div className="hotel-settings-nav">
          <div>
            <nav className="tabs">
              <NavLink
                exact
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}`}
              >
                Account
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                className={`link`}
                to={`/account/${selectedAccountId}/tags`}
              >
                Tags
              </NavLink>
            </nav>
          </div>
        </div>

        <Switch>
          <Route exact path="/account/:selectedAccountId" component={Account} />
          <Route exact path="/account/:selectedAccountId/tags" component={Tags} />
        </Switch>
      </div>
    ) : null
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  tags: state.tags
})

export default connect(mapStateToProps)(Property)
