/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

import { fromJS } from 'immutable'

const initialState = fromJS({
  name: '',
  agent_type: '',
  end_user_type: '',
  supported_feature_flags: {
    BROADCAST_ENABLED: true,
    CHAT: true,
    GUEST_ENGAGEMENT_ENABLED: true,
    GUEST_PROFILE_EDITABLE: true,
    GUEST_TAGGING: true,
    INTERNAL_CHAT: true,
    PUSH_NOTIFICATION_ENABLED: true,
    QUICK_REPLIES: true,
    REPLIES: true,
    STATS_ENABLED: true,
    WHATS_APP_ENGAGEMENT_ENABLED: true,
    WHOSEES: true
  }
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
