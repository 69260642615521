/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

const AccountUtils = {
  shouldLoadAccHotels(account) {
    if (!account) return false

    return !account.get('loadingHotels') && !account.get('hotelsLoaded')
  }
}

export default AccountUtils
