/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_FEATURE_FLAGS } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_FEATURE_FLAGS:
      const featureFlags = new OrderedMap(data.feature_flags.map(flag => [flag.id, fromJS(flag)]))
      return state.merge(featureFlags)

    default:
      return state
  }
}
