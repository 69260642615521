/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'
import HotelUtils from '../utils/HotelUtils'

const {
  RECEIVE_GET_HOTEL_INTEGRATIONS,
  RECEIVE_GET_HOTEL_INTEGRATION,
  RECEIVE_DELETE_HOTEL_INTEGRATION
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, integrationId, integrationKey } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_INTEGRATIONS:
      const formattedData = []
      fromJS(data).forEach((integrations, integrationKey) => {
        integrations.forEach(integration => {
          formattedData.push(
            HotelUtils.integrationUiObject(integration.get('integration_type'))
              .merge(integration)
              .toJS()
          )
        })
      })
      return new OrderedMap(formattedData.map(integration => [integration.id, fromJS(integration)]))

    case RECEIVE_GET_HOTEL_INTEGRATION:
      const key = integrationKey.toLowerCase()
      const integration = data[key]
      const updatedIntegration = HotelUtils.integrationUiObject(integrationKey).merge(
        fromJS(integration)
      )
      return state.set(integration.id, updatedIntegration)

    case RECEIVE_DELETE_HOTEL_INTEGRATION:
      return state.remove(integrationId)

    default:
      return state
  }
}
