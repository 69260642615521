import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import alertify from 'alertifyjs'
import { fromJS } from 'immutable'
import _ from 'lodash'

import Loader from './Loader'
import PreviewInGridFormat from './PreviewInGridFormat'

import {
  createQuickReply,
  loadQuickReply,
  deleteQuickReply,
  updateQuickReply
} from '../actions/HotelActions'

class EditQuickReply extends React.Component {
  static propTypes = {
    quickReply: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      quickReply: this.getTemplateProps(props),
      saving: false,
      quickReplyInBulk: [],
      isSavingBulk: false
    }
  }

  componentDidMount() {
    const { dispatch, match, quickReplies } = this.props
    const { quickReplyId, selectedHotelId } = match && match.params
    if (quickReplyId && quickReplies.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadQuickReply(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps
    const { quickReplyId } = match && match.params
    if (quickReplyId) {
      // Not needed in case of new template creation
      this.setState({
        quickReply: this.getTemplateProps(nextProps)
      })
    }
  }

  getTemplateProps = props => {
    const { match, quickReplies, quickReply } = props
    const { quickReplyId } = match && match.params
    return quickReplyId ? fromJS([quickReplies.get(quickReplyId)]) : fromJS([quickReply])
  }

  handleChange = (i, ev) => {
    const quickReply = this.state.quickReply.get(i)
      ? this.state.quickReply.update(i, qr => qr.set(ev.target.name, ev.target.value))
      : fromJS([{ name: ev.target.value }])
    const previewShow = quickReply.get(0)
    const showPreview = previewShow.get('reply')

    this.setState({
      quickReply: quickReply,
      preview: showPreview
    })
  }

  handleSubmit = (ev, isBulk) => {
    ev.preventDefault()
    const { dispatch, match } = this.props
    const { selectedHotelId, quickReplyId } = match && match.params
    const { quickReply, quickReplyInBulk } = this.state
    if (isBulk) {
      this.setState({ isSavingBulk: true, error: null })
    } else {
      this.setState({ saving: true, error: null })
    }
    const quickReplies = isBulk ? quickReplyInBulk : quickReply.toJS()
    if (quickReplyId !== 'new') {
      dispatch(
        updateQuickReply(selectedHotelId, quickReplies, quickReplyId, (statusCode, body) => {
          this.setState({ isSaving: false, isSavingBulk: false })
          console.log(body, statusCode)
          if (statusCode === 201) {
            return alertify.error((body && body.error) || 'Error updating quick reply.')
          }
          alertify.success('Quick Reply successfully updated.')
          this.handleCancel()
        })
      )
    } else {
      dispatch(
        createQuickReply(selectedHotelId, quickReplies, (statusCode, body) => {
          this.setState({ isSaving: false, isSavingBulk: false })
          if (statusCode !== 201) {
            return alertify.error((body && body.error) || 'Error creating quick reply.')
          }
          alertify.success('Quick Reply successfully created.')
          this.handleCancel()
        })
      )
    }
  }

  handleRemoveFields(i) {
    this.setState({ quickReply: this.state.quickReply.delete(i) })
  }

  handleCancel = ev => {
    ev && ev.preventDefault()
    const { match, history } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/quick-replies`)
  }

  handleDelete = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId, quickReplyId } = match && match.params
    this.setState({ isDeleting: true })
    dispatch(
      deleteQuickReply(selectedHotelId, quickReplyId, (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 204) {
          return alertify.error((body && body.error) || 'Error deleting template.')
        }

        alertify.success('Template successfully deleted.')
        this.handleCancel()
      })
    )
  }

  //changes here
  handlePaste = ev => {
    ev.preventDefault()
    const data = ev.clipboardData.getData('Text')
    let newQuickReply = [...this.state.quickReplyInBulk]

    //remove blank input
    const rows = data.split('\n')
    _.remove(rows, function(e, i) {
      return e === '\t'
    })
    //remove input without name or reply
    let inputWithoutReply = /\t$/
    let inputWithoutName = /^\t/
    for (let i = 0; i < rows.length; i++) {
      if (inputWithoutReply.test(rows[i]) || inputWithoutName.test(rows[i])) {
        let remove = rows.indexOf(rows[i])
        if (remove > -1) {
          rows.splice(remove, 1)
        }
      }
    }

    for (let i in rows) {
      const newRow = rows[i].split('\t')
      const quickReply = {}
      newRow.forEach((val, index) => {
        if (val) {
          let propertyName
          if (index === 0) propertyName = 'name'
          if (index === 1) propertyName = 'reply'
          if (propertyName) {
            quickReply[propertyName] = val
          }
        }
      })
      newQuickReply.push({ ...quickReply })
    }

    const filterQuickReply = _.filter(newQuickReply, function(obj) {
      return obj.name && obj.reply
    })

    this.setState({ quickReplyInBulk: filterQuickReply })
  }

  handleDeleteFromBulk = index => {
    const { quickReplyInBulk } = this.state
    //deleting from quickReplyInBulk
    _.remove(quickReplyInBulk, function(e, i) {
      return index === i
    })
    this.setState({ quickReplyInBulk: quickReplyInBulk })
  }

  isPristine = () => {
    const { quickReply, isSaving } = this.state
    let isEmpty = false
    quickReply.map((qr, i) => {
      if (!qr || !qr.get('name') || !qr.get('reply') || isSaving) {
        isEmpty = true
      } else {
        isEmpty = false
      }
    })
    return isEmpty
  }

  isPristineForBulk = () => {
    const { quickReplyInBulk, isSavingBulk } = this.state
    let isEmpty = false
    if (quickReplyInBulk.length > 0) {
      quickReplyInBulk.map((qr, i) => {
        if (qr && (!qr.name || !qr.reply || isSavingBulk)) {
          isEmpty = true
        } else {
          isEmpty = false
        }
      })
    } else {
      isEmpty = true
    }
    return isEmpty
  }

  render() {
    const { isLoading, quickReply, saving, isDeleting, preview, isSavingBulk } = this.state
    const { match } = this.props
    const { quickReplyId } = match && match.params
    const rowData = this.state.quickReplyInBulk ? this.state.quickReplyInBulk : []

    if (isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }
    return (
      <div className="edit-quick-reply">
        <form onSubmit={this.handleSubmit} className="quick-reply-settings">
          <h2>{quickReplyId !== 'new' ? 'Update Quick Reply' : 'Create Quick Reply'}</h2>
          {quickReply.map((qr, i) => (
            <React.Fragment key={i}>
              {i !== 0 && (
                <img
                  className="pull-right remove-button"
                  src={'/icons/close.svg'}
                  onClick={this.handleRemoveFields.bind(this, i)}
                  alt=""
                />
              )}
              <h3>
                <input
                  type="text"
                  placeholder="Name this reply..."
                  name="name"
                  onChange={ev => this.handleChange(i, ev)}
                  id="name"
                  value={(qr && qr.get('name')) || ''}
                  required
                />
              </h3>
              <fieldset>
                <textarea
                  placeholder="Type a reply here..."
                  name="reply"
                  id="reply"
                  onChange={ev => this.handleChange(i, ev)}
                  value={(qr && qr.get('reply')) || ''}
                  required
                />
              </fieldset>
            </React.Fragment>
          ))}
          <div className="preview-reply-screen">
            <span>Preview:</span>
            <p className="render-reply">{preview}</p>
          </div>
          <div className="actions">
            <button type="submit" className="button" disabled={this.isPristine()}>
              {saving ? 'Saving...' : 'Save'}
            </button>
            <button type="cancel" className="button" onClick={this.handleCancel}>
              Cancel
            </button>
            {quickReplyId !== 'new' && (
              <button
                type="button"
                className="button button-delete pull-right"
                onClick={this.handleDelete}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            )}
          </div>
        </form>
        {quickReplyId === 'new' && (
          <React.Fragment>
            <div className="input-box">
              <h2>Add Bulk Reply</h2>
              <textarea
                type="text"
                placeholder="Click to copy & paste recipients here..."
                onPaste={this.handlePaste}
                maxlength="0"
              />
            </div>
            {rowData.length > 0 && (
              <PreviewInGridFormat
                rowData={rowData}
                handleDeleteFromBulk={this.handleDeleteFromBulk}
              />
            )}
            <div className="actions bulk-reply-buttons">
              <button
                type="submit"
                className="button"
                onClick={ev => this.handleSubmit(ev, true)}
                disabled={this.isPristineForBulk()}
              >
                {isSavingBulk ? 'Saving...' : 'Save'}
              </button>
              <button type="cancel" className="button" onClick={this.handleCancel}>
                Cancel
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  quickReply: state.quickReply,
  quickReplies: state.quickReplies
})

export default connect(mapStateToProps)(EditQuickReply)
