/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { connect } from 'react-redux'

import EditHotel from './EditHotel'

class CreateNewHotel extends React.Component {
  render() {
    const { dispatch, hotel, match } = this.props
    const { selectedAccountId } = match && match.params

    return (
      <div className="item-container-main">
        <div className="hotel-settings-nav">
          <div className="bmargin2em">
            <h1>Create New Business</h1>
          </div>
        </div>
        <EditHotel dispatch={dispatch} selectedAccountId={selectedAccountId} hotel={hotel} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotel: state.hotel
})

export default connect(mapStateToProps)(CreateNewHotel)
