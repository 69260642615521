/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import axios from 'axios'
import alertify from 'alertifyjs'

import Endpoints from '../constants/Endpoints'
import store from '../store'

const { API } = Endpoints

const ApiUtils = {
  handleError: (params, url, statusCode, body) => {
    // Handle the case if client is offline
    if (window.navigator.onLine === false) {
      alertify.error('Please check your internet connection and try again.')
      return
    }

    // http://docs.cloudmailin.com/receiving_email/http_status_codes/
    // StatusCode: 0 Server Unreachable
    // StatusCode: 2xx Successful call
    // StatusCode: 4xx Client error
    // StatusCode: 5xx Server error
    if (statusCode === 0 || (statusCode >= 400 && statusCode !== 422)) {
      const errorMessage = body && body.error
      alertify.error(`${errorMessage || 'Something went wrong, Please refresh the page.'}`)
      console.log(`CODE: ${statusCode}, URL: ${url}, ERROR: ${errorMessage}`)
    }
  },
  authorizationToken: () => {
    const state = store.getState()
    return state.auth && state.auth.get('accessToken')
  },
  get: (params, url, cb) => {
    const apiUrl = `${API}${url}`
    const authToken = ApiUtils.authorizationToken()
    axios
      .get(apiUrl, {
        params: params,
        headers: { Authorization: authToken }
      })
      .then(response => {
        const statusCode = response.status
        const body = response.data
        ApiUtils.handleError(params, url, statusCode, body)

        cb && cb(statusCode, body)
      })
      .catch(error => {
        const errResponse = error.response
        if (errResponse) {
          const statusCode = errResponse.status
          const body = errResponse.data
          ApiUtils.handleError(params, url, statusCode, body)

          cb && cb(statusCode, body)
        }
      })
  },
  post: (params, url, cb) => {
    const apiUrl = `${API}${url}`
    axios({
      method: 'post',
      url: apiUrl,
      data: params,
      headers: { Authorization: ApiUtils.authorizationToken() }
    })
      .then(response => {
        const statusCode = response.status
        const body = response.data
        ApiUtils.handleError(params, url, statusCode, body)

        cb && cb(statusCode, body)
      })
      .catch(error => {
        const errResponse = error.response
        if (errResponse) {
          const statusCode = errResponse.status
          const body = errResponse.data
          ApiUtils.handleError(params, url, statusCode, body)

          cb && cb(statusCode, body)
        }
      })
  },
  put: (params, url, cb) => {
    const apiUrl = `${API}${url}`
    axios({
      method: 'put',
      url: apiUrl,
      data: params,
      headers: { Authorization: ApiUtils.authorizationToken() }
    })
      .then(response => {
        const statusCode = response.status
        const body = response.data
        ApiUtils.handleError(params, url, statusCode, body)

        cb && cb(statusCode, body)
      })
      .catch(error => {
        const errResponse = error.response
        if (errResponse) {
          const statusCode = errResponse.status
          const body = errResponse.data
          ApiUtils.handleError(params, url, statusCode, body)

          cb && cb(statusCode, body)
        }
      })
  },
  delete: (params, url, cb) => {
    const apiUrl = `${API}${url}`
    axios
      .delete(apiUrl, {
        params: params,
        headers: { Authorization: ApiUtils.authorizationToken() }
      })
      .then(response => {
        const statusCode = response.status
        const body = response.data
        ApiUtils.handleError(params, url, statusCode, body)

        cb && cb(statusCode, body)
      })
      .catch(error => {
        const errResponse = error.response
        if (errResponse) {
          const statusCode = errResponse.status
          const body = errResponse.data
          ApiUtils.handleError(params, url, statusCode, body)

          cb && cb(statusCode, body)
        }
      })
  },
  fileUpload: (params, url, reqProps, cb) => {
    const apiUrl = `${API}${url}`
    axios
      .post(apiUrl, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: ApiUtils.authorizationToken()
        },
        ...reqProps
      })
      .then(response => {
        const statusCode = response.status
        const body = response.data
        ApiUtils.handleError(params, url, statusCode, body)

        cb && cb(statusCode, body)
      })
      .catch(error => {
        const errResponse = error.response
        if (errResponse) {
          const statusCode = errResponse.status
          const body = errResponse.data
          ApiUtils.handleError(params, url, statusCode, body)

          cb && cb(statusCode, body)
        }
      })
  }
}

export default ApiUtils
