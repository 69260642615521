/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import alertify from 'alertifyjs'

import Nav from '../components/Nav'
import Home from './Home'
import Logout from '../components/Logout'
import Settings from '../components/Settings'

class Main extends React.Component {
  componentDidMount() {
    this.checkRedirect()

    const alertifyDefaults = alertify.defaults
    alertify.defaults = {
      ...alertifyDefaults,
      modal: false,
      transition: 'fade',
      movable: false,
      pinnable: false,
      reverseButtons: true,
      glossary: {
        ...alertifyDefaults.glossary,
        title: 'Glowing.io'
      },
      theme: {
        ...alertifyDefaults.theme,
        ok: 'button',
        cancel: 'button button-cancel'
      }
    }
  }

  componentDidUpdate() {
    this.checkRedirect()
  }

  checkRedirect = () => {
    const { dispatch, location, accounts } = this.props

    const firstAccount = accounts.sortBy(acccount => acccount.get('name')).first()

    if (!firstAccount) return

    if (location.pathname === '/' || location.pathname === '/account') {
      dispatch(push(`/account/${firstAccount.get('id')}`))
    }
  }

  render() {
    return [
      <Nav key="header" />,
      <div key="body" className="main-content">
        <Switch>
          <Route path="/account" component={Home} />
          <Route path="/settings" component={Settings} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </div>
    ]
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts
})

export default withRouter(connect(mapStateToProps)(Main))
