/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'

const PropertyError = ({ error }) => (
  <div className="user-create-error">
    {error.map((message, i) => (
      <span key={i} className="rmargin0-5em">
        {message}
      </span>
    ))}
  </div>
)

export default PropertyError
