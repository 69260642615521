/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, NavLink, Switch, Redirect } from 'react-router-dom'

import EditMe from './EditMe'
import SettingUsers from './SettingUsers'
import CreateNewUser from './CreateNewUser'
import User from './User'
import MeUtils from '../utils/MeUtils'

class SettingsNav extends React.Component {
  static propTypes = {
    hotelId: PropTypes.string
  }

  render() {
    const { me } = this.props

    const isMeSuperAdmin = MeUtils.isSuperAdmin(me)

    return (
      <nav className="side-nav">
        <div className="side-nav-items">
          {isMeSuperAdmin && (
            <React.Fragment>
              <h3>Settings</h3>
              <ul>
                <li>
                  <NavLink activeclassname="active" className="link" to={`/settings/users`}>
                    Admin Users
                  </NavLink>
                </li>
              </ul>
            </React.Fragment>
          )}

          <h3>Me</h3>
          <ul>
            <li>
              <NavLink activeclassname="active" className="link" to={`/settings/account`}>
                My Account
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

class Settings extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    location: PropTypes.object
  }

  render() {
    const { location, me } = this.props

    return (
      <div className="main-settings-wrapper column-layout">
        <SettingsNav location={location} me={me} />
        <main className="settings-main">
          <Switch>
            <Redirect from="/settings" exact to="/settings/account" />
            <Route exact path="/settings/users/new" component={CreateNewUser} />
            <Route exact path="/settings/users/:selectedUserId" component={User} />
            <Route exact path="/settings/users" component={SettingUsers} />
            <Route exact path="/settings/account" component={EditMe} />
          </Switch>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  me: state.me
})

export default connect(mapStateToProps)(Settings)
