import { OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_SALUTATIONS, RECEIVE_GET_SALUTATION } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_SALUTATIONS:
      return new OrderedMap(data)

    case RECEIVE_GET_SALUTATION:
      const salutations = state.get('salutations')
      salutations.push(data)
      return state.set('salutations', salutations)

    default:
      return state
  }
}
