/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import ActionTypes from '../constants/ActionTypes'

const { REQUEST_SET_LOADING_STATE } = ActionTypes

export function updateLoaderState(status) {
  return dispatch => {
    dispatch({
      type: REQUEST_SET_LOADING_STATE,
      data: status
    })
  }
}
