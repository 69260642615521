import { OrderedMap } from 'immutable'

const initialState = OrderedMap({
  name: '',
  reply: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
