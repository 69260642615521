/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_TEAMS,
  RECEIVE_GET_HOTEL_TEAM,
  RECEIVE_DELETE_HOTEL_TEAM,
  RECEIVE_POST_TEAM_HOTEL_USER_ADD,
  RECEIVE_POST_TEAM_HOTEL_USER_REMOVE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, hotelTeamId, hotelUserId } = action

  const prevTeam = hotelTeamId ? state.get(hotelTeamId) : null

  switch (type) {
    case RECEIVE_GET_HOTEL_TEAMS:
      return new OrderedMap(data.teams.map(team => [team.id, fromJS(team)]))

    case RECEIVE_GET_HOTEL_TEAM:
      return state.set(data.team.id, fromJS(data.team))

    case RECEIVE_DELETE_HOTEL_TEAM:
      return state.remove(hotelTeamId)

    case RECEIVE_POST_TEAM_HOTEL_USER_ADD:
      return state.set(
        hotelTeamId,
        prevTeam.set('users', prevTeam.get('users').push(hotelUserId))
      )

    case RECEIVE_POST_TEAM_HOTEL_USER_REMOVE:
      return state.set(
        hotelTeamId,
        prevTeam.set(
          'users',
          prevTeam.get('users').filter(id => id !== hotelUserId)
        )
      )

    default:
      return state
  }
}
