/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'

import Router from './core/Router'

class App extends React.Component {
  render() {
    return (
      <div className="main-wrapper">
        <Router />
      </div>
    )
  }
}

export default App
