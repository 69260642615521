/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_ADMIN_USERS,
  RECEIVE_GET_ADMIN_USER,
  RECEIVE_DELETE_ADMIN_USER,
  RECEIVE_UPDATE_USER_ROLE,
  RECEIVE_UPDATE_USER_PASSWORD
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, userId } = action

  switch (type) {
    case RECEIVE_GET_ADMIN_USERS:
      const newUsers = new OrderedMap(data.admin_users.map(user => [user.id, fromJS(user)]))
      return state.concat(newUsers)

    case RECEIVE_GET_ADMIN_USER:
      return state.set(data.admin_user.id, fromJS(data.admin_user))

    case RECEIVE_DELETE_ADMIN_USER:
      return state.remove(userId)

    case RECEIVE_UPDATE_USER_ROLE:
      return state.set(data.admin_user.id, fromJS(data.admin_user))

    case RECEIVE_UPDATE_USER_PASSWORD:
      return state.set(data.admin_user.id, fromJS(data.admin_user))

    default:
      return state
  }
}
