/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_ACCOUNT_HOTELS,
  RECEIVE_GET_ACCOUNT_HOTEL,
  RECEIVE_DELETE_ACCOUNT_HOTEL,
  RECEIVE_UPDATE_HOTEL_LOGO,
  RECEIVE_UPDATE_LANDING_PAGE_BACKGROUND_IMAGE
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, hotelId } = action

  switch (type) {
    case RECEIVE_GET_ACCOUNT_HOTELS:
      const newHotels = new OrderedMap(data.hotels.map(hotel => [hotel.id, fromJS(hotel)]))
      return state.merge(newHotels)

    case RECEIVE_GET_ACCOUNT_HOTEL:
      return state.set(data.hotel.id, fromJS(data.hotel))

    case RECEIVE_UPDATE_HOTEL_LOGO:
      const updated = state.get(hotelId).set('logo', data.logo)
      return state.set(hotelId, updated)

    case RECEIVE_UPDATE_LANDING_PAGE_BACKGROUND_IMAGE:
      const imageUpdated = state
        .get(hotelId)
        .set('guests_landing_page_background_image', data.guests_landing_page_background_image)
      return state.set(hotelId, imageUpdated)

    case RECEIVE_DELETE_ACCOUNT_HOTEL:
      return state.remove(hotelId)

    default:
      return state
  }
}
