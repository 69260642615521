/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2019
 */

import { Map } from 'immutable'

const initialState = Map({
  is_active: true,
  logo: '',
  name: '',
  phone: '',
  city: '',
  email: '',
  guests_landing_page_url: '',
  guests_landing_page_header_text: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
