/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { is, Map } from 'immutable'

import { createHotelTeam } from '../actions/HotelActions'

const initialState = {
  team: Map({ name: '' }),
  isSaving: false,
  error: null
}

class CreateNewHotelTeam extends React.Component {
  static propTypes = {
    history: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = initialState
  }

  componentDidMount = () => {
    this.nameInputNode.focus()
  }

  handleChange = ev => {
    const { team } = this.state
    this.setState({
      team: team.set(ev.target.name, ev.target.value),
      error: null,
      isSaving: false
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch, match } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { team } = this.state

    this.setState({ isSaving: true, error: null })
    dispatch(
      createHotelTeam(selectedHotelId, team.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 201) {
          return this.setState({ error: body.error })
        }
        this.setState(initialState)
        dispatch(push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/teams`))
      })
    )
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState(initialState)
  }

  isPristine = () => {
    return is(this.state.team, initialState.team)
  }

  render() {
    const { isSaving, error } = this.state
    return (
      <form onSubmit={this.handleSubmit} className="settings-container new-team-settings">
        <h1>
          <input
            type="text"
            placeholder="New Team"
            name="name"
            ref={node => (this.nameInputNode = node)}
            id="name"
            onChange={this.handleChange}
            value={this.state.team.get('name')}
            required
          />
        </h1>

        {error ? <div className="error">{error}</div> : null}

        <div className="actions">
          <button disabled={this.isPristine() || isSaving} type="submit" className="button">
            {isSaving ? <span>Creating Team... </span> : <span>Create new team </span>}
          </button>
          <button type="cancel" className="button" onClick={this.handleReset}>
            Reset
          </button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  hotelTeam: state.hotelTeam
})

export default connect(mapStateToProps)(CreateNewHotelTeam)
