/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'
import { logout } from './AuthActions'

const {
  RECEIVE_GET_ME,
  RECEIVE_UPDATE_ME,
  RECEIVE_GET_ACCOUNTS,
  REQUEST_SET_LOADING_STATE,
  RECEIVE_ME_AVATAR_UPDATE
} = ActionTypes

export function loadMe(cb) {
  return dispatch => {
    dispatch({
      type: REQUEST_SET_LOADING_STATE,
      data: true
    })

    const url = `/v1/admin/me`
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_ME,
          data: body.admin_user
        })

        dispatch({
          type: RECEIVE_GET_ACCOUNTS,
          data: body.admin_user
        })

        dispatch({
          type: REQUEST_SET_LOADING_STATE,
          data: false
        })
      } else {
        dispatch(logout())
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updateMe(me, cb) {
  return dispatch => {
    const params = { admin_user: me }
    const url = `/v1/admin/admin_users/${me.id}`
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPDATE_ME,
          data: body.admin_user
        })
      }
      cb && cb(statusCode, body)
    })
  }
}

export function updateAvatar(userId, avatar, cb) {
  return dispatch => {
    const params = { avatar }
    const url = `/v1/admin/admin_users/${userId}/upload_avatar`
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_ME_AVATAR_UPDATE,
          data: body.admin_user
        })
      }
      cb && cb(statusCode, body)
    })
  }
}
