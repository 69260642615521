/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

const RegExpUtils = {
  filter(query) {
    if (!query) return ''
    return query.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
  }
}

export default RegExpUtils
