import React from 'react'
import { connect } from 'react-redux'

import EditLandingPage from './EditLandingPage'

class EditLandingPageWrapper extends React.Component {
  render() {
    const { dispatch, match, hotels } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params

    const hotel = hotels && hotels.get(selectedHotelId)

    return (
      <EditLandingPage dispatch={dispatch} selectedAccountId={selectedAccountId} hotel={hotel} />
    )
  }
}

const mapStateToProps = state => ({
  hotels: state.hotels
})

export default connect(mapStateToProps)(EditLandingPageWrapper)
