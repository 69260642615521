const CustomTagColors = [
  '#C02A69',
  '#FF9790',
  '#B8C043',
  '#149AB5',
  '#9FCAEE',
  '#FAD500',
  '#BBBBBB',
  '#8B8B8B',
  '#D2B59D',
  '#EDA1EE',
  '#B841ED',
  '#673AB7'
]

export default CustomTagColors
