/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import alertify from 'alertifyjs'

import AccountsList from '../components/AccountsList'
import CreateNewAccount from '../components/CreateNewAccount'
import CreateNewHotel from '../components/CreateNewHotel'
import Hotel from '../components/Hotel'
import Property from '../components/Property'
import Loader from '../components/Loader'

class Home extends React.Component {
  componentDidMount() {
    alertify.set('notifier', 'delay', 3)
  }

  render() {
    const { match, loader } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params

    return (
      <div className="column-layout">
        <Loader isLoading={loader.get('isLoadingMain')} />
        <AccountsList selectedAccountId={selectedAccountId} selectedHotelId={selectedHotelId} />
        <Switch>
          <Route exact path="/account/:selectedAccountId/hotel/new" component={CreateNewHotel} />
          <Route path="/account/:selectedAccountId/hotel/:selectedHotelId" component={Hotel} />
          <Route exact path="/account/new" component={CreateNewAccount} />
          <Route path="/account/:selectedAccountId" component={Property} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loader: state.loader
})

export default connect(mapStateToProps)(Home)
