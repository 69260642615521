/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, August 2017
 */

import React from 'react'
import classnames from 'classnames'

class CollapsibleListItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  toggleContent = () => {
    this.props.toggleOne(this.props.id)
  }

  render() {
    const { id, list, active } = this.props

    const listStateClassName = active ? 'expanded' : 'collapsed'

    return (
      <div className={`collapsible__list list__${id}`}>
        <h3
          className={classnames('collapsible__list__title', {
            active: list.listItemSelected
          })}
          onClick={this.toggleContent}
        >
          {list.header}
          <div className={`arrow arrow-down ${listStateClassName}`} />
        </h3>
        <div className={`collapsible__list__content ${listStateClassName}`}>
          {list.content}
        </div>
      </div>
    )
  }
}

export default CollapsibleListItem
