/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import { Map } from 'immutable'

const initialState = Map({
  email: '',
  name: '',
  phone: '',
  enabled: true,
  website: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
