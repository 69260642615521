/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fromJS, is, Map } from 'immutable'
import { connect } from 'react-redux'
import Select from 'react-select'
import alertify from 'alertifyjs'
import uuid from 'node-uuid'

import Loader from './Loader'
import {
  loadReplyTemplates,
  createReplyTemplate,
  deleteReplyTemplate,
  addReplyTemplateToAllHotels,
  updateReplyTemplate,
  uploadImageToMessageBird
} from '../actions/HotelActions'
import HotelUtils from '../utils/HotelUtils'
import ReplyTemplateCategories from '../constants/ReplyTemplateCategories'
import EngagementTemplateStatuses from '../constants/ReplyTemplateStatuses'
import Spinner from './Spinner'

const { IN_REVIEW, APPROVED, REJECTED } = EngagementTemplateStatuses
const STATUS_CREATED = 201

class EditReplyTemplate extends React.Component {
  static propTypes = {
    replyTemplate: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      replyTemplate: this.getTemplateProps(props),
      templateCategories: new Map(ReplyTemplateCategories),
      languageOptions: HotelUtils.getLanguagesList(),
      addToAllHotels: false,
      isUploadingAttachment: false,
      isConsentTemplate: false
    }
  }

  componentDidMount() {
    const { dispatch, match, replyTemplates } = this.props
    const { templateId, selectedHotelId } = match && match.params
    const { replyTemplate } = this.state

    if (templateId && replyTemplates.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadReplyTemplates(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }

    if (replyTemplate && replyTemplate.getIn(['consent_template'])) {
      this.setState({
        isConsentTemplate: replyTemplate.getIn(['consent_template'])
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps
    const { templateId } = match && match.params
    if (templateId) {
      // Not needed in case of new template creation
      this.setState({
        replyTemplate: this.getTemplateProps(nextProps)
      })
    }
  }

  getTemplateProps = props => {
    const { match, replyTemplates, replyTemplate } = props
    const { templateId } = match && match.params
    return templateId ? this.findReplyTemplate(props) : replyTemplate
  }

  findReplyTemplate = props => {
    const { match, replyTemplates } = props
    const { templateId } = match && match.params
    let template = replyTemplates.get(templateId)
    if (
      template &&
      template.get('category') === 'N/A' &&
      template.get('template_namespace') === 'N/A' &&
      template.get('whats_app_template_name') === 'N/A'
    ) {
      template = template
        .set('category', '')
        .set('template_namespace', '')
        .set('whats_app_template_name', '')
    }
    return template
  }

  handleChange = ev => {
    let inputVal = ev.target.value
    if (ev.target.name === 'dummy_template') {
      const openingCurlyTags = ((inputVal && inputVal.match(/\{{/g)) || '').length
      const closingCurlyTags = ((inputVal && inputVal.match(/\}}/g)) || '').length
      this.setState({ messageFormatError: openingCurlyTags !== closingCurlyTags })
    } else if (ev.target.name === 'whats_app_template_name') {
      inputVal = inputVal.toLowerCase().replace(/[^a-zA-Z1-9_]/g, '')
    }

    this.setState({
      replyTemplate: this.state.replyTemplate.set(ev.target.name, inputVal)
    })
  }

  handleSelectChange = (option, path) => {
    this.setState({
      replyTemplate: this.state.replyTemplate.set(path, option && option.value)
    })
  }

  handleSubmit = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const { replyTemplate, addToAllHotels } = this.state

    this.setState({ isSaving: true })
    dispatch(
      createReplyTemplate(selectedHotelId, replyTemplate.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 201) {
          return alertify.error((body && body.error) || 'Error creating template.')
        }

        addToAllHotels &&
          dispatch(
            addReplyTemplateToAllHotels(
              body.whats_app_engagement_template.id,
              (statusCode, body) => {
                if (statusCode !== 201) {
                  return alertify.error(
                    (body && body.error) || 'Error adding template to all hotels at account level.'
                  )
                }
              }
            )
          )

        alertify.success('Template successfully created.')
        this.handleCancel()
      })
    )
  }

  handleUpdate = (ev, action) => {
    ev && ev.preventDefault()
    action === APPROVED && this.setState({ isApproving: true })
    action === REJECTED && this.setState({ isRejecting: true })

    const { dispatch, match, history } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    const { replyTemplate } = this.state

    let updatedTemplate = replyTemplate.set('status', action)

    updatedTemplate =
      action === REJECTED ? this.updateRejectedTemplated(updatedTemplate) : updatedTemplate

    dispatch(
      updateReplyTemplate(updatedTemplate.get('id'), updatedTemplate.toJS(), (statusCode, body) => {
        action === APPROVED && this.setState({ isApproving: false })
        action === REJECTED && this.setState({ isRejecting: false })
        if (statusCode !== 204) {
          return alertify.error(
            (body && body.error) ||
              `Error ${action === REJECTED ? 'rejecting' : 'approving'} template.`
          )
        }
        alertify.success(`Template successfully ${action === REJECTED ? 'rejected' : 'approved'}`)
        history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
      })
    )
  }

  updateRejectedTemplated = template => {
    return template
      .set('category', 'N/A')
      .set('template_namespace', 'N/A')
      .set('whats_app_template_name', 'N/A')
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    const { match, history } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates`)
  }

  handleDelete = ev => {
    ev && ev.preventDefault()

    const { dispatch } = this.props
    const { replyTemplate } = this.state

    this.setState({ isDeleting: true })
    dispatch(
      deleteReplyTemplate(replyTemplate.get('id'), (statusCode, body) => {
        this.setState({ isDeleting: false })
        if (statusCode !== 204) {
          return alertify.error((body && body.error) || 'Error deleting template.')
        }

        alertify.success('Template successfully deleted.')
        this.handleCancel()
      })
    )
  }

  handleCheckBoxClick = () => this.setState({ addToAllHotels: !this.state.addToAllHotels })

  handleCheckBoxConsentTemplate = () =>
    this.setState({
      isConsentTemplate: !this.state.isConsentTemplate,
      replyTemplate: this.state.replyTemplate.set('consent_template', !this.state.isConsentTemplate)
    })

  isPristine = () => {
    const {
      replyTemplate,
      messageFormatError,
      isSaving,
      isApproving,
      isRejecting,
      isUploadingAttachment
    } = this.state

    return (
      !replyTemplate.get('name') ||
      !replyTemplate.get('whats_app_template_name') ||
      !replyTemplate.get('template_namespace') ||
      !replyTemplate.get('category') ||
      !replyTemplate.get('language') ||
      !replyTemplate.get('dummy_template') ||
      isSaving ||
      isApproving ||
      isRejecting ||
      isUploadingAttachment ||
      messageFormatError
    )
  }

  handleImageUpload = e => {
    e.preventDefault()
    const { match } = this.props
    const { selectedHotelId } = match && match.params
    const attachmentUnique = uuid()
    const files = e.target.files || {}
    let formData = new FormData()
    formData.append('giofile', files[0])
    this.setState({ isUploadingAttachment: true })
    uploadImageToMessageBird(selectedHotelId, attachmentUnique, formData, (status, response) => {
      this.setState({ isUploadingAttachment: false })
      if (status !== STATUS_CREATED) {
        return alertify.error((response && response.error) || 'Error uploading image')
      }

      this.setState({
        replyTemplate: this.state.replyTemplate.set('media_url', response.media_url)
      })
    })
  }

  removeFilesAttachment = e => {
    e.preventDefault()
    this.setState({
      replyTemplate: this.state.replyTemplate.set('media_url', '')
    })
  }

  render() {
    const { match } = this.props
    const { templateId } = match && match.params
    const {
      templateCategories,
      languageOptions,
      replyTemplate,
      isLoading,
      isSaving,
      isDeleting,
      isApproving,
      isRejecting,
      messageFormatError,
      addToAllHotels,
      isUploadingAttachment,
      isConsentTemplate
    } = this.state

    const mediaUrl = replyTemplate && replyTemplate.get('media_url')

    const meppedTemplateCategories = fromJS(
      templateCategories.map((value, key) => {
        return { value: key, label: value }
      })
    )
    if (isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }

    if (!replyTemplate) {
      return <div className="settings-container">Template not found.</div>
    }

    return (
      <form className="settings-container reply-templates-settings" autoComplete="off">
        <h2>{templateId ? '' : 'Create'} WhatsApp Engagement Template </h2>

        <h3>
          <input
            type="text"
            placeholder="Name this template"
            name="name"
            onChange={this.handleChange}
            value={replyTemplate.get('name')}
            required
          />
        </h3>

        <fieldset>
          <legend>WhatsApp Template Name</legend>
          <input
            type="text"
            name="whats_app_template_name"
            placeholder="To be obtained from MessageBird"
            onChange={this.handleChange}
            value={replyTemplate.get('whats_app_template_name')}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Template Namespace</legend>
          <input
            type="text"
            name="template_namespace"
            placeholder="To be obtained from MessageBird"
            onChange={this.handleChange}
            value={replyTemplate.get('template_namespace')}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Category</legend>
          <Select
            value={replyTemplate.get('category')}
            options={meppedTemplateCategories.toArray()}
            onChange={option => this.handleSelectChange(option, 'category')}
            clearable={false}
          />
        </fieldset>

        <fieldset>
          <legend>Language</legend>
          <Select
            value={replyTemplate.get('language')}
            options={languageOptions.toJS()}
            onChange={option => this.handleSelectChange(option, 'language')}
            clearable={false}
          />
        </fieldset>

        <fieldset>
          <legend>Message</legend>
          <textarea
            placeholder="Enter template content here"
            name="dummy_template"
            onChange={this.handleChange}
            value={replyTemplate.get('dummy_template')}
            required
          />
        </fieldset>

        {!templateId && !mediaUrl ? (
          <label title="Upload New Attachment" className="new-attachment">
            {isUploadingAttachment ? (
              <div className="spinner__wrapper list-style-none">
                <Spinner />
              </div>
            ) : (
              <img src="/icons/attachment-icon.svg" className="attachment-icon" />
            )}
            <input
              type="file"
              id="file"
              name="file"
              accept={'.png,.jpeg,.jpg'}
              className="attachment-upload-input"
              onChange={this.handleImageUpload}
            />
            Add Image
          </label>
        ) : (
          mediaUrl && (
            <div>
              <div className="quick-reply-attachment">
                <img src={mediaUrl} className="attachment-icon" />
                {!templateId && (
                  <div>
                    <span onClick={this.removeFilesAttachment} className="attachment-remove">
                      remove
                    </span>
                    <span className="cross-text" onClick={this.removeFilesAttachment}>
                      x
                    </span>
                  </div>
                )}
              </div>
            </div>
          )
        )}

        <fieldset className="preview-screen">
          <div>
            <span>Preview: </span>
            <span className="render-error-message">
              {messageFormatError &&
                'At least one of the field variables in the message above is entered incorrectly. Please double-check the format, remembering the need for double braces.'}
            </span>
            <blockquote className={messageFormatError ? 'render-error' : ''}>
              {mediaUrl && <img src={mediaUrl} width="50" height="50" />}
              <pre className="text">{replyTemplate.get('dummy_template')}</pre>
            </blockquote>
          </div>
        </fieldset>

        {!templateId && (
          <fieldset className="form-item">
            <div>
              <div
                className={`select-field ${addToAllHotels ? 'selected' : ''}`}
                onClick={() => this.handleCheckBoxClick()}
              >
                {addToAllHotels && <img src={`/icons/select-tick.svg`} alt="" />}
              </div>
              <div>Add to all hotels at account level</div>
            </div>
          </fieldset>
        )}

        <fieldset className="form-item">
          <div>
            <div
              className={`select-field ${isConsentTemplate ? 'selected' : ''}`}
              onClick={() => this.handleCheckBoxConsentTemplate()}
            >
              {isConsentTemplate && <img src={`/icons/select-tick.svg`} alt="" />}
            </div>
            <div>Consent template</div>
          </div>
        </fieldset>

        <fieldset className="tmargin2em">
          {!templateId ? (
            <button
              disabled={this.isPristine()}
              type="button"
              className="button"
              onClick={this.handleSubmit}
            >
              {isSaving ? 'Creating...' : 'Create'}
            </button>
          ) : replyTemplate.get('status') !== IN_REVIEW ? (
            <button type="button" className="button button-delete" onClick={this.handleDelete}>
              {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
          ) : (
            <div style={{ float: 'right' }}>
              <button
                type="button"
                className="button button-delete"
                style={{ marginRight: '10px' }}
                onClick={e => this.handleUpdate(e, REJECTED)}
              >
                {isRejecting ? 'Rejecting...' : 'Reject'}
              </button>
              <button
                disabled={this.isPristine()}
                type="button"
                className="button"
                onClick={e => this.handleUpdate(e, APPROVED)}
              >
                {isApproving ? 'Approving...' : 'Approve'}
              </button>
            </div>
          )}
          <button type="cancel" className="button" onClick={this.handleCancel}>
            Cancel
          </button>
        </fieldset>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  replyTemplates: state.replyTemplates,
  replyTemplate: state.replyTemplate
})

export default connect(mapStateToProps)(EditReplyTemplate)
