/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import EditAccount from './EditAccount'
import { loadAccountHotels } from '../actions/HotelActions'

class Account extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ selectedAccountId: PropTypes.string })
  }

  componentWillUpdate(nextProps, nextState) {
    const { dispatch, match, accounts } = this.props
    // This needs to be done only once on page reload after accounts data is fetched
    if (accounts.isEmpty() && !nextProps.accounts.isEmpty()) {
      const accountId = match.params.selectedAccountId
      dispatch(loadAccountHotels(accountId))
    }
  }

  render() {
    const { dispatch, match, accounts } = this.props
    const { selectedAccountId } = match && match.params

    const account = selectedAccountId
      ? accounts.get(selectedAccountId)
      : new Map()

    return account ? (
      <div className="item-container-main">
        <EditAccount dispatch={dispatch} account={account} />
      </div>
    ) : null
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts
})

export default connect(mapStateToProps)(Account)
