import React from 'react'

class PreviewInGridFormat extends React.Component {
  constructor(props) {
    super(props)
  }

  imageRender = () => {
    return `<img alt="delete" src="/icons/delete-grey.svg" />`
  }

  render() {
    const { rowData } = this.props
    const count = rowData.length

    return (
      <React.Fragment>
        <div className="bulk-table" style={{ height: 'auto', width: 'auto' }}>
          <div
            className="ag-theme-alpine"
            style={{ height: 300, width: 'auto', overflowX: 'auto' }}
          >
            <table className="quick-reply-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Reply</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {rowData.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{row.name || ''}</td>
                      <td>{row.reply || ''}</td>
                      <td>
                        <img
                          alt="delete"
                          src="/icons/delete-grey.svg"
                          onClick={() => this.props.handleDeleteFromBulk(i)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h4>Total Rows: {count}</h4>
        </div>
      </React.Fragment>
    )
  }
}
export default PreviewInGridFormat
