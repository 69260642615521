/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { logout } from '../actions/AuthActions'

class Logout extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(logout())
    dispatch(push('/login'))
    window.location.reload()
  }

  render() {
    return <div>Logging you out...</div>
  }
}

export default connect()(Logout)
