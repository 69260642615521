/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, November 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import alertify from 'alertifyjs'
import { fromJS } from 'immutable'

import Loader from './Loader'
import Spinner from './Spinner'
import HighlightSearchMatches from './HighlightSearchMatches'
import {
  loadHotelSubProperties,
  updateHotelSubProperty,
  updateHotelSubPropertyLogo
} from '../actions/HotelActions'

class HotelSubProperties extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      deleting: {}
    }
  }

  componentDidMount() {
    const { dispatch, match, selectedAccountId } = this.props
    const { selectedHotelId } = match && match.params

    let formData = new FormData()

    this.setState({ isLoading: true, isLoadingLogo: true, errorLogoUpdate: null })
    dispatch(
      loadHotelSubProperties(selectedHotelId, () => {
        this.setState({ isLoading: false })
      }),
      updateHotelSubPropertyLogo(
        selectedAccountId,
        selectedHotelId,
        formData,
        (statusCode, body) => {
          this.setState({ isLoadingLogo: false })
          if (statusCode !== 200) {
            return this.setState({ errorLogoUpdate: fromJS(body) })
          }
        }
      )
    )
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  handleDelete = subProperty => ev => {
    ev && ev.stopPropagation()
    const { dispatch } = this.props
    const { deleting } = this.state

    const confirmMsg = `Are you sure you want to delete this sub property?`
    alertify.confirm(confirmMsg).set({
      onok: () => {
        const subPropertyId = subProperty.get('id')
        const currentStatus = subProperty.get('is_active')
        subProperty = subProperty.set('is_active', !currentStatus)

        const updatedSubProperty = {
          name: subProperty.get('name'),
          phone: subProperty.get('phone'),
          city: subProperty.get('city'),
          email: subProperty.get('email'),
          guests_landing_page_url: subProperty.get('guests_landing_page_url'),
          guests_landing_page_header_text: subProperty.get('guests_landing_page_header_text'),
          is_active: !currentStatus
        }

        this.setState({
          deleting: { ...deleting, [subPropertyId]: true },
          error: null
        })
        dispatch(
          updateHotelSubProperty(subProperty.get('id'), updatedSubProperty, (statusCode, body) => {
            this.setState({ deleting: { ...deleting, [subPropertyId]: false } })
            if (statusCode !== 200) {
              return alertify.error((body && body.error) || 'Error deleting sub property.')
            }

            alertify.success(`Sub Property successfully deleted.`)
          })
        )
      }
    })
  }

  render() {
    const { match, subProperties } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { query, isLoading, deleting } = this.state

    const activeSubProperties = subProperties
      .toList()
      .filter(subProperty => subProperty.get('is_active') === true)
      .sortBy(subProperty => subProperty.get('name'))

    return (
      <div
        className="settings-container sub-properties-settings display-flex-column"
        onScroll={this.handleScroll}
      >
        <Loader isLoading={isLoading} />
        <div ref={node => (this.hotelContentWraper = node)}>
          <div className="header-items">
            <Link
              to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/sub-properties/new`}
              className="button pull-right"
            >
              Add sub property
            </Link>

            <h1>
              Sub Properties <span className="entity-count">({activeSubProperties.size})</span>
            </h1>
          </div>

          <div className="sub-properties">
            <div className="top-notes">
              <input
                className="settings-search-input"
                type="search"
                onChange={this.handleSearch}
                value={query || ''}
                placeholder="Search sub property"
              />
            </div>
            <table>
              <tbody>
                {activeSubProperties.map(subProperty => (
                  <tr key={subProperty.get('id')} className="sub-property">
                    <td>
                      <Link
                        to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/sub-properties/${subProperty.get(
                          'id'
                        )}`}
                      >
                        <img src={subProperty.get('logo')} className="avatar" />
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/sub-properties/${subProperty.get(
                          'id'
                        )}`}
                      >
                        <strong className="name">
                          <HighlightSearchMatches
                            query={query}
                            messageText={`${subProperty.get('name')}`}
                          />
                        </strong>{' '}
                      </Link>
                    </td>
                    <td>
                      <button
                        type="cancel"
                        className="button"
                        onClick={this.handleDelete(subProperty)}
                        disabled={deleting[subProperty.get('id')]}
                      >
                        <img src="/icons/delete-grey.svg" alt="" className="delete" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="spinner__wrapper">
          {isLoading ? (
            <div className="messages__spinner list-style-none">
              <Spinner />
            </div>
          ) : (
            <div className="filler"> </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  subProperties: state.subProperties
})

export default connect(mapStateToProps)(HotelSubProperties)
