/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

const style = {
  overlay: {
    zIndex: 1,
    background: 'rgba(41,47,47,0.9)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5em'
  },
  content: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    padding: 0,
    borderRadius: 5,
    border: 0,
    display: 'flex',
    height: '100%',
    position: 'static',
    width: '38em',
    maxWidth: '100%'
  }
}

export default style
