/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { Map } from 'immutable'
import _ from 'lodash'
import alertify from 'alertifyjs'

import { saveBusinessType } from '../actions/AccountActions'
import ModalStyle from '../constants/ModalStyle'

class CreateNewBusinessType extends React.Component {
  static propTypes = {
    businessType: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      businessType: props.businessType,
      featureFlags: new Map()
    }
  }

  componentDidMount() {
    this.processFeatureFlags(this.props.featureFlags)
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.featureFlags.size !== nextProps.featureFlags.size) {
      this.processFeatureFlags(nextProps.featureFlags)
    }
  }

  processFeatureFlags = featureFlags => {
    const { businessType } = this.state

    const defaultFeatureFlags = businessType.get('supported_feature_flags') || new Map()

    const updatedFeatureFlags = featureFlags.map((flag, key) =>
      flag.set('value', !!defaultFeatureFlags.get(key))
    )

    this.setState({ featureFlags: updatedFeatureFlags })
  }

  handleChange = ev => {
    this.setState({
      businessType: this.state.businessType.set(ev.target.name, ev.target.value)
    })
  }

  toggleFlag = flag => ev => {
    const updatedFlag = flag.set('value', !flag.get('value'))

    this.setState({
      featureFlags: this.state.featureFlags.set(flag.get('id'), updatedFlag)
    })
  }

  isPristine = () => {
    const { businessType, isSaving } = this.state

    return (
      !businessType.get('name') ||
      !businessType.get('agent_type') ||
      !businessType.get('end_user_type') ||
      isSaving
    )
  }

  handleSave = ev => {
    ev && ev.preventDefault()

    const { dispatch } = this.props
    const { businessType, featureFlags } = this.state

    const selectedFeatureFlags = featureFlags
      .filter(flag => flag.get('value'))
      .map(flag => flag.get('name'))
      .toList()

    const updatedBusinessType = businessType.set('supported_feature_flags', selectedFeatureFlags)

    this.setState({ isSaving: true })
    dispatch(
      saveBusinessType(updatedBusinessType.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })

        if (statusCode !== 200 && statusCode !== 201) {
          return alertify.error((body && body.error) || `Error saving business type!`)
        }

        alertify.success(`Business type successfully saved.`)
        setTimeout(() => this.handleCancel(), 750)
      })
    )
  }

  handleCancel = ev => {
    ev && ev.preventDefault()

    this.props.onRequestClose()
  }

  render() {
    const { onRequestClose, ...props } = this.props
    const { businessType, featureFlags } = this.state

    ModalStyle.content.width = '48em'
    ModalStyle.content.maxHeight = '72em'
    ModalStyle.content.animation = 'zoomInModal 0.35s linear'

    const sortedFlags = featureFlags.sortBy(flag =>
      _.toLower(flag.get('display_name') || flag.get('name'))
    )

    return (
      <ReactModal {...props} style={ModalStyle} ariaHideApp={false}>
        <div className="modal-popup create-business-type-modal">
          <header className="modal-popup-header">
            <h3>Create New Business Type</h3>
            <button onClick={onRequestClose} className="close">
              <img src="icons/close_black_24px.svg" className="icon" alt="Close" />
            </button>
          </header>

          <div className="modal-popup-body padding2em">
            <fieldset>
              <legend>Business Category Name</legend>
              <input
                type="text"
                name="name"
                className="input-field"
                value={businessType.get('name') || ''}
                onChange={this.handleChange}
                placeholder="Hotel, Hospital, School, etc."
              />
            </fieldset>

            <fieldset>
              <legend>Agent Type</legend>
              <input
                type="text"
                name="agent_type"
                className="input-field"
                value={businessType.get('agent_type') || ''}
                onChange={this.handleChange}
                placeholder="Staff, Doctor, Teacher, etc."
              />
            </fieldset>

            <fieldset>
              <legend>End-User Type</legend>
              <input
                type="text"
                name="end_user_type"
                className="input-field"
                value={businessType.get('end_user_type') || ''}
                onChange={this.handleChange}
                placeholder="Guest, Patient, Student, Customer, etc."
              />
            </fieldset>

            <fieldset>
              <legend>Feature Flags</legend>
              <div className="feature-flags-wrapper">
                {sortedFlags.toList().map(flag => (
                  <div key={flag.get('id')} className={`flag-item`} onClick={this.toggleFlag(flag)}>
                    <div className={`flag-select ${flag.get('value') ? 'selected' : ''}`}>
                      {flag.get('value') && <img src={`/icons/select-tick.svg`} alt="Selected" />}
                    </div>
                    <div className="flag-name">
                      {flag.get('display_name') || flag.get('name').replace(/_/g, ' ')}
                    </div>
                    <div className="description" title={flag.get('description')}>
                      {flag.get('description')}
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>

            <div className="actions">
              <button
                type="button"
                disabled={this.isPristine()}
                className="button submit-button"
                onClick={this.handleSave}
              >
                Save
              </button>
              <button type="cancel" className="button" onClick={this.handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

const mapStateToProps = state => ({
  businessType: state.businessType
})

export default connect(mapStateToProps)(CreateNewBusinessType)
