/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'

class Loader extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool
  }

  render() {
    const { isLoading } = this.props

    return (
      <div
        className={`loader display-flex ${!isLoading ? 'remove-loader' : ''}`}
      >
        {isLoading ? <img src={'/icons/ring.svg'} alt="" /> : null}
      </div>
    )
  }
}

export default Loader
