import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import alertify from 'alertifyjs'

import Loader from './Loader'
import { loadHotelWidgets, updateHotelWidget } from '../actions/HotelActions'

class WebWidgets extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelWidgets(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  changeWidgetStatus = widget => ev => {
    ev && ev.stopPropagation()
    const { dispatch, match } = this.props
    const currentStatus = widget.get('active')
    widget = widget.set('active', !currentStatus)

    const updatedWidget = {
      active: !currentStatus,
      is_whitelisted: widget.get('is_whitelisted'),
      domain_url: widget.get('domain_url'),
      host: widget.get('host'),
      welcome_message: widget.get('welcome_message')
    }

    dispatch(
      updateHotelWidget(widget.get('id'), updatedWidget, (statusCode, body) => {
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error updating widget.')
        }

        alertify.success(`Widget successfully ${currentStatus ? 'disabled' : 'enabled'}.`)
      })
    )
  }

  render() {
    const { match, webWidgets } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading } = this.state

    return (
      <div className="settings-container web-widget-settings display-flex-column">
        <Loader isLoading={isLoading} />

        <Link
          to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/web-widget/new`}
          className="button pull-right"
        >
          Add New
        </Link>
        <h2 className="tmargin0em">
          Web Widgets <span className="entity-count">({webWidgets.size})</span>
        </h2>

        <div className="web-widgets-wrapper">
          <ul className="entity-list">
            {webWidgets.toList().map((widget, index) => (
              <li key={widget.get('id')} className="web-widget">
                <Link
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/web-widget/${widget.get(
                    'id'
                  )}`}
                >
                  <strong className="name">{widget.get('domain_url')}</strong>
                  <div
                    className="custom-control custom-switch pull-right"
                    onClick={e => e && e.stopPropagation()}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`widgetStatus${index}`}
                      checked={widget.get('active')}
                      onChange={this.changeWidgetStatus(widget)}
                    />
                    <label className="custom-control-label" htmlFor={`widgetStatus${index}`} />
                  </div>
                  <small>Host: {widget.get('host')} </small>
                  <div className="web-widget-text">{widget.get('welcome_message')}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  webWidgets: state.webWidgets
})

export default connect(mapStateToProps)(WebWidgets)
