/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { is } from 'immutable'
import alertify from 'alertifyjs'

import ToggleShowPassword from './ToggleShowPassword'
import { logout } from '../actions/AuthActions'
import { updateMe, updateAvatar } from '../actions/MeActions'

class EditMe extends React.Component {
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string })
  }

  constructor(props) {
    super(props)

    this.state = {
      me: props.me,
      showPassword: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      me: nextProps.me
    })
  }

  handleChange = ev => {
    const { name, value } = ev.target

    if ((name === 'password' || name === 'password_confirmation') && value === '') {
      return this.setState({ me: this.state.me.delete(name) })
    }

    const me = this.state.me.set(name, value)
    this.setState({ me: me })
  }

  handleSubmit = ev => {
    const { dispatch } = this.props
    const isPasswordChanged = this.state.me.get('password') ? true : false
    ev.preventDefault()

    this.setState({ isSaving: true, error: null })
    dispatch(
      updateMe(this.state.me.toJS(), (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200) {
          return this.setState({ error: body && body.error })
        } else {
          if (isPasswordChanged) this.logout()
          else alertify.success('Profile successfuly updated.')
        }
      })
    )
  }

  logout = () => {
    alert('Password updated successfully! Login again with new password to continue')
    this.props.dispatch(logout())
  }

  handleUploadAvatar = ev => {
    const { dispatch } = this.props
    const file = ev.target.files[0]
    if (!file) return

    const reader = new window.FileReader()

    const me = this.state.me

    reader.addEventListener(
      'load',
      () => {
        const avatar = reader.result
        this.setState({ isUploading: true, errorAvatarUpdate: null })
        dispatch(
          updateAvatar(me.get('id'), avatar, (statusCode, body) => {
            this.setState({ isUploading: false })
            if (statusCode !== 200) {
              return this.setState({ errorAvatarUpdate: body && body.error })
            }
          })
        )
      },
      false
    )

    reader.readAsDataURL(file)
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({ me: this.props.me })
  }

  isPristine = () => {
    return is(this.state.me, this.props.me) || this.state.isSaving
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const { me, error, showPassword, isUploading, errorAvatarUpdate } = this.state

    return (
      <form onSubmit={ev => ev.preventDefault()} className="admin-user-settings">
        <h1>My Account</h1>

        <div className="column-layout">
          <div className="account-info w-80pct">
            <div className="name column-layout">
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                value={me.get('first_name') || ''}
                onChange={this.handleChange}
                className="w-50pct rmargin0-5em"
              />
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={me.get('last_name') || ''}
                onChange={this.handleChange}
                className="w-50pct"
              />
            </div>

            <input
              type="text"
              className=""
              placeholder="Email Address"
              disabled
              name="email"
              value={me.get('email') || ''}
              onChange={this.handleChange}
            />

            <fieldset>
              <input
                type="file"
                name="avatar"
                ref={node => (this.avatarNode = node)}
                accept=".png,.jpg,.jpeg,.gif"
                className="hidden "
                onChange={this.handleUploadAvatar}
              />
              <button
                className="button "
                onClick={ev => {
                  ev.preventDefault()
                  this.avatarNode.click()
                }}
                disabled={isUploading}
              >
                {isUploading ? 'Uploading profile photo...' : 'Upload a profile photo...'}
              </button>

              {errorAvatarUpdate && <div className="error">{errorAvatarUpdate}</div>}
            </fieldset>

            <fieldset className="password-fields">
              <legend>Update your password</legend>
              <input
                type="password"
                name="password"
                value={me.get('password') || ''}
                placeholder="Enter your new password..."
                onChange={this.handleChange}
                className=""
              />
              <input
                type={showPassword ? 'text' : 'password'}
                name="password_confirmation"
                className="rpadding3em"
                value={me.get('password_confirmation') || ''}
                placeholder="Confirm your new password..."
                onChange={this.handleChange}
              />
              {me.get('password_confirmation') ? (
                <ToggleShowPassword
                  showPassword={showPassword}
                  handleShowPassword={this.handleShowPassword}
                />
              ) : null}
            </fieldset>
          </div>
        </div>

        {error && error.first_name ? (
          <div className="error">First Name {error.first_name}</div>
        ) : null}
        {error && error.last_name ? <div className="error">Last Name {error.last_name}</div> : null}
        {error && error.phone ? <div className="error">{error.phone}</div> : null}
        {error && error.password ? <div className="error">{error.password}</div> : null}
        {error && error.password_confirmation ? (
          <div className="error">{error.password_confirmation}</div>
        ) : null}

        <div className="actions">
          <button disabled={this.isPristine()} className="button" onClick={this.handleSubmit}>
            Save Changes
          </button>
          <button type="cancel" className="button" onClick={this.handleReset}>
            Reset
          </button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  me: state.me
})

export default connect(mapStateToProps)(EditMe)
