/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

const Endpoints = {
  API: process.env.REACT_APP_GLOWING_API || 'https://api.stage.glowing.io',
  SOCKET_API: process.env.REACT_APP_GLOWING_SOCKET_API || 'https://socket.stage.glowing.io',
  APP: process.env.ENDPOINT_APP || window.location.protocol + '//' + window.location.host,
  PROD_VERSION: 'v1.0.11',
  STAG_VERSION: 'v1.0.11'
}

export default Endpoints
