import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loader from './Loader'

import { loadQuickReply } from '../actions/HotelActions'

class QuickReplies extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadQuickReply(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }
  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  render() {
    const { match, quickReplies } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading } = this.state
    console.log(quickReplies.toJS())
    return (
      <div className="edit-quick-reply">
        <div className="quick-replies-settings display-flex-coloumn">
          <div className="header-items">
            <Loader isLoading={isLoading} />
            <Link
              to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/quick-replies/new`}
              className="button pull-right"
            >
              Add Quick Reply
            </Link>
            <h1>
              <span>Quick Replies</span>
              <span className="entity-count">({quickReplies.size})</span>
            </h1>
            <p className="help">Saved quick reply will be shown in below list.</p>
            <div className="settings-search-container">
              <input
                className="settings-search-input"
                type="search"
                placeholder="Search quick reply"
              />
            </div>
          </div>
          <ul className="quick-replies-list entity-list">
            {quickReplies.toList().map(qr => (
              <li className="quick-reply" key={qr.get('id')}>
                <Link
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/quick-replies/${qr.get(
                    'id'
                  )}`}
                >
                  <strong className="name">{qr.get('name')}</strong>
                  <div className="quick-reply-text">{qr.get('reply')}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  quickReplies: state.quickReplies
})

export default connect(mapStateToProps)(QuickReplies)
