/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, November 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from './Loader'
import { loadReplyTemplates } from '../actions/HotelActions'
import ReplyTemplateCategories from '../constants/ReplyTemplateCategories'
import EngagementTemplateStatuses from '../constants/ReplyTemplateStatuses'

const { IN_REVIEW, APPROVED, REJECTED } = EngagementTemplateStatuses

class ReplyTemplates extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadReplyTemplates(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  getTemplateStatusText = status => {
    if (status === IN_REVIEW || status === REJECTED) {
      return `[${status.replace(/_/g, ' ').toUpperCase()}] `
    }
    return ''
  }

  render() {
    const { match, replyTemplates } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading } = this.state

    return (
      <div className="settings-container reply-templates-settings display-flex-column">
        <Loader isLoading={isLoading} />

        <Link
          to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates/new`}
          className="button pull-right"
        >
          Add Template
        </Link>
        <h2 className="tmargin0em">
          WhatsApp Engagement Templates{' '}
          <span className="entity-count">({replyTemplates.size})</span>
        </h2>

        <div className="reply-templates-wrapper">
          <ul className="entity-list">
            {replyTemplates.toList().map(template => (
              <li key={template.get('id')} className="reply-template">
                <Link
                  to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/reply-templates/${template.get(
                    'id'
                  )}`}
                >
                  <strong className="name">
                    {this.getTemplateStatusText(template.get('status'))}
                    {template.get('name')}
                  </strong>
                  <div className="reply-template-text" style={{ width: window.innerWidth - 512 }}>
                    {template.get('dummy_template')}
                  </div>
                  <small>Category: {ReplyTemplateCategories[template.get('category')]} </small>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  replyTemplates: state.replyTemplates
})

export default connect(mapStateToProps)(ReplyTemplates)
