/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2019
 */

import { Map } from 'immutable'

const initialState = Map({
  category: '',
  dummy_template: '',
  is_active: true,
  language: 'en',
  name: '',
  template_namespace: '',
  whats_app_template_name: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
