import { OrderedMap } from 'immutable'

const initialState = OrderedMap({
  host: '',
  domain_url: '',
  welcome_message: '',
  active: true,
  is_whitelisted: true,
  access_token: '',
  script: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
