/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nitin Verma <nitinvupk83@gmail.com>, May 2021
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_QUICK_REPLIES,
  RECEIVE_GET_HOTEL_QUICK_REPLY,
  RECEIVE_DELETE_HOTEL_QUICK_REPLY
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, quickReplyId } = action
  switch (type) {
    case RECEIVE_GET_HOTEL_QUICK_REPLIES:
      return new OrderedMap(data.quick_replies.map(qr => [qr.id, fromJS(qr)]))

    case RECEIVE_GET_HOTEL_QUICK_REPLY:
      return state.set(data.quick_replies.map(qr => [qr.id, fromJS(qr)]))

    case RECEIVE_DELETE_HOTEL_QUICK_REPLY:
      return state.remove(quickReplyId)

    default:
      return state
  }
}
