/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import { connect } from 'react-redux'

import EditHotel from './EditHotel'

class EditHotelWrapper extends React.Component {
  render() {
    const { dispatch, match, hotels } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params

    const hotel = hotels && hotels.get(selectedHotelId)

    return (
      <EditHotel
        dispatch={dispatch}
        selectedAccountId={selectedAccountId}
        hotel={hotel}
      />
    )
  }
}

const mapStateToProps = state => ({
  hotels: state.hotels
})

export default connect(mapStateToProps)(EditHotelWrapper)
