/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from './Loader'
import HotelIntegration from './HotelIntegration'
import { loadIntegrations } from '../actions/HotelIntegrationActions'

class EditHotelIntegration extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { dispatch, match, hotelIntegrations } = this.props
    const { selectedHotelId, integrationId } = match && match.params

    if (integrationId && hotelIntegrations && hotelIntegrations.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadIntegrations(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  render() {
    const { dispatch, match, hotelIntegrations } = this.props
    const { selectedAccountId, selectedHotelId, integrationId } = match && match.params
    const { isLoading } = this.state

    const integration = hotelIntegrations.get(integrationId)

    return (
      <div className="settings-container display-flex-column">
        <div className="edit-integration">
          {integration ? (
            <HotelIntegration
              dispatch={dispatch}
              selectedAccountId={selectedAccountId}
              selectedHotelId={selectedHotelId}
              integration={integration}
            />
          ) : (
            <Loader isLoading={isLoading} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelIntegrations: state.hotelIntegrations
})

export default connect(mapStateToProps)(EditHotelIntegration)
