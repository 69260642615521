/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'

import { update2faSettings } from '../actions/HotelActions'

class Hotel2faSettings extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      updating: false,
      is2faenabled: props.hotel && props.hotel.get('two_fa_enabled')
    }
  }

  componentWillReceiveProps(nextProps) {
    const { hotel } = nextProps
    if (this.props.hotel.get('two_fa_enabled') !== hotel && hotel.get('two_fa_enabled')) {
      this.setState({ is2faenabled: hotel && hotel.get('two_fa_enabled') })
    }
  }

  toggle2faSettings = () => {
    const { dispatch, hotel } = this.props
    const status = hotel.get('two_fa_enabled')

    this.setState({ updating: true, error: null })
    dispatch(
      update2faSettings(hotel.get('id'), !status, (statusCode, body) => {
        this.setState({ updating: false })
        if (statusCode !== 200) {
          return this.setState({
            is2faenabled: status,
            error: (body && body.error) || 'Error updating 2FA configuration.'
          })
        }
      })
    )
  }

  render() {
    const { hotel } = this.props
    const { updating, error } = this.state

    const is2faenabled = hotel && hotel.get('two_fa_enabled')

    return (
      <div className="section tmargin0-5em">
        <h3 className="tmargin0em">Two Factor Authentication</h3>

        <ul className="toggle-2fa-silder btn-slider">
          <li>
            <label className="switch vertical-align-middle" disabled={updating}>
              <input
                type="checkbox"
                defaultChecked={is2faenabled}
                onChange={this.toggle2faSettings}
                disabled={updating}
              />
              <span className="slider round" />
            </label>
          </li>
          <li className={`status-label ${is2faenabled && !updating ? 'active' : ''}`}>
            <div>{updating ? 'Updating...' : is2faenabled ? 'Enabled' : 'Disabled'}</div>
          </li>
        </ul>

        {error && <div className="error tmargin1em">{error}</div>}
      </div>
    )
  }
}

export default Hotel2faSettings
