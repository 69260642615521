import React from 'react'
import PropTypes from 'prop-types'
import { is, fromJS } from 'immutable'
import alertify from 'alertifyjs'
import Textarea from 'react-textarea-autosize'

import Loader from './Loader'
import {
  createHotelSubProperty,
  loadHotelSubProperties,
  updateHotelSubProperty,
  updateHotelSubPropertyLogo
} from '../actions/HotelActions'
import { connect } from 'react-redux'

class EditHotelSubProperty extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      selectedAccountId: PropTypes.string,
      selectedHotelId: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      subProperty: this.getSubPropertyProps(props)
    }
  }

  componentDidMount() {
    const { dispatch, match, subProperties } = this.props
    const { subPropertyId, selectedHotelId } = match && match.params
    if (subPropertyId && subProperties.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadHotelSubProperties(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    const { subPropertyId } = match && match.params
    if (subPropertyId && !is(prevProps.subProperties, this.props.subProperties)) {
      this.setState({
        subProperty: this.getSubPropertyProps(this.props)
      })
    }
  }

  getSubPropertyProps = props => {
    const { match, subProperty, subProperties } = props
    const { subPropertyId } = match && match.params
    return subPropertyId ? subProperties.get(subPropertyId) : subProperty
  }

  isPristine = () => {
    const { isSaving } = this.state
    const { subProperties, match } = this.props
    const { subPropertyId } = match && match.params

    const subProperty = subProperties.get(subPropertyId)

    return is(this.state.subProperty, subProperty) || isSaving
  }

  handleSubmit = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId, subPropertyId } = match && match.params
    const { subProperty } = this.state

    this.setState({ isSaving: true, error: null })

    if (subPropertyId) {
      const property = {
        name: subProperty.get('name'),
        phone: subProperty.get('phone'),
        city: subProperty.get('city'),
        email: subProperty.get('email'),
        guests_landing_page_url: subProperty.get('guests_landing_page_url'),
        guests_landing_page_header_text: subProperty.get('guests_landing_page_header_text'),
        is_active: subProperty.get('is_active')
      }
      this.updateSubProperty(subPropertyId, property)
    } else {
      dispatch(
        createHotelSubProperty(selectedHotelId, subProperty.toJS(), (statusCode, body) => {
          this.setState({ isSaving: false })
          if (statusCode !== 201) {
            return alertify.error((body && body.error) || 'Error creating sub property.')
          }

          alertify.success('Sub property successfully created.')
          this.handleCancel()
        })
      )
    }
  }

  updateSubProperty = (subPropertyId, property) => {
    const { dispatch } = this.props

    dispatch(
      updateHotelSubProperty(subPropertyId, property, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error updating sub property.')
        }

        alertify.success('Sub property successfully updated.')
        this.handleCancel()
      })
    )
  }

  handleChange = ev => {
    this.setState({
      subProperty: this.state.subProperty.set(ev.target.name, ev.target.value)
    })
  }

  handleCancel = () => {
    const { history, match } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/sub-properties`)
  }

  handleLogoUpload = ev => {
    ev.preventDefault()
    const { dispatch, match } = this.props
    const { subProperty } = this.state
    const { selectedAccountId } = match && match.params

    const subPropertyId = subProperty.get('id')
    const files = ev.target.files

    let formData = new FormData()
    files && formData.append('logo', files[0])

    this.setState({ isLoadingLogo: true, errorLogoUpdate: null })
    dispatch(
      updateHotelSubPropertyLogo(selectedAccountId, subPropertyId, formData, (statusCode, body) => {
        this.setState({ isLoadingLogo: false })
        if (statusCode !== 200) {
          return this.setState({ errorLogoUpdate: fromJS(body) })
        }

        // Unset/Remove the uploaded file from input handler
        this.subPropertyLogoInput.value = ''
      })
    )
  }

  render() {
    const { subProperty, isLoading, isSaving, isLoadingLogo, errorLogoUpdate } = this.state
    const { match, subProperties } = this.props
    const { subPropertyId } = match && match.params

    if ((subPropertyId && subProperties.isEmpty()) || isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }

    return (
      <form className="settings-container hotel-settings lpadding9em" onSubmit={this.handleSubmit}>
        <Loader isLoading={isLoading} />
        <div className="hotel-avatar avatar">
          {isLoadingLogo ? (
            <img src="/icons/ring.gif" alt="" className="uploading-logo" />
          ) : (
            <img src={subProperty.get('logo')} alt="" />
          )}
        </div>

        <fieldset>
          {subPropertyId ? (
            <div className="logo-upload-wrapper">
              <label className="photo photo-input button button-secondary">
                <div className="photo-input__placeholder">
                  <strong>Upload new business logo</strong>
                </div>
                <input
                  type="file"
                  id="sub-property-logo"
                  name="sub-property-logo"
                  accept=".png,.jpg,.jpeg,.gif"
                  ref={input => {
                    this.subPropertyLogoInput = input
                  }}
                  onChange={this.handleLogoUpload}
                />
              </label>

              {errorLogoUpdate && errorLogoUpdate.has('logo') && (
                <div className="error">Logo {errorLogoUpdate.get('logo')}</div>
              )}
              {errorLogoUpdate && errorLogoUpdate.has('logo_content_type') && (
                <div className="error">
                  Logo content type {errorLogoUpdate.get('logo_content_type')}
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </fieldset>

        <fieldset>
          <div>
            <input
              type="text"
              name="name"
              value={subProperty.get('name')}
              onChange={this.handleChange}
              placeholder="Name"
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="phone"
              value={subProperty.get('phone')}
              onChange={this.handleChange}
              placeholder="Phone Number"
            />
          </div>
          <div>
            <input
              type="text"
              name="city"
              value={subProperty.get('city')}
              onChange={this.handleChange}
              placeholder="City"
            />
          </div>
          <div>
            <input
              type="text"
              name="email"
              value={subProperty.get('email')}
              onChange={this.handleChange}
              placeholder="Email"
            />
          </div>
        </fieldset>

        <fieldset className="w-50pct tmargin2em">
          <legend>Guests Landing page URL</legend>
          <input
            type="url"
            className="margin0"
            name="guests_landing_page_url"
            value={subProperty.get('guests_landing_page_url') || ''}
            onChange={this.handleChange}
          />
        </fieldset>

        <fieldset>
          <legend>Guests landing page header brief</legend>
          <Textarea
            rows={2}
            maxRows={5}
            name="guests_landing_page_header_text"
            value={subProperty.get('guests_landing_page_header_text') || ''}
            onChange={this.handleChange}
            required
          />
        </fieldset>

        <button disabled={this.isPristine()} type="submit" className="button">
          {subPropertyId
            ? isSaving
              ? 'Updating...'
              : 'Update'
            : isSaving
            ? 'Creating...'
            : 'Create'}
        </button>
        <button type="cancel" className="button" onClick={this.handleCancel}>
          Cancel
        </button>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  subProperty: state.subProperty,
  subProperties: state.subProperties
})

export default connect(mapStateToProps)(EditHotelSubProperty)
