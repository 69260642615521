/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import CustomTagColors from '../constants/CustomTagColors'

class EditTag extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    selectedAccountId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      action: props.action,
      isCreating: props.isCreating,
      showColorPicker: false,
      tagText: props.tagName,
      selectedColorCode: props.tagColor,
      defaultTagColor: CustomTagColors[0],
      tagsColorList: CustomTagColors
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({ tagText: nextProps.tagName, isCreating: nextProps.isCreating })
  }

  handleShowColorPicker = () => {
    this.setState({ showColorPicker: !this.state.showColorPicker })
  }

  selectTagColor = colorCode => {
    this.props.changeTagColor(colorCode)
    this.setState({
      showColorPicker: false
    })
  }

  handleButtonclick = () => {
    if (this.props.action === 'creation') {
      this.props.createTag()
    } else {
      this.props.updateTag()
    }
  }

  checkIsDisabled = () => {
    const { action, isCreating, tagText, selectedColorCode } = this.state
    const { tag, tagColor } = this.props
    let isDisabled = false
    if (action === 'creation') {
      isDisabled = isCreating || !tagText
    } else {
      isDisabled = tagText === tag.get('name') && tagColor === selectedColorCode
    }
    return isDisabled
  }

  render() {
    const { handleTagChange, cancelEditing, tagColor } = this.props
    const {
      action,
      isCreating,
      tagText,
      tagsColorList,
      showColorPicker,
      defaultTagColor
    } = this.state
    return (
      <div className="display-flex">
        <input type="text" value={tagText} maxLength={25} onChange={e => handleTagChange(e)} />
        <div className="tag-color-picker-wrapper">
          <button
            className="button button-plain button-color-picker"
            onClick={this.handleShowColorPicker}
            style={{ backgroundColor: tagColor || defaultTagColor }}
            title={!tagColor ? 'Default Tag Color' : 'Selected Tag Color'}
          />
          <ul className={`color-picker ${showColorPicker ? 'show-color-picker' : ''}`}>
            {tagsColorList.map(colorCode => (
              <li key={colorCode}>
                <div
                  className="color-box"
                  style={{ backgroundColor: colorCode }}
                  onClick={() => this.selectTagColor(colorCode)}
                  title={colorCode}
                >
                  {tagColor === colorCode ? <img src={`/icons/select-tick-white.svg`} /> : ''}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button
          className="btn-primary"
          onClick={this.handleButtonclick}
          disabled={this.checkIsDisabled()}
        >
          {action === 'creation' ? (isCreating ? 'Creating...' : 'Create') : 'Update'}
        </button>
        <button className="btn-outline" onClick={cancelEditing}>
          Cancel
        </button>
      </div>
    )
  }
}

export default EditTag
