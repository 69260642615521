/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import alertify from 'alertifyjs'

import Loader from './Loader'
import { loadIntegrations, deleteIntegration } from '../actions/HotelIntegrationActions'

const HOTSOS = 3001

class HotelIntegrations extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      deleting: {}
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadIntegrations(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  handleDeleteIntegration = integration => ev => {
    ev.preventDefault()
    const { dispatch, match } = this.props
    const { deleting } = this.state
    const { selectedHotelId } = match && match.params

    const confirmMsg = `Are you sure you want to delete this integration?`
    alertify.confirm(confirmMsg).set({
      onok: () => {
        const integrationId = integration.get('id')

        this.setState({
          deleting: { ...deleting, [integrationId]: true },
          error: null
        })
        dispatch(
          deleteIntegration(selectedHotelId, integrationId, (statusCode, body) => {
            this.setState({ deleting: { ...deleting, [integrationId]: false } })
            if (statusCode !== 204) {
              return this.setState({
                error: (body && body.error) || `Error deleting integration.`
              })
            }

            alertify.success(`Integration successfuly deleted.`)
          })
        )
      }
    })
  }

  render() {
    const { match, hotelIntegrations } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading, deleting, error } = this.state

    return (
      <div className="settings-container display-flex-column">
        <Loader isLoading={isLoading} />

        <h2 className="tmargin0em">Integrations </h2>

        <div className="integrations-container">
          <ul className="integrations-list">
            {hotelIntegrations.toList().map((integration, key) => {
              if (integration.get('integration_type') === HOTSOS) return <div />
              return (
                <li key={key} className="item">
                  <img
                    src={integration.get('logoUrl')}
                    className={`logo ${integration.get('integrationKey')}`}
                  />
                  <div className="actions">
                    <NavLink
                      activeClassName="active"
                      className="button button-small link"
                      to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/integrations/${integration.get(
                        'id'
                      )}`}
                    >
                      Edit
                    </NavLink>
                    <button
                      className="button button-small button-delete"
                      onClick={this.handleDeleteIntegration(integration)}
                      disabled={deleting[integration.get('id')]}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              )
            })}
            <li className="item">
              <img src="/icons/plus-96.svg" className="logo plus" />
              <NavLink
                activeClassName="active"
                className="button button-small create-button"
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/integrations/new`}
              >
                New Integration
              </NavLink>
            </li>
          </ul>

          {error && <div className="error">{error}</div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelIntegrations: state.hotelIntegrations
})

export default connect(mapStateToProps)(HotelIntegrations)
