/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2019
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_SUB_PROPERTIES,
  RECEIVE_GET_HOTEL_SUB_PROPERTY,
  RECEIVE_UPDATE_HOTEL_SUB_PROPERTY_LOGO
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, hotelId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_SUB_PROPERTIES:
      return new OrderedMap(
        data.hotel_properties.map(subProperty => [subProperty.id, fromJS(subProperty)])
      )

    case RECEIVE_GET_HOTEL_SUB_PROPERTY:
      return state.set(data.hotel_property.id, fromJS(data.hotel_property))

    case RECEIVE_UPDATE_HOTEL_SUB_PROPERTY_LOGO:
      const updated = state.get(hotelId).set('logo', data.logo)
      return state.set(hotelId, updated)

    default:
      return state
  }
}
