/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, November 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fromJS, is } from 'immutable'
import Textarea from 'react-textarea-autosize'
import TagsInput from 'react-tagsinput'
import _ from 'lodash'
import Select from 'react-select'
import alertify from 'alertifyjs'

import Loader from './Loader'
import { loadOptInSettings, updateOptInSettings, loadReplyTemplates } from '../actions/HotelActions'

class HotelOptInSettings extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      guestOptIn: props.guestOptIn || this.getDefaultObject()
    }
  }

  getDefaultObject = () => {
    return fromJS({
      opt_in_type: 'implicit',
      inbound_opt_in_message_type: 'custom_text',
      inbound_message: '',
      outbound_message: '',
      consent_denied_message: '',
      consent_ambiguous_message: '',
      consent_given_keywords: '',
      consent_denied_keywords: []
    })
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadOptInSettings(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )

    this.setState({ isLoading: true })
    dispatch(
      loadReplyTemplates(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      guestOptIn: nextProps.guestOptIn || this.getDefaultObject()
    })
  }

  handleMessageChange = ev => {
    this.setState({
      guestOptIn: this.state.guestOptIn.set(ev.target.name, ev.target.value)
    })
  }

  changeGuestOptInType = type => ev => {
    this.setState({
      guestOptIn: this.state.guestOptIn.set('opt_in_type', type)
    })
  }

  changeMessageOptInType = type => ev => {
    const { guestOptIn, selectedTemplate } = this.state

    const operations = {
      custom_text: () =>
        guestOptIn.set('whats_app_templates_id', '').set('opt_in_message_type', type),
      template: () => guestOptIn.set('inbound_message', '').set('opt_in_message_type', type)
    }

    const updatedGuestOptIn = operations[type] ? operations[type]() : guestOptIn

    this.setState({
      guestOptIn: updatedGuestOptIn,
      selectedTemplate: type === 'template' ? selectedTemplate : ''
    })
  }

  handleMultiWordInputChange = (path, values) => {
    const keywords = _.without(
      _.uniq(_.map(values, val => val.trim().replace(/[`~@#$%^&*()_|+\-='"<>\{\}\[\]\\\/]/gi, ''))),
      '',
      undefined
    )

    this.setState({
      guestOptIn: this.state.guestOptIn.set(path, fromJS(keywords))
    })
  }

  saveOptInSettings = ev => {
    ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const { guestOptIn } = this.state

    const guestOptInParams = guestOptIn.set('hotel_id', selectedHotelId)

    this.setState({ saving: true, error: null })
    dispatch(
      updateOptInSettings(selectedHotelId, guestOptInParams, (statusCode, body) => {
        this.setState({ saving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          this.setState({ error: body && fromJS(body.error) })
          return alertify.error(`Error updating Opt-in.`)
        }

        alertify.success('Opt-in successfully updated.')
      })
    )
  }

  handleReset = ev => {
    this.setState({
      guestOptIn: this.props.guestOptIn || this.getDefaultObject()
    })
  }

  isPristine = () => {
    const { guestOptIn, saving } = this.state

    const consentDeniedKeywordsCount = guestOptIn.get('consent_denied_keywords')
      ? guestOptIn.get('consent_denied_keywords').size
      : 0

    const isTemplateMessageOptInType = guestOptIn.get('opt_in_message_type') === 'template'
    const isCustomTextMessageOptInType = guestOptIn.get('opt_in_message_type') === 'custom_text'

    return (
      !guestOptIn.get('opt_in_type') ||
      (isCustomTextMessageOptInType && !guestOptIn.get('inbound_message')) ||
      !guestOptIn.get('consent_denied_message') ||
      !guestOptIn.get('consent_ambiguous_message') ||
      !guestOptIn.get('consent_given_keywords') ||
      !(consentDeniedKeywordsCount > 0) ||
      is(guestOptIn, this.props.guestOptIn) ||
      (isTemplateMessageOptInType && !guestOptIn.get('whats_app_templates_id')) ||
      saving
    )
  }

  onSelectTemplate = option => {
    this.setState({
      guestOptIn: this.state.guestOptIn.set('whats_app_templates_id', option.value)
    })
  }

  render() {
    const { guestOptIn, isLoading, saving, error } = this.state

    const { replyTemplates } = this.props

    const guestOptInType = guestOptIn.get('opt_in_type')
    const messageOptInType = guestOptIn.get('opt_in_message_type')
    const consentGrantedKeywords = guestOptIn.get('consent_given_keywords')
      ? guestOptIn.get('consent_given_keywords').toJS()
      : []
    const consentDeniedKeywords = guestOptIn.get('consent_denied_keywords')
      ? guestOptIn.get('consent_denied_keywords').toJS()
      : []

    const replyTemplatesArray = Array.from(replyTemplates.values())
    const templatesOptions = replyTemplatesArray
      .filter(template => template.get('consent_template'))
      .map(template => ({
        label: template.get('name'),
        value: template.get('id')
      }))

    const selectedTemplate =
      guestOptIn && guestOptIn.get('whats_app_templates_id')
        ? templatesOptions.find(
            template => template.value === guestOptIn.get('whats_app_templates_id')
          )
        : ''

    return (
      <div className="settings-container display-flex-column">
        <Loader isLoading={isLoading} />

        <h2 className="tmargin0em">Guest Privacy and Opt-In Message Configuration </h2>

        <div className="opt-in-wrapper">
          <fieldset>
            <legend>Opt-In Consent Type</legend>
            <div className="opt-in-type">
              <h4 className={`${guestOptInType !== 'implicit' ? 'opacity5' : ''}`}>
                <label className="input-wrapper">
                  <input
                    type="radio"
                    checked={guestOptInType === 'implicit'}
                    onChange={this.changeGuestOptInType('implicit')}
                  />
                  Implicit
                </label>
              </h4>
              <h4 className={`${guestOptInType !== 'explicit' ? 'opacity5' : ''}`}>
                <div>
                  <label className="input-wrapper">
                    <input
                      type="radio"
                      checked={guestOptInType === 'explicit'}
                      onChange={this.changeGuestOptInType('explicit')}
                    />
                    <strong>Explicit</strong>
                  </label>
                </div>
              </h4>
            </div>
          </fieldset>

          <fieldset>
            <legend>Inbound Opt-in Message</legend>
            <div className="opt-in-message-type">
              <h4 className={`${messageOptInType !== 'custom_text' ? 'opacity5' : ''}`}>
                <label className="input-wrapper">
                  <input
                    type="radio"
                    checked={messageOptInType === 'custom_text'}
                    onChange={this.changeMessageOptInType('custom_text')}
                  />
                  Custom text
                </label>
              </h4>
              <h4 className={`${messageOptInType !== 'template' ? 'opacity5' : ''}`}>
                <div>
                  <label className="input-wrapper">
                    <input
                      type="radio"
                      checked={messageOptInType === 'template'}
                      onChange={this.changeMessageOptInType('template')}
                    />
                    <strong>Template</strong>
                  </label>
                </div>
              </h4>
            </div>
          </fieldset>

          {messageOptInType === 'custom_text' && (
            <fieldset>
              <Textarea
                maxRows={8}
                value={guestOptIn && guestOptIn.get('inbound_message')}
                name="inbound_message"
                className="w-80pct"
                placeholder={'Enter message here...'}
                onChange={this.handleMessageChange}
              />
              <small>
                <legend>Message to be sent when the guest sends the first message.</legend>
              </small>
            </fieldset>
          )}

          {messageOptInType === 'template' && (
            <div className="w-80pct">
              <fieldset>
                <Select
                  name="opt-in-message-template"
                  options={templatesOptions}
                  value={selectedTemplate}
                  onChange={this.onSelectTemplate}
                  placeholder={'Select WhatsApp template...'}
                />
              </fieldset>
            </div>
          )}
          <fieldset>
            <legend>Outbound Opt-In Message [optional]</legend>
            <Textarea
              maxRows={8}
              value={guestOptIn.get('outbound_message')}
              name="outbound_message"
              className="w-80pct"
              placeholder={'Enter message here...'}
              onChange={this.handleMessageChange}
            />
            <small>
              <legend>Message to be sent when the hotel sends the first message.</legend>
            </small>
          </fieldset>

          <fieldset>
            <legend>Consent Denied Message </legend>
            <Textarea
              maxRows={8}
              value={guestOptIn.get('consent_denied_message') || ''}
              name="consent_denied_message"
              className="w-80pct"
              placeholder={'Enter message here...'}
              onChange={this.handleMessageChange}
            />
            <small>
              <legend>Message to be sent when guest denies the consent.</legend>
            </small>
          </fieldset>

          <fieldset>
            <legend>Ambiguous Message </legend>
            <Textarea
              maxRows={8}
              value={(guestOptIn && guestOptIn.get('consent_ambiguous_message')) || ''}
              name="consent_ambiguous_message"
              className="w-80pct"
              placeholder={'Enter message here...'}
              onChange={this.handleMessageChange}
            />
            <small>
              <legend>
                Message to be sent when the guest’s response to the opt-in message is ambiguous.
              </legend>
            </small>
          </fieldset>

          <fieldset className="w-80pct">
            <legend>Consent keywords </legend>
            <TagsInput
              value={consentGrantedKeywords}
              onChange={values => this.handleMultiWordInputChange('consent_given_keywords', values)}
              inputProps={{ placeholder: 'Press enter to add keywords...' }}
              addOnBlur={true}
            />
            <small>
              <legend>
                The list of words to identify a guest as a consenting guest. Eg. "Yes", "Ok" etc.
              </legend>
            </small>
          </fieldset>

          <fieldset className="w-80pct">
            <legend>No-consent keywords </legend>
            <TagsInput
              value={consentDeniedKeywords}
              onChange={values =>
                this.handleMultiWordInputChange('consent_denied_keywords', values)
              }
              inputProps={{ placeholder: 'Press enter to add keywords...' }}
              addOnBlur={true}
            />
            <small>
              <legend>
                The list of words to identify a guest as a non-consenting guest. Eg. "No", "Nope"
                etc.
              </legend>
            </small>
          </fieldset>

          {error && (
            <fieldset>
              {error.entrySeq().map(([key, value]) => (
                <div key={key} className="error">
                  <span className="text-capitalize">{_.replace(key, /_/g, ' ')} </span>
                  <span>{value}.</span>
                </div>
              ))}
            </fieldset>
          )}

          <div className="actions">
            <button
              type="submit"
              className="button"
              disabled={this.isPristine()}
              onClick={this.saveOptInSettings}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
            <button type="cancel" className="button" onClick={this.handleReset}>
              Reset
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  guestOptIn: state.hotelSettings.get('guest_opt_in'),
  replyTemplates: state.replyTemplates
})

export default connect(mapStateToProps)(HotelOptInSettings)
