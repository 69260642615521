/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'
import HotelUtils from '../utils/HotelUtils'

const {
  RECEIVE_GET_HOTEL_PLATFORMS,
  RECEIVE_GET_HOTEL_PLATFORM,
  RECEIVE_DELETE_HOTEL_PLATFORM,
  RECEIVE_UPLOAD_HOTEL_PLATFORM_QR_CODE,
  RECEIVE_POST_WEBHOOK
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, platformId, platformKey } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_PLATFORMS:
      const formattedData = []
      fromJS(data).forEach((platforms, platformKey) => {
        if (platformKey === 'sms' || platformKey === 'email') {
          platforms.entrySeq().forEach(([pKey, pValues]) => {
            pValues.forEach(platform => {
              formattedData.push(platform.merge(HotelUtils.platformUiObject(pKey)).toJS())
            })
          })
        } else {
          platforms.forEach(platform => {
            formattedData.push(platform.merge(HotelUtils.platformUiObject(platformKey)).toJS())
          })
        }
      })
      return new OrderedMap(formattedData.map(platform => [platform.id, fromJS(platform)]))

    case RECEIVE_GET_HOTEL_PLATFORM:
      const platform = data[platformKey]
      return state.set(
        platform.id,
        fromJS(platform).merge(HotelUtils.platformUiObject(platformKey))
      )

    case RECEIVE_UPLOAD_HOTEL_PLATFORM_QR_CODE:
      const platformObj = state.get(platformId)
      return state.set(platformId, platformObj.set('qr_code_image', data.qr_code_image))

    case RECEIVE_DELETE_HOTEL_PLATFORM:
      return state.remove(platformId)

    case RECEIVE_POST_WEBHOOK:
      return state

    default:
      return state
  }
}
