/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import alertify from 'alertifyjs'

import Loader from './Loader'
import {
  loadIntegratedPlatforms,
  deletePlatform,
  updatePlatformsOrderInBulk,
  getWebhook,
  deleteWebhook
} from '../actions/HotelPlatformActions'
import RegExpUtils from '../utils/RegExpUtils'

class HotelPlatforms extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      deleting: {},
      query: ''
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadIntegratedPlatforms(selectedHotelId, () => {
        this.setState({ isLoading: false })
      })
    )
  }

  handleDeletePlatform = platform => ev => {
    ev.preventDefault()
    const { dispatch } = this.props
    const { deleting } = this.state

    const confirmMsg = `Are you sure you want to delete this platform?`
    alertify.confirm(confirmMsg).set({
      onok: () => {
        const platformId = platform.get('id')
        const platformKey = platform.get('apiUrlKey')
        const deleteUrl = `/v1/admin/platforms/${platformKey}/${platformId}`

        this.setState({
          deleting: { ...deleting, [platformId]: true },
          error: null
        })

        if (platformKey === 'message_bird' || platformKey === 'instagram') {
          this.findMessageBirdWebhook(platform)
        } else {
          dispatch(
            deletePlatform(deleteUrl, platformId, (statusCode, body) => {
              this.setState({ deleting: { ...deleting, [platformId]: false } })
              if (statusCode !== 204) {
                return this.setState({
                  error: (body && body.error) || `Error deleting platform.`
                })
              }
              this.updatePlatformsOrderAfterDelete(platform)

              alertify.success(`Platform successfuly deleted.`)
            })
          )
        }
      }
    })
  }

  handleSearch = ev => {
    this.setState({ query: ev.target.value })
  }

  updatePlatformsOrderAfterDelete = platform => {
    const { hotelPlatforms, dispatch, match } = this.props
    const { selectedHotelId } = match && match.params
    const platformsToUpdate = []
    const url = `/v1/admin/hotels/${selectedHotelId}/platforms`
    hotelPlatforms.filter(obj => {
      if (parseInt(obj.get('order')) > parseInt(platform.get('order'))) {
        platformsToUpdate.push({
          id: obj.get('id'),
          apiUrlKey: obj.get('apiUrlKey'),
          order: parseInt(obj.get('order')) - 1
        })
      }
    })
    dispatch(updatePlatformsOrderInBulk(url, platformsToUpdate, (statusCode, body) => {}))
  }

  findMessageBirdWebhook = platform => {
    const { dispatch } = this.props
    const selectedHotelId = platform.get('hotel_id')
    const webhookUrl = `/v1/admin/hotels/${selectedHotelId}/platforms/webhook`

    const params = {
      platform: platform.get('uiKey'),
      platformId: platform.get('id')
    }

    dispatch(
      getWebhook(webhookUrl, params, (statusCode, body) => {
        if (statusCode !== 200 && statusCode !== 201) {
          return this.setState({
            error: (body && body.error) || `Error finding webhook.`
          })
        }

        this.deleteMessageBirdPlatformAndWebhook(
          body,
          params,
          webhookUrl,
          selectedHotelId,
          platform
        )
      })
    )
  }

  deleteMessageBirdPlatformAndWebhook = (body, params, webhookUrl, selectedHotelId, platform) => {
    const { dispatch } = this.props
    const { deleting } = this.state
    const platformId = platform.get('id')
    const platformKey = platform.get('apiUrlKey')
    const deleteUrl = `/v1/admin/platforms/${platformKey}/${platformId}`
    const channelId = platform.get('channel_id')
    const hotelWebhook =
      body && body.items
        ? body.items.find(item => item.url.includes(selectedHotelId) && item.channelId == channelId)
        : ''

    if (hotelWebhook && Object.keys(hotelWebhook).length > 0) {
      params.webhookId = hotelWebhook.id
      dispatch(
        deleteWebhook(webhookUrl, params, (statusCode, body) => {
          if (statusCode !== 200 && statusCode !== 201) {
            return this.setState({
              error: (body && body.error) || `Error deleting webhook.`
            })
          }
        })
      )
    }

    dispatch(
      deletePlatform(deleteUrl, platformId, (statusCode, body) => {
        this.setState({ deleting: { ...deleting, [platformId]: false } })
        if (statusCode !== 204) {
          return this.setState({
            error: (body && body.error) || `Error deleting platform.`
          })
        }
        this.updatePlatformsOrderAfterDelete(platform)

        alertify.success(`Platform successfuly deleted.`)
      })
    )
  }

  render() {
    const { match, hotelPlatforms } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { isLoading, deleting, error, query } = this.state
    const exp = new RegExp(RegExpUtils.filter(query), 'i')
    const filteredPlatforms = hotelPlatforms.filter(
      platform => !query || (platform.get('uiName') || '').search(exp) !== -1
    )
    const sortedArray = filteredPlatforms.sortBy(f => parseInt(f.get('order')))
    return (
      <div className="settings-container display-flex-column">
        <Loader isLoading={isLoading} />

        <h2 className="tmargin0em">Messaging Platforms </h2>

        <div className="platforms-container">
          <div>
            <input
              className="settings-search-input"
              type="search"
              onChange={this.handleSearch}
              value={query}
              placeholder="Search platform"
            />
          </div>

          <ul className="platform-list">
            {sortedArray.toList().map((platform, i) => (
              <li key={platform.get('uiKey') || i} className="item">
                <div className="platform-name">
                  <span>{platform.get('uiName')}</span>
                  {
                    // <span className='tooltip tile-tooltip'>
                    //   <img src='/icons/info-icon.svg' className="info" />
                    //   <span className='tooltiptext tooltip-top'>
                    //     <div>Average metric value across selected date range.</div>
                    //   </span>
                    // </span>
                  }
                </div>
                <img src={platform.get('logoUrl')} className={`logo ${platform.get('uiKey')}`} />
                <div className="actions">
                  <NavLink
                    activeClassName="active"
                    className="button button-small link"
                    to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/platforms/${platform.get(
                      'id'
                    )}`}
                  >
                    Edit
                  </NavLink>
                  <button
                    className="button button-small button-delete"
                    onClick={this.handleDeletePlatform(platform)}
                    disabled={deleting[platform.get('id')]}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}

            <li className="item">
              <div className="platform-name" />
              <img src="/icons/plus-96.svg" className="logo plus" />
              <NavLink
                activeClassName="active"
                className="button button-small create-button"
                to={`/account/${selectedAccountId}/hotel/${selectedHotelId}/platforms/new`}
              >
                Add Platform
              </NavLink>
            </li>
          </ul>
          {error ? <div className="error">{error}</div> : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelPlatforms: state.hotelPlatforms
})

export default connect(mapStateToProps)(HotelPlatforms)
