/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, November 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fromJS, OrderedMap } from 'immutable'
import Modal from 'react-modal'
import alertify from 'alertifyjs'
import Loader from './Loader'
import Spinner from './Spinner'
import ModalStyle from '../constants/ModalStyle'
import CreateTag from './CreateTag'
import EditTag from './EditTag'
import CustomTagColors from '../constants/CustomTagColors'

import {
  loadHotelTags,
  createPropertyTag,
  updatePropertyTag,
  deletePropertyTag
} from '../actions/HotelActions'

class Tags extends React.Component {
  static propTypes = {
    tags: PropTypes.instanceOf(OrderedMap),
    hotelId: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      showCreateForm: false,
      isDeleting: false,
      isModalOpen: false,
      action: '',
      verbiage: '',
      newTag: '',
      newTagColor: CustomTagColors[0],
      showColorPicker: false
    }
  }

  componentDidMount = () => {
    const { dispatch, match } = this.props
    const { selectedAccountId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelTags(
        selectedAccountId,
        () => {
          this.setState({ isLoading: false })
        },
        true
      )
    )
  }

  handleCreateTagClick = () => {
    this.setState({
      showCreateForm: true,
      editingTag: null
    })
  }

  handleCreateTag = () => {
    const { dispatch, match } = this.props
    const { newTag, newTagColor } = this.state
    const { selectedAccountId } = match && match.params

    if (!newTag || !newTagColor) return

    this.setState({ isCreating: true })
    const tag = fromJS({ label: newTag, color: newTagColor })
    dispatch(
      createPropertyTag(selectedAccountId, tag, (statusCode, body) => {
        this.setState({ isCreating: false })
        if (statusCode === 201) {
          this.setState({
            showCreateForm: false,
            newTagColor: CustomTagColors[0],
            newTag: ''
          })
          alertify.success('Tag created successfully')
          this.handleRefreshData()
        } else {
          return alertify.error(body && body.error)
        }
      })
    )
  }

  handleTagChange = e => this.setState({ newTag: e.target.value })

  handleCancelEditing = () => {
    this.setState({
      showCreateForm: false,
      newTag: ''
    })
  }

  handleSelectTagColor = colorCode => {
    this.setState({
      newTagColor: colorCode,
      showColorPicker: false
    })
  }

  onRequestClose = () => {
    this.setState({ isModalOpen: false })
  }

  onModalCancel = () => {
    this.setState({
      isModalOpen: false,
      editingTag: null
    })
  }

  deleteTag = tag => {
    const verbiage = (
      <span>
        Are you sure you want to delete the tag {tag.get('name')}? This would remove this tag from
        all the respective guests.
      </span>
    )
    this.setState({
      tagToDelete: tag,
      isModalOpen: true,
      verbiage: verbiage,
      action: 'Delete Tag'
    })
  }

  updateTag = (tagId, oldTagName, newTagName, newTagColor) => {
    const verbiage = (
      <span>
        Are you sure you want to change the name of {oldTagName} tag to {newTagName}? This would
        modify the tag name on all the guests with tag {oldTagName}
      </span>
    )
    const tagToUpdate = {
      id: tagId,
      name: newTagName,
      color: newTagColor
    }
    this.setState({
      tagToUpdate: fromJS(tagToUpdate),
      isModalOpen: true,
      isDeleting: false,
      verbiage: verbiage,
      action: 'Update Tag',
      isBrandLevelTagsVisible: true,
      isSystemTagsVisible: true,
      isCustomTagsVisible: true
    })
  }

  onUpdateClick = () => {
    const { dispatch, match } = this.props
    const { tagToUpdate } = this.state
    const { selectedAccountId } = match && match.params

    this.setState({ isUpdating: true })
    dispatch(
      updatePropertyTag(selectedAccountId, tagToUpdate, (statusCode, body) => {
        this.setState({ isUpdating: false, isModalOpen: false })
        if (statusCode !== 200) {
          return alertify.error(body && body.error)
        }
        alertify.success('Tag updated successfully')
        this.setState({ editingTag: null })
        this.handleRefreshData()
      })
    )
  }

  onDeleteClick = () => {
    const { dispatch, match } = this.props
    const { tagToDelete } = this.state
    const { selectedAccountId } = match && match.params

    this.setState({ isDeleting: true })
    dispatch(
      deletePropertyTag(selectedAccountId, tagToDelete.get('id'), (statusCode, body) => {
        this.setState({ isDeleting: false, isModalOpen: false })
        if (statusCode !== 204) {
          return alertify.error(body && body.error)
        }
        if (statusCode !== 422) {
          alertify.error('Tag deleted successfully')
        }
        this.setState({ editingTag: null })
        this.handleRefreshData()
      })
    )
  }

  handleEditClick = tagId => {
    this.setState({
      editingTag: tagId,
      newTag: '',
      newTagColor: CustomTagColors[0],
      showCreateForm: false
    })
  }

  handleUpdateCancelClick = () => this.setState({ editingTag: null })

  handleRefreshData = () => {
    const { dispatch, match } = this.props
    const { selectedAccountId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadHotelTags(
        selectedAccountId,
        () => {
          this.setState({ isLoading: false })
        },
        true
      )
    )
  }

  render() {
    const {
      isLoading,
      editingTag,
      isCreating,
      newTag,
      showCreateForm,
      newTagColor,
      isModalOpen,
      isUpdating,
      isDeleting,
      verbiage,
      action
    } = this.state

    const { hotelTags } = this.props

    const brandLevelTags = hotelTags
      .filter(tag => tag.get('tag_type') === 'brand')
      .sort((tag1, tag2) => tag1.get('name').localeCompare(tag2.get('name')))

    return (
      <div
        className="settings-container brand-tags-settings display-flex-column"
        onScroll={this.handleScroll}
      >
        <Loader isLoading={isLoading} />
        <div ref={node => (this.hotelContentWraper = node)}>
          <div className="header-items">
            <h1>
              Tags <span className="entity-count">({brandLevelTags.size})</span>
            </h1>
          </div>

          <div className="brand-tags">
            <div className="tag-container">
              {brandLevelTags.toArray().map((tag, index) => (
                <CreateTag
                  key={index}
                  tag={tag}
                  editingTag={editingTag}
                  deleteTag={this.deleteTag}
                  updateTag={this.updateTag}
                  handleEditClick={this.handleEditClick}
                  handleUpdateCancelClick={this.handleUpdateCancelClick}
                />
              ))}
              {showCreateForm ? (
                <div className="edit-container" style={{ marginLeft: '0em' }}>
                  <EditTag
                    action={'creation'}
                    isCreating={isCreating}
                    tagName={newTag}
                    tagColor={newTagColor}
                    handleTagChange={this.handleTagChange}
                    cancelEditing={this.handleCancelEditing}
                    createTag={this.handleCreateTag}
                    changeTagColor={this.handleSelectTagColor}
                  />
                </div>
              ) : (
                <div className="create-tag" onClick={this.handleCreateTagClick}>
                  <img src="/icons/plus-grey.svg" />
                  <span>Create new tag</span>
                </div>
              )}
            </div>
            <TagModal
              isOpen={isModalOpen}
              action={action}
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              verbiage={verbiage}
              onRequestClose={this.onRequestClose}
              onCancel={this.onModalCancel}
              updateTag={this.onUpdateClick}
              deleteTag={this.onDeleteClick}
            />
          </div>
          <div className="spinner__wrapper">
            {isLoading ? (
              <div className="messages__spinner list-style-none">
                <Spinner />
              </div>
            ) : (
              <div className="filler"> </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class TagModal extends React.Component {
  render() {
    const {
      isOpen,
      isUpdating,
      isDeleting,
      action,
      onRequestClose,
      updateTag,
      onCancel,
      deleteTag,
      verbiage
    } = this.props
    return (
      <Modal isOpen={isOpen} style={ModalStyle} ariaHideApp={false} className="tag-modal">
        <div className="modal-popup">
          <header className="modal-popup-header">
            <h3>{action}</h3>
            <button onClick={onRequestClose} className="close">
              <img src="/icons/ic_close_black_24px.svg" className="icon" />
            </button>
          </header>
          <div className="modal-popup-body">
            <div className="row-layout">{verbiage}</div>

            <div className="actions tmargin1em">
              {action === 'Update Tag' ? (
                <button className="button" type="button" onClick={updateTag} disabled={isUpdating}>
                  {isUpdating ? 'Updating...' : 'Update'}
                </button>
              ) : (
                <button className="button button-delete" onClick={deleteTag} disabled={isDeleting}>
                  {isDeleting ? 'Deleting...' : 'Delete'}
                </button>
              )}
              <button
                type="cancel"
                className="button"
                onClick={onCancel}
                disabled={isUpdating || isDeleting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  hotelTags: state.hotelTags
})

export default connect(mapStateToProps)(Tags)
