/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, May 2018
 */

import { List, fromJS } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_HOTEL_TIMEZONES } = ActionTypes

const initialState = new List()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_TIMEZONES:
      return new fromJS(data.timezones)

    default:
      return state
  }
}
