import React from 'react'
import PropTypes from 'prop-types'
import { is } from 'immutable'
import alertify from 'alertifyjs'

import Loader from './Loader'
import { createHotelWidget, loadHotelWidgets, updateHotelWidget } from '../actions/HotelActions'
import { connect } from 'react-redux'

class EditWebWidget extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      selectedAccountId: PropTypes.string,
      selectedHotelId: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      webWidget: this.getWidgetProps(props),
      isWhitelisted: this.isWhitelisted(props)
    }
  }

  componentDidMount() {
    const { dispatch, match, webWidgets } = this.props
    const { widgetId, selectedHotelId } = match && match.params
    if (widgetId && webWidgets.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadHotelWidgets(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    const { widgetId } = match && match.params
    if (widgetId && !is(prevProps.webWidgets, this.props.webWidgets)) {
      this.setState({
        webWidget: this.getWidgetProps(this.props),
        isWhitelisted: this.isWhitelisted(this.props)
      })
    }
  }

  getWidgetProps = props => {
    const { match, webWidget, webWidgets } = props
    const { widgetId } = match && match.params
    return widgetId ? webWidgets.get(widgetId) : webWidget
  }

  isWhitelisted = props => {
    const { match, webWidgets } = props
    const { widgetId } = match && match.params
    return widgetId ? webWidgets.getIn([widgetId, 'is_whitelisted']) : false
  }

  isPristine = () => {
    const { webWidget, isSaving, isWhitelisted } = this.state
    const { match, webWidgets } = this.props
    const { widgetId } = match && match.params

    if (widgetId) {
      const updatedWidget = webWidget.set('is_whitelisted', isWhitelisted)
      if (is(updatedWidget, webWidgets.get(widgetId))) {
        return true
      }
    }

    return (
      !webWidget.get('domain_url') ||
      !webWidget.get('host') ||
      !webWidget.get('welcome_message') ||
      !webWidget.get('whats_app_template_id') ||
      isSaving
    )
  }

  handleSubmit = ev => {
    ev && ev.preventDefault()

    const { dispatch, match } = this.props
    const { selectedHotelId, widgetId } = match && match.params
    const { webWidget, isWhitelisted } = this.state

    this.setState({ isSaving: true, error: null })

    if (widgetId) {
      const widget = {
        active: webWidget.get('active'),
        is_whitelisted: isWhitelisted,
        domain_url: webWidget.get('domain_url'),
        host: webWidget.get('host'),
        welcome_message: webWidget.get('welcome_message')
      }
      this.updateWidget(widgetId, widget)
    } else {
      dispatch(
        createHotelWidget(selectedHotelId, webWidget.toJS(), (statusCode, body) => {
          this.setState({ isSaving: false })
          if (statusCode !== 201) {
            return alertify.error((body && body.error) || 'Error creating web widget.')
          }

          alertify.success('Web widget successfully created.')
          this.handleCancel()
        })
      )
    }
  }

  updateWidget = (widgetId, widget) => {
    const { dispatch } = this.props

    dispatch(
      updateHotelWidget(widgetId, widget, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200) {
          return alertify.error((body && body.error) || 'Error updating web widget.')
        }

        alertify.success('Web widget successfully updated.')
        this.handleCancel()
      })
    )
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({ settings: this.state.settingsProps })
  }

  handleChange = ev => {
    this.setState({
      webWidget: this.state.webWidget.set(ev.target.name, ev.target.value)
    })
  }

  handleCheckBoxClick = () => {
    this.setState({ isWhitelisted: !this.state.isWhitelisted })
  }

  handleCancel = () => {
    const { history, match } = this.props
    const { selectedHotelId, selectedAccountId } = match && match.params
    history.push(`/account/${selectedAccountId}/hotel/${selectedHotelId}/web-widget`)
  }

  render() {
    const { webWidget, isLoading, isSaving, isWhitelisted } = this.state
    const { match, webWidgets } = this.props
    const { widgetId } = match && match.params

    if ((widgetId && webWidgets.isEmpty()) || isLoading) {
      return (
        <div className="settings-container">
          <Loader isLoading={isLoading} />
        </div>
      )
    }

    return (
      <div className="settings-container web-widget-settings">
        <Loader isLoading={isLoading} />
        <div className="header-items">
          <h1>Widget Setup</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <fieldset>
            <div>
              <input
                type="text"
                name="domain_url"
                value={webWidget.get('domain_url')}
                onChange={this.handleChange}
                placeholder="Domain URL"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="host"
                value={webWidget.get('host')}
                onChange={this.handleChange}
                placeholder="Host"
                required
              />
            </div>
            <div>
              <textarea
                placeholder="Enter welcome message here"
                name="welcome_message"
                onChange={this.handleChange}
                value={webWidget.get('welcome_message')}
                required
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter whatsapp template id"
                name="whats_app_template_id"
                onChange={this.handleChange}
                value={webWidget.get('whats_app_template_id')}
                required
              />
            </div>
            {widgetId && (
              <div>
                <fieldset className="form-item">
                  <div>
                    <div
                      className={`select-field ${isWhitelisted ? 'selected' : ''}`}
                      onClick={this.handleCheckBoxClick}
                    >
                      {isWhitelisted && <img src={`/icons/select-tick.svg`} alt="" />}
                    </div>
                    <div>Whitelist domain</div>
                  </div>
                </fieldset>
              </div>
            )}
          </fieldset>

          <button disabled={this.isPristine()} type="submit" className="button">
            {widgetId ? (isSaving ? 'Updating...' : 'Update') : isSaving ? 'Creating...' : 'Create'}
          </button>
          <button type="cancel" className="button" onClick={this.handleCancel}>
            Cancel
          </button>
        </form>

        {widgetId && (
          <div className="widget-script">
            <label>Widget Script:</label>
            <small className="info">
              <legend className="bmargin1em">
                Copy the following script and paste it into the website's HTML source code after the
                closing <code className="text-lowercase">&lt;/body&gt;</code> tag.
              </legend>
            </small>
            <div className="code-wrapper">
              <code>{webWidget.get('script')}</code>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  webWidget: state.webWidget,
  webWidgets: state.webWidgets
})

export default connect(mapStateToProps)(EditWebWidget)
