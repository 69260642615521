import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_HOTEL_NPS_DETAILS, RECEIVE_CREATE_HOTEL_NPS_DETAILS } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, templateId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_NPS_DETAILS:
      return new OrderedMap(data)

    case RECEIVE_CREATE_HOTEL_NPS_DETAILS:
      return state.set(data.hotel_widget.id, fromJS(data.hotel_widget))

    default:
      return state
  }
}
