/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { Switch } from 'react-router-dom'

import Login from '../components/Login'
import SetUp2FA from '../components/SetUp2FA'
import Otp2FA from '../components/Otp2FA'
import ForgotPassword from '../components/ForgotPassword'
import Main from '../containers/Main'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/setup-2fa" component={SetUp2FA} />
        <PublicRoute path="/otp-2fa" component={Otp2FA} />
        <PublicRoute path="/forgot_password" component={ForgotPassword} />
        <PrivateRoute path="/" component={Main} />
      </Switch>
    )
  }
}

export default Router
