/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from './Loader'
import HotelPlatform from './HotelPlatform'
import HotelPlatformConstants from '../constants/HotelPlatformConstants'
import { loadIntegratedPlatforms } from '../actions/HotelPlatformActions'

class EditHotelPlatform extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      platforms: HotelPlatformConstants
    }
  }

  componentDidMount() {
    const { dispatch, match, hotelPlatforms } = this.props
    const { selectedHotelId, platformId } = match && match.params
    if (platformId && hotelPlatforms && hotelPlatforms.isEmpty()) {
      this.setState({ isLoading: true })
      dispatch(
        loadIntegratedPlatforms(selectedHotelId, () => {
          this.setState({ isLoading: false })
        })
      )
    }
  }

  render() {
    const { dispatch, match, hotelPlatforms } = this.props
    const { selectedAccountId, selectedHotelId, platformId } = match && match.params
    const { isLoading, platforms } = this.state
    const platform = hotelPlatforms.get(platformId)
    return (
      <div className="settings-container display-flex-column">
        <div className="edit-platform">
          {platform ? (
            <HotelPlatform
              dispatch={dispatch}
              selectedAccountId={selectedAccountId}
              selectedHotelId={selectedHotelId}
              platform={platform}
              hotelPlatforms={hotelPlatforms}
              platformsCount={hotelPlatforms.size}
              platforms={platforms}
            />
          ) : (
            <Loader isLoading={isLoading} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hotelPlatforms: state.hotelPlatforms
})

export default connect(mapStateToProps)(EditHotelPlatform)
