/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2020
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_GET_BUSINESS_TYPES, RECEIVE_GET_BUSINESS_TYPE } = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_BUSINESS_TYPES:
      const businessTypes = new OrderedMap(
        data.business_types.map(bType => [bType.id, fromJS(bType)])
      )
      return state.merge(businessTypes)

    case RECEIVE_GET_BUSINESS_TYPE:
      return state.set(data.business_type.id, fromJS(data.business_type))

    default:
      return state
  }
}
