/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

/* eslint no-useless-escape: "off" */
/* eslint no-mixed-operators: "off" */

import _ from 'lodash'

const ParserUtils = {
  parseEmails(raw) {
    return raw.split('\n').map(raw => {
      const rawSplit = raw && raw.split(',')

      const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      const emailMatch = rawSplit[0] && rawSplit[0].match(emailRegex)
      const email = emailMatch && emailMatch[0]

      const fullName = (rawSplit[1] && rawSplit[1].trim()) || ''
      const multiWord = fullName.indexOf(' ') !== -1
      const fName = multiWord
        ? fullName.substring(0, fullName.lastIndexOf(' '))
        : fullName
      const lName = multiWord
        ? fullName.substring(fullName.lastIndexOf(' ') + 1)
        : ''

      return {
        email: email,
        first_name: _.startCase(_.toLower(fName)),
        last_name: _.startCase(_.toLower(lName))
      }
    })
  }
}

export default ParserUtils
