import React from 'react'
import EditTag from './EditTag'

class CreateTag extends React.Component {
  constructor(props) {
    super(props)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.state = {
      isEditing: false,
      tagText: props.tag.get('name'),
      selectedColorCode: props.tag.get('color'),
      defaultTagColor: '#C02A69'
    }
  }

  componentWillReceiveProps = nextProps => {
    if (!nextProps.editingTag) {
      this.setState({
        isEditing: false,
        tagText: this.props.tag.get('name'),
        selectedColorCode: this.props.tag.get('color')
      })
    }
  }

  handleTagChange = e => {
    this.setState({ tagText: e.target.value })
  }

  deleteTag = () => {
    this.props.deleteTag(this.props.tag)
  }

  updateTag = () => {
    const { tag } = this.props
    const { tagText, selectedColorCode } = this.state
    this.props.updateTag(tag.get('id'), tag.get('name'), tagText, selectedColorCode)
  }

  changeTagColor = colorCode => {
    this.setState({
      selectedColorCode: colorCode
    })
  }

  cancelEditing = () => {
    this.setState({
      isEditing: false,
      tagText: this.props.tag.get('name')
    })
    this.props.handleUpdateCancelClick()
  }

  editTag = () => {
    this.setState({ isEditing: true })
    this.props.handleEditClick(this.props.tag.get('id'))
  }

  render() {
    const { tag, editingTag } = this.props
    const { isEditing, tagText, selectedColorCode } = this.state

    return (
      <div>
        {tag.get('id') === editingTag && isEditing ? (
          <div className="edit-container">
            <img src="/icons/delete-grey.svg" className="trash" onClick={this.deleteTag} />
            <EditTag
              tag={tag}
              tagName={tagText}
              tagColor={selectedColorCode}
              handleTagChange={this.handleTagChange}
              cancelEditing={this.cancelEditing}
              updateTag={this.updateTag}
              changeTagColor={this.changeTagColor}
            />
          </div>
        ) : (
          <div className="custom-tag">
            <div className="tag" style={{ backgroundColor: tag.get('color') }}>
              {tag.get('name')}
            </div>
            <img className="edit-tag" src="/icons/pencil-grey.svg" onClick={this.editTag} />
          </div>
        )}
      </div>
    )
  }
}

export default CreateTag
