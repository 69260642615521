/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, June 2018
 */

import { fromJS } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_FEATURE_FLAGS,
  RECEIVE_GET_HOTEL_FEATURE_FLAG_UPDATE,
  RECEIVE_GET_GUEST_OPT_IN_DETAILS,
  RECEIVE_GET_GUEST_OPT_IN_UPDATE
} = ActionTypes

const initialState = fromJS({
  featureFlags: {}
})

export default (state = initialState, action) => {
  const { type, data } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_FEATURE_FLAGS:
      return state.set('featureFlags', fromJS(data.feature_flags))

    case RECEIVE_GET_HOTEL_FEATURE_FLAG_UPDATE:
      const updatedFlags = state.get('featureFlags').merge(fromJS(data.feature_flags))
      return state.set('featureFlags', updatedFlags)

    case RECEIVE_GET_GUEST_OPT_IN_DETAILS:
    case RECEIVE_GET_GUEST_OPT_IN_UPDATE:
      return state.set('guest_opt_in', fromJS(data.guest_opt_in))

    default:
      return state
  }
}
