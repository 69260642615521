/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { Map } from 'immutable'

import UserRole from '../constants/UserRole'

const MeUtils = {
  isSuperAdmin(me) {
    if (!me) return false

    const meRole = (me && me.get('roles') && me.get('roles').first()) || new Map()
    return meRole.get('name') === UserRole.SUPER_ADMIN
  },
  is2faInactive(me) {
    if (me) {
      return !me.two_fa_setup_needed && !me.otp_required
    }
  },
  isSuccessfulLogin(me) {
    if (!me) return false
    return MeUtils.is2faInactive(me) || me.otp_verified
  }
}

export default MeUtils
