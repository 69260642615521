import React from 'react'
import PropTypes from 'prop-types'
import { is, fromJS, Map } from 'immutable'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import alertify from 'alertifyjs'
import _ from 'lodash'
import Textarea from 'react-textarea-autosize'
import { SketchPicker } from 'react-color'

import CreateNewBusinessType from './CreateNewBusinessType'
import { loadFeatureFlagsList } from '../actions/AccountActions'

import WeChatStepsLanguages from '../constants/WeChatStepsLanguages'
import BackgroundTypes from '../constants/BackgroundTypes'

import {
  saveHotel,
  loadHotelTimezones,
  updateLandingPageBackgroundImage
} from '../actions/HotelActions'

class EditLandingPage extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      hotel: props.hotel,
      showPicker: false,
      color: '#ffffff',
      isBackgroundColor: '',
      enableSaveButton: false
    }
  }

  componentDidMount() {
    const { dispatch, hotelTimezones, featureFlags } = this.props
    const savedLanguage = this.state.hotel.get('guests_landing_page_we_chat_steps_language')

    if (hotelTimezones.isEmpty()) {
      dispatch(loadHotelTimezones())
    }

    if (featureFlags.isEmpty()) {
      dispatch(loadFeatureFlagsList())
    }

    let selectedWeChatStepsLanguages = []
    if (savedLanguage === 'chinese_traditional') {
      selectedWeChatStepsLanguages = ['chinese_traditional']
    } else if (savedLanguage === 'english') {
      selectedWeChatStepsLanguages = ['english']
    } else if (savedLanguage === 'all') {
      selectedWeChatStepsLanguages = ['chinese_traditional', 'english']
    }

    this.setState({ selectedWeChatStepsLanguages })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      hotel: nextProps.hotel
    })
  }

  handleChange = ev => {
    this.setState({
      hotel: this.state.hotel.set(ev.target.name, ev.target.value),
      error: this.state.error ? this.state.error.delete(ev.target.name) : new Map()
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    const hotelBusinessType = hotel.get('business_type') || new Map()
    const updatedHotelJs = hotel.set('business_type_id', hotelBusinessType.get('id')).toJS()

    this.setState({ isSaving: true, error: null })
    dispatch(
      saveHotel(selectedAccountId, updatedHotelJs, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          this.setState({
            error: fromJS(body)
          })
          const topElement = document.getElementsByTagName('input')[0]
          topElement && topElement.scrollIntoView()
          return
        }

        alertify.success(`Landing page info successfully updated`)
        this.setState({ enableSaveButton: false })

        setTimeout(() => {
          const hotelId = body && body.hotel && body.hotel.id
          dispatch(push(`/account/${selectedAccountId}/hotel/${hotelId}/landing-page`))
        }, 100)
      })
    )
  }

  saveImageBackground = ev => {
    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    const hotelBusinessType = hotel.get('business_type') || new Map()
    const updatedHotelJs = hotel.set('business_type_id', hotelBusinessType.get('id')).toJS()

    dispatch(saveHotel(selectedAccountId, updatedHotelJs))

    this.setState({ enableSaveButton: true })
  }

  handleReset = ev => {
    ev.preventDefault()
    this.setState({ hotel: this.props.hotel })
  }

  isPristine = () => {
    const { isSaving, enableSaveButton } = this.state

    if (enableSaveButton) return false

    return is(this.state.hotel, this.props.hotel) || isSaving
  }

  closeCreateBusinessTypeModal = () => {
    this.setState({ showCreateBusinessTypeModal: false })
  }

  handleColorChange = newColor => {
    this.setState({
      hotel: this.state.hotel.set('guests_landing_page_background_color', newColor.hex)
    })
  }

  handleSwitchChange = (name, value) => {
    this.setState({
      hotel: this.state.hotel.set(name, value)
    })
  }

  handleChangeBackgroundType = ev => {
    const backgroundType = ev.target.value.toLowerCase()

    this.setState({
      hotel: this.state.hotel.set('guests_landing_page_background_type', backgroundType)
    })
  }

  handleChangeWeChatStepsLanguage = ev => {
    const selectedLanguage = ev.target.value.toLowerCase()
    let selectedWeChatStepsLanguages = [...(this.state.selectedWeChatStepsLanguages || [])]

    if (ev.target.checked) {
      selectedWeChatStepsLanguages.push(selectedLanguage)
    } else {
      selectedWeChatStepsLanguages = selectedWeChatStepsLanguages.filter(
        lang => lang !== selectedLanguage
      )
    }

    let finalLanguageValue
    if (selectedWeChatStepsLanguages.length === 1) {
      finalLanguageValue = selectedWeChatStepsLanguages[0]
    } else if (selectedWeChatStepsLanguages.length === 2) {
      finalLanguageValue = 'all'
    } else {
      finalLanguageValue = ''
    }

    this.setState({
      selectedWeChatStepsLanguages,
      hotel: this.state.hotel.set('guests_landing_page_we_chat_steps_language', finalLanguageValue)
    })
  }

  handleBackgroundImageUpload = ev => {
    ev.preventDefault()
    const { dispatch, selectedAccountId } = this.props
    const { hotel } = this.state

    this.saveImageBackground()

    const hotelId = hotel.get('id')
    const files = ev.target.files

    let formData = new FormData()
    files && formData.append('guests_landing_page_background_image', files[0])

    this.setState({ isLoadingBackgroundImage: true, errorBackgroundImageUpdate: null })
    dispatch(
      updateLandingPageBackgroundImage(selectedAccountId, hotelId, formData, (statusCode, body) => {
        this.setState({ isLoadingBackgroundImage: false })
        if (statusCode !== 200) {
          return this.setState({ errorBackgroundImageUpdate: fromJS(body) })
        }

        this.backgroundImageInput.value = ''
      })
    )
  }

  render() {
    const { featureFlags } = this.props
    const {
      hotel,
      error,
      showCreateBusinessTypeModal,
      showPicker,
      color,
      isLoadingBackgroundImage,
      errorBackgroundImageUpdate
    } = this.state

    const borderColor = color === '#ffffff' ? 'black' : 'transparent'

    const isBackgroundColor = hotel.get('guests_landing_page_background_type') === 'color'
    const isBackgroundImage = hotel.get('guests_landing_page_background_type') === 'image'

    const colorSquareStyle = {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      backgroundColor: hotel.get('guests_landing_page_background_color') || color,
      cursor: 'pointer',
      border: `2px solid ${borderColor}`
    }

    return (
      <form className="settings-container hotel-settings">
        <h2>Landing Page</h2>

        <fieldset className="w-50pct tmargin2em">
          <legend>Guests Landing page URL</legend>
          <input
            type="url"
            className="margin0"
            name="guests_landing_page_url"
            value={hotel.get('guests_landing_page_url') || ''}
            onChange={this.handleChange}
          />
        </fieldset>

        <fieldset>
          <legend>Guests landing page header</legend>
          <Textarea
            className="guests_landing_page_header_text"
            name="guests_landing_page_header_text"
            value={hotel.get('guests_landing_page_header_text') || ''}
            onChange={this.handleChange}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Guests landing page footer</legend>
          <Textarea
            className="guests_landing_page_footer_text"
            name="guests_landing_page_footer_text"
            value={hotel.get('guests_landing_page_footer_text') || ''}
            onChange={this.handleChange}
          />
        </fieldset>

        <fieldset id="background-types">
          <legend>Background type</legend>
          <div className="background-types-grid">
            {BackgroundTypes.map((backgroundType, i) => (
              <label key={backgroundType}>
                <input
                  type="radio"
                  name="guests_landing_page_background_type"
                  value={backgroundType}
                  onChange={this.handleChangeBackgroundType}
                  checked={
                    hotel.get('guests_landing_page_background_type') ===
                    backgroundType.toLowerCase()
                  }
                />
                {backgroundType}
              </label>
            ))}
          </div>
        </fieldset>

        {isBackgroundColor && (
          <fieldset>
            <legend>Background color</legend>
            <div
              onClick={() => this.setState({ showPicker: !showPicker })}
              style={colorSquareStyle}
            />
            {showPicker && (
              <div style={{ position: 'absolute', zIndex: '2' }}>
                <SketchPicker
                  color={hotel.get('guests_landing_page_background_color') || color}
                  onChange={this.handleColorChange}
                />
              </div>
            )}
          </fieldset>
        )}

        {isBackgroundImage && (
          <fieldset>
            <legend>Background image</legend>
            <div className="landing-page-background-image image">
              {isLoadingBackgroundImage ? (
                <img src="/icons/ring.gif" alt="" className="uploading-image" />
              ) : (
                <img src={hotel.get('guests_landing_page_background_image') || ''} alt="" />
              )}
            </div>
            <div className="landing-page-background-image-upload-wrapper">
              <label className="image image-input button button-secondary">
                <div className="photo-image__placeholder">
                  <strong>Upload new background image</strong>
                </div>
                <input
                  type="file"
                  id="landing-page-background-image"
                  name="landing-page-background-image"
                  accept=".png,.jpg,.jpeg,.gif"
                  ref={input => {
                    this.backgroundImageInput = input
                  }}
                  onChange={this.handleBackgroundImageUpload}
                />
              </label>

              {errorBackgroundImageUpdate && errorBackgroundImageUpdate.has('logo') && (
                <div className="error">Logo {errorBackgroundImageUpdate.get('logo')}</div>
              )}
              {errorBackgroundImageUpdate &&
                errorBackgroundImageUpdate.has('logo_content_type') && (
                  <div className="error">
                    Logo content type {errorBackgroundImageUpdate.get('logo_content_type')}
                  </div>
                )}
            </div>
          </fieldset>
        )}

        <fieldset>
          <legend>Logo height</legend>
          <Textarea
            rows={1}
            maxRows={1}
            name="guests_landing_page_logo_height"
            value={hotel.get('guests_landing_page_logo_height') || ''}
            onChange={this.handleChange}
            required
          />
        </fieldset>

        <fieldset>
          <legend>Logo width</legend>
          <Textarea
            rows={1}
            maxRows={1}
            name="guests_landing_page_logo_width"
            value={hotel.get('guests_landing_page_logo_width') || ''}
            onChange={this.handleChange}
            required
          />
        </fieldset>

        <fieldset id="we-chat-steps-languages">
          <legend>WeChat Steps Language</legend>
          <div className="we-chat-steps-languages-grid">
            {WeChatStepsLanguages.map((language, i) => (
              <label key={language}>
                <input
                  type="checkbox"
                  name="guests_landing_page_we_chat_steps_language"
                  value={language}
                  onChange={this.handleChangeWeChatStepsLanguage}
                  checked={
                    this.state.selectedWeChatStepsLanguages &&
                    this.state.selectedWeChatStepsLanguages.includes(language.toLowerCase())
                  }
                />
                {language}
              </label>
            ))}
          </div>
        </fieldset>

        {error && (
          <fieldset id="errors">
            {error.entrySeq().map(
              ([key, value]) =>
                key !== 'email' &&
                key !== 'user' && (
                  <div key={key} className="error">
                    <span className="text-capitalize">{_.replace(key, /_/g, ' ')} </span>
                    <span>{value}.</span>
                  </div>
                )
            )}
          </fieldset>
        )}

        <fieldset className="tmargin2em">
          <button
            disabled={this.isPristine()}
            onClick={this.handleSubmit}
            type="submit"
            className="button"
          >
            Save
          </button>
          <button type="cancel" className="button" onClick={this.handleReset}>
            Reset
          </button>
        </fieldset>

        {showCreateBusinessTypeModal && (
          <CreateNewBusinessType
            isOpen={showCreateBusinessTypeModal}
            onRequestClose={this.closeCreateBusinessTypeModal}
            featureFlags={featureFlags || new Map()}
          />
        )}
      </form>
    )
  }
}

const mapStateToProps = state => ({
  hotelTimezones: state.hotelTimezones,
  businessTypes: state.businessTypes,
  featureFlags: state.featureFlags
})

export default connect(mapStateToProps)(EditLandingPage)
