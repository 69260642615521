/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'

import CollapsibleList from './CollapsibleList'
import Spinner from './Spinner'
import { loadBusinessTypes } from '../actions/AccountActions'
import { loadAccountHotels } from '../actions/HotelActions'
import AccountUtils from '../utils/AccountUtils'

class HotelListItem extends React.Component {
  render() {
    const { accountId, hotel, selectedHotelId } = this.props

    const hotelId = hotel.get('id')

    return (
      <li className="list-item">
        <NavLink
          activeClassName="active"
          className={`link on-hover-bg-color ${selectedHotelId === hotelId ? 'active' : ''}`}
          to={`/account/${accountId}/hotel/${hotelId}`}
        >
          <div className="head">
            <div className="hotel-name">{hotel.get('name')}</div>
          </div>
        </NavLink>
      </li>
    )
  }
}

const formattedThreadsData = (accounts, hotels, selectedAccountId, selectedHotelId) => {
  return accounts.toList().map(account => {
    const orderedHotels =
      hotels &&
      hotels
        .filter(hotel => hotel.get('account_id') === account.get('id'))
        .sortBy(hotel => hotel.get('name'))

    const hotelsCount = orderedHotels.count()

    const header = (
      <div>
        <NavLink
          activeClassName="active"
          className={`link on-hover-bg-color`}
          to={`/account/${account.get('id')}`}
          onClick={e => e.stopPropagation()}
        >
          {account.get('name')}
        </NavLink>
        <span>{account.get('hotelsLoaded') && ` (${hotelsCount})`}</span>
      </div>
    )

    const content = account.get('loadingHotels') ? (
      <div className="spinner__wrapper list-style-none">
        <Spinner />
      </div>
    ) : hotelsCount ? (
      <ul key={account.get('name')}>
        {orderedHotels
          .map(hotel => {
            return (
              <HotelListItem
                key={hotel.get('id')}
                accountId={account.get('id')}
                selectedHotelId={selectedHotelId}
                hotel={hotel}
              />
            )
          })
          .toList()}
      </ul>
    ) : (
      <div className="placeholder">No hotels added to this account yet.</div>
    )

    // Check if account is selected in main configuration window
    const accountItemSelected = !selectedHotelId && account.get('id') === selectedAccountId

    return {
      itemId: account.get('id'),
      header: header,
      content: content,
      listItemSelected: accountItemSelected
    }
  })
}

class AccountsList extends React.Component {
  static propTypes = {
    selectedAccountId: PropTypes.string,
    selectedHotelId: PropTypes.string,
    location: PropTypes.object
  }

  componentDidMount() {
    const { dispatch, businessTypes } = this.props

    if (businessTypes.isEmpty()) {
      dispatch(loadBusinessTypes())
    }
  }

  handleListToggle = (itemId, status) => {
    const { dispatch, accounts } = this.props
    const account = accounts.get(itemId)

    // Fetch account's hotel data if not already fetched
    if (AccountUtils.shouldLoadAccHotels(account)) {
      dispatch(loadAccountHotels(itemId))
    }
  }

  render() {
    const { accounts, hotels, selectedAccountId, selectedHotelId } = this.props

    const orderedAccouts = accounts.sortBy(acccount => acccount.get('name'))

    const accountsListsData = formattedThreadsData(
      orderedAccouts,
      hotels,
      selectedAccountId,
      selectedHotelId
    )

    return (
      <nav key="side-nav" className="side-nav items-list">
        <div>
          <Link to={`/account/new`} className="button create-account pull-right">
            Create New Account
          </Link>
          <h4>Accounts</h4>
        </div>
        <div className="glowingio-accordion">
          <CollapsibleList data={accountsListsData} onListToggle={this.handleListToggle} />
          {accounts.size === 0 && <div className="padding0-5em">No account found.</div>}
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  hotels: state.hotels,
  businessTypes: state.businessTypes,
  location: state.router && state.router.location // Location needed to shoe active state the selected hotel-item in side-nav-bar
})

export default connect(mapStateToProps)(AccountsList)
