/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

class Nav extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func
  }

  render() {
    const { me } = this.props

    return (
      <nav className="main-nav">
        <ul>
          <li>
            <Link to={`/account`} className="hotel avatar-link">
              <img className="avatar" src="/glowing.png" alt="" />
              <div>
                <strong className="name">Glowing.io</strong>
                <small className="city">Admin Dashboard</small>
              </div>
            </Link>
          </li>
        </ul>

        <ul>
          <li>
            <NavLink
              activeClassName="active"
              to={`/account`}
              className="nav-link"
            >
              <img src={'/icons/settings-home.svg'} alt="" />
              <small>Home</small>
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to={`/settings`}
              className="nav-link"
            >
              <img src={'/icons/settings.svg'} alt="" />
              <small>Settings</small>
            </NavLink>
          </li>
          {me &&
            !me.isEmpty() && (
              <li>
                <NavLink
                  activeClassName="active"
                  to="/settings/account"
                  className="avatar-link lmargin1em"
                >
                  <img className="avatar" src={me.get('avatar')} alt="" />
                  <div style={{ marginTop: '0.4em' }}>
                    <strong className="name">{`${me.get('first_name')} ${(
                      me.get('last_name') || ''
                    ).charAt(0)}.`}</strong>
                  </div>
                </NavLink>
              </li>
            )}
          <li>
            <NavLink
              activeClassName="active"
              to={`/logout`}
              className="nav-link"
            >
              <img src={'/icons/logout.svg'} alt="" />
              <small>Logout</small>
            </NavLink>
          </li>
        </ul>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  me: state.me
})

export default withRouter(connect(mapStateToProps)(Nav))
