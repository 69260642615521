/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import ApiUtils from '../utils/ApiUtils'
import MeUtils from '../utils/MeUtils'
import ActionTypes from '../constants/ActionTypes'

const { RECEIVE_LOGIN, RECEIVE_LOGOUT, RECEIVE_GET_ACCOUNTS, RECEIVE_LOGIN_CREDS } = ActionTypes

export function login(credentials, cb) {
  return dispatch => {
    const url = `/v1/admin/login`
    ApiUtils.post(credentials, url, (statusCode, body) => {
      if (statusCode === 200) {
        // Needed on setup-2FA and/or OTP page
        dispatch({
          type: RECEIVE_LOGIN_CREDS,
          data: credentials
        })

        // Needed after successfull login
        if (MeUtils.isSuccessfulLogin(body)) {
          dispatch({
            type: RECEIVE_LOGIN,
            data: body.admin_user
          })

          dispatch({
            type: RECEIVE_GET_ACCOUNTS,
            data: body.admin_user
          })
        }
      }

      cb && cb(statusCode, body)
    })
  }
}

export function enbleUser2fa(credentials, cb) {
  return dispatch => {
    const url = `/v1/admin/2fa/enable`
    ApiUtils.post(credentials, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_LOGIN,
          data: body.admin_user
        })

        dispatch({
          type: RECEIVE_GET_ACCOUNTS,
          data: body.admin_user
        })
      }
      cb && cb(statusCode, body)
    })
  }
}

export function verifyOTP(credentials, cb) {
  return dispatch => {
    const url = `/v1/admin/verify_otp`
    ApiUtils.post(credentials, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_LOGIN,
          data: body.admin_user
        })

        dispatch({
          type: RECEIVE_GET_ACCOUNTS,
          data: body.admin_user
        })
      }
      cb && cb(statusCode, body)
    })
  }
}

export function resetPassword(email, cb) {
  return dispatch => {
    const params = { email }
    const url = `/v1/admin/reset_password`
    ApiUtils.post(params, url, cb)
  }
}

export function logout() {
  return dispatch => {
    dispatch({
      type: RECEIVE_LOGOUT,
      data: true
    })
  }
}
