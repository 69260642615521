/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

import ApiUtils from '../utils/ApiUtils'
import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_PLATFORMS,
  RECEIVE_GET_HOTEL_PLATFORM,
  RECEIVE_DELETE_HOTEL_PLATFORM,
  RECEIVE_UPLOAD_HOTEL_PLATFORM_QR_CODE,
  RECEIVE_POST_WEBHOOK
} = ActionTypes

export function loadIntegratedPlatforms(hotelId, cb) {
  return dispatch => {
    const url = `/v1/admin/hotels/${hotelId}/platforms`

    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_PLATFORMS,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function addPlatform(url, params, cb) {
  return dispatch => {
    ApiUtils.post(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_PLATFORM,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function getPlatform(url, params, cb) {
  return dispatch => {
    // /v1/admin/hotels/:hotel_id/platforms/platform_key?page=1
    ApiUtils.get(true, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_PLATFORM,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updatePlatform(url, params, platformKey, cb) {
  return dispatch => {
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_PLATFORM,
          platformKey,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updatePlatformQrCodeImage(platformKey, platformId, data, cb) {
  return dispatch => {
    const reqProps = {}

    const url = `/v1/admin/platforms/${platformKey}/${platformId}/attachments`
    ApiUtils.fileUpload(data, url, reqProps, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_UPLOAD_HOTEL_PLATFORM_QR_CODE,
          platformKey,
          platformId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deletePlatform(url, platformId, cb) {
  return dispatch => {
    ApiUtils.delete(true, url, (statusCode, body) => {
      if (statusCode === 204) {
        dispatch({
          type: RECEIVE_DELETE_HOTEL_PLATFORM,
          platformId: platformId,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function updatePlatformsOrderInBulk(url, data, cb) {
  const params = { platforms_array: data }
  return dispatch => {
    ApiUtils.put(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_GET_HOTEL_PLATFORM,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function getWebhook(url, params, cb) {
  return dispatch => {
    ApiUtils.get(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_POST_WEBHOOK,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}

export function deleteWebhook(url, params, cb) {
  return dispatch => {
    ApiUtils.delete(params, url, (statusCode, body) => {
      if (statusCode === 200) {
        dispatch({
          type: RECEIVE_POST_WEBHOOK,
          data: body
        })
      }

      cb && cb(statusCode, body)
    })
  }
}
