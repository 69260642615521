/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, August 2017
 */

import React from 'react'
import { OrderedMap } from 'immutable'

import CollapsibleListItem from './CollapsibleListItem'

class CollapsibleList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeListsIndexes: {}
    }
  }

  componentDidMount() {
    this.expandAllListsInitially(this.props.data)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.size !== this.props.data.size) {
      this.expandAllListsInitially(this.props.data)
    }
  }

  expandAllListsInitially = data => {
    const pathname = window.location.pathname
    const activeIndexes = new OrderedMap(
      data.map(list => [
        list.itemId,
        pathname.indexOf(list.itemId) !== -1 &&
          pathname.indexOf('/hotel/') !== -1
      ]) // Expand the list by default only if any hotel of account is selected on page load
    )
    this.setState({ activeListsIndexes: activeIndexes.toJS() })
  }

  toggleOne = id => {
    const { activeListsIndexes } = this.state
    const status = !activeListsIndexes[id]
    const activeIndexes = {
      ...activeListsIndexes,
      [id]: status
    }
    this.setState({ activeListsIndexes: activeIndexes })
    this.props.onListToggle(id, status)
  }

  getListItem = (list, index) => {
    const { activeListsIndexes } = this.state
    const status = activeListsIndexes && activeListsIndexes[list.itemId]
    const active = status === undefined ? true : status
    return (
      <CollapsibleListItem
        key={index}
        id={list.itemId}
        list={list}
        toggleOne={this.toggleOne}
        active={active}
      />
    )
  }

  render() {
    const { data } = this.props

    return (
      <div id="collapsible__lists" className="collapsible__list__wrapper">
        {data.map(this.getListItem)}
      </div>
    )
  }
}

export default CollapsibleList
