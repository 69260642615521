/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, July 2018
 */

/* eslint no-mixed-operators: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { enbleUser2fa, verifyOTP } from '../actions/AuthActions'

class Otp2FA extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      otp: '',
      error: null
    }
  }

  componentDidMount() {
    if (this.otpInput) {
      this.otpInput.focus()
    }
  }

  handleVerify = ev => {
    ev.preventDefault()
    const { dispatch, location, history, loginCreds } = this.props
    const { state } = location

    const data = loginCreds.set('otp_attempt', this.state.otp)

    if (state && state.enableUser2fa) {
      dispatch(
        enbleUser2fa(data.toJS(), (statusCode, body) => {
          if (statusCode !== 200) {
            return this.setState({ error: body && body.error })
          }

          history.replace((state && state.nextPathname) || '/')
        })
      )
    } else {
      dispatch(
        verifyOTP(data.toJS(), (statusCode, body) => {
          if (statusCode !== 200) {
            return this.setState({ error: body && body.error })
          }

          history.replace((state && state.nextPathname) || '/')
        })
      )
    }
  }

  handleChange = ev => {
    this.setState({
      otp: ev.target.value,
      error: null
    })
  }

  isPristine = () => {
    return !this.state.otp
  }

  render() {
    const { otp, error } = this.state

    return (
      <div className="otp-2fa">
        <form onSubmit={this.handleVerify} className="otp card" autoComplete="off">
          <div className="page-heading">Two-Factor Authentication</div>
          <div className="heading subtle">
            Please enter the 6-digit OTP code from your Google Authenticator app.
          </div>
          <input
            type="text"
            ref={node => (this.otpInput = node)}
            name="otp"
            onChange={this.handleChange}
            value={otp}
            required
          />

          {error && <div className="error">{error}</div>}

          <button type="submit" disabled={this.isPristine()} className="button">
            Verify
          </button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loginCreds: state.loginCreds
})

export default withRouter(connect(mapStateToProps)(Otp2FA))
