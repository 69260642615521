/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import EditUser from './EditUser'
import Loader from '../components/Loader'
import { loadUsers } from '../actions/SettingActions'

class User extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      selectedUserId: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { dispatch } = this.props

    this.setState({ isLoading: true })
    dispatch(
      loadUsers(-1, -1, (statusCode, body) => {
        this.setState({ isLoading: false })
      })
    )
  }

  render() {
    const { dispatch, match, users } = this.props
    const { selectedUserId } = match && match.params
    const { isLoading } = this.state

    const user = selectedUserId ? users.get(selectedUserId) : new Map()

    if (isLoading) return <Loader isLoading={isLoading} />

    return user ? <EditUser dispatch={dispatch} user={user} /> : null
  }
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps)(User)
