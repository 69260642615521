/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, September 2019
 */

/* eslint no-mixed-operators: "off" */
/* eslint jsx-a11y/alt-text: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import HotelIntegration from './HotelIntegration'
import HotelIntegrationConstants from '../constants/HotelIntegrationConstants'

class IntegrationsList extends React.Component {
  static propTypes = {
    integrations: PropTypes.object
  }

  addNewIntegration = integration => ev => {
    this.props.selectIntegration(integration)
  }

  render() {
    const { integrations } = this.props

    return (
      <div>
        <h2 className="tmargin0em">Add New Integration </h2>

        <ul className="integrations-list">
          {integrations.map(integration => (
            <li key={integration.get('integrationKey')} className="item">
              <img
                src={integration.get('logoUrl')}
                className={`logo ${integration.get('integrationKey')}`}
              />
              <button
                className="button button-small create-button"
                onClick={this.addNewIntegration(integration)}
              >
                Add {integration.get('uiName')}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

class AddNewIntegration extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      integrations: HotelIntegrationConstants
    }
  }

  selectIntegration = integration => {
    this.setState({ integration: integration.merge(integration) })
  }

  render() {
    const { dispatch, match } = this.props
    const { selectedAccountId, selectedHotelId } = match && match.params
    const { integrations, integration } = this.state

    return (
      <div className="settings-container display-flex-column">
        <div className="add-new-integration">
          {integration ? (
            <HotelIntegration
              dispatch={dispatch}
              selectedAccountId={selectedAccountId}
              selectedHotelId={selectedHotelId}
              integration={integration}
            />
          ) : (
            <IntegrationsList
              integrations={integrations}
              selectIntegration={this.selectIntegration}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect()(AddNewIntegration)
