/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, April 2018
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_USERS,
  RECEIVE_GET_HOTEL_USER,
  RECEIVE_DELETE_HOTEL_USER
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data, hotelUserId } = action

  switch (type) {
    case RECEIVE_GET_HOTEL_USERS:
      const newUsers = new OrderedMap(
        data.users.map(user => [user.id, fromJS(user)])
      )
      return state.concat(newUsers)

    // case RECEIVE_GET_CREATE_HOTEL_USERS:
    //   const newHotelUsers = new OrderedMap(
    //     data.users.map(user => [user.id, fromJS(user)])
    //   )
    //   return state.merge(newHotelUsers)

    case RECEIVE_GET_HOTEL_USER:
      return state.set(data.user.id, fromJS(data.user))

    case RECEIVE_DELETE_HOTEL_USER:
      return state.remove(hotelUserId)

    default:
      return state
  }
}
