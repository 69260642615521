import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import alertify from 'alertifyjs'
import ReactModal from 'react-modal'

import Loader from './Loader'
import {
  createHotelSalutations,
  createSalutation,
  loadHotelSalutations,
  loadSalutations
} from '../actions/HotelActions'
import ModalStyle from '../constants/ModalStyle'

class HotelSalutations extends React.Component {
  static propTypes = {
    hotel: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSaving: false,
      mappedSalutations: [],
      isModalOpen: false
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props
    const { selectedHotelId } = match && match.params

    this.setState({ isLoading: true })
    dispatch(
      loadSalutations((statusCode, body) => {
        this.setState({ isLoading: false })

        if (statusCode !== 200) return

        const salutations = body.salutations

        dispatch(
          loadHotelSalutations(selectedHotelId, (statusCode, body) => {
            if (
              statusCode === 200 &&
              body &&
              body.hotel_salutations &&
              body.hotel_salutations.length &&
              salutations.length
            ) {
              const salutationIds = body.hotel_salutations.map(
                hotel_salutation => hotel_salutation.salutation_id
              )
              const addedSalutations = []
              salutationIds.map(salutationId => {
                addedSalutations.push(
                  salutations.find(salutation => salutation.id === salutationId)
                )
              })
              this.setState({ mappedSalutations: addedSalutations })
            }
          })
        )
      })
    )
  }

  isSalutationMappedToHotel = salutation => {
    const { mappedSalutations } = this.state
    return mappedSalutations.includes(salutation)
  }

  mapSalutationToHotel = salutation => {
    const updatedSalutations = [...this.state.mappedSalutations]
    if (updatedSalutations.includes(salutation)) {
      const itemIndex = updatedSalutations.indexOf(salutation)
      updatedSalutations.splice(itemIndex, 1)
      this.setState({ mappedSalutations: updatedSalutations })
    } else {
      updatedSalutations.push(salutation)
      this.setState({ mappedSalutations: updatedSalutations })
    }
  }

  mapAllSalutationsToHotel = () => {
    const { salutations } = this.props
    const { mappedSalutations } = this.state
    if (salutations.get('salutations').length === mappedSalutations.length) {
      this.setState({ mappedSalutations: [] })
      return
    }
    this.setState({ mappedSalutations: salutations.get('salutations') })
  }

  isAllSalutationsMappedToHotel = () => {
    const { salutations } = this.props
    const { mappedSalutations } = this.state
    if (!salutations || !salutations.get('salutations')) return false
    return salutations.get('salutations').length === mappedSalutations.length
  }

  handleSubmit = ev => {
    ev.preventDefault()
    const { match, dispatch } = this.props
    const { selectedHotelId } = match && match.params
    const { mappedSalutations } = this.state
    const formattedSalutations = { salutations: mappedSalutations.map(salutation => salutation.id) }
    this.setState({ isSaving: true })
    dispatch(
      createHotelSalutations(selectedHotelId, formattedSalutations, (statusCode, body) => {
        this.setState({ isSaving: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return alertify.error((body && body.error) || `Error mapping salutation!`)
        }

        alertify.success(`Salutation successfully mapped.`)
      })
    )
  }

  isPristine = () => {
    const { isSaving, mappedSalutations } = this.state
    return isSaving || mappedSalutations.length === 0
  }

  onCloseModal = () => this.setState({ isModalOpen: false })

  openSalutationsModal = () => this.setState({ isModalOpen: true })

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSaveSalutation = e => {
    e.preventDefault()
    const { dispatch } = this.props
    const { salutationValue, salutationCodeValue } = this.state
    const data = {
      value: salutationValue,
      code_value: salutationCodeValue
    }
    this.setState({ isSavingSalutation: true })
    dispatch(
      createSalutation(data, (statusCode, body) => {
        this.setState({ isSavingSalutation: false })
        if (statusCode !== 200 && statusCode !== 201) {
          return alertify.error((body && body.error) || `Error creating salutation!`)
        }

        alertify.success(`Salutation successfully created.`)
        this.setState({ isModalOpen: false, salutationValue: '', salutationCodeValue: '' })
      })
    )
  }

  isSalutationPristine = () => {
    const { isSavingSalutation, salutationValue, salutationCodeValue } = this.state
    return isSavingSalutation || !salutationValue
  }

  render() {
    const { salutations } = this.props
    const {
      isLoading,
      mappedSalutations,
      isModalOpen,
      salutationValue,
      salutationCodeValue
    } = this.state

    const { onRequestClose, ...props } = this.props

    ModalStyle.content.width = '35em'
    ModalStyle.content.height = '20em'
    ModalStyle.content.animation = 'zoomInModal 0.35s linear'

    return (
      <div className="settings-container hotel-salutations display-flex-column">
        <Loader isLoading={isLoading} />
        <div className="title-container">
          <h2 className="tmargin0em">Salutations</h2>
          <button className="button pull-right" onClick={this.openSalutationsModal}>
            Add New Salutations
          </button>
        </div>
        <div className="salutations-container">
          <div className="salutations-box-left">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="title">All Salutations</div>
              <div className={`select-field selected`} onClick={this.mapAllSalutationsToHotel}>
                {this.isAllSalutationsMappedToHotel() && (
                  <img src={`/icons/select-tick.svg`} alt="" />
                )}
              </div>
            </div>
            <div style={{ overflow: 'scroll' }}>
              {salutations &&
                salutations.get('salutations') &&
                salutations.get('salutations').map(salutation => {
                  return (
                    <div className="salutations-list">
                      <div
                        className={`select-field selected`}
                        onClick={() => this.mapSalutationToHotel(salutation)}
                      >
                        {this.isSalutationMappedToHotel(salutation) && (
                          <img src={`/icons/select-tick.svg`} alt="" />
                        )}
                      </div>
                      <div key={salutation.id}>{salutation.value}</div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="salutations-box-right">
            <div className="title">Mapped Salutations</div>
            <div style={{ overflow: 'scroll' }}>
              {mappedSalutations.map(salutation => {
                return <div className="salutations-list">{salutation.value}</div>
              })}
            </div>
          </div>
        </div>
        <button
          disabled={this.isPristine()}
          type="submit"
          className="button submit-button"
          onClick={this.handleSubmit}
        >
          Save
        </button>

        <ReactModal {...props} style={ModalStyle} ariaHideApp={false} isOpen={isModalOpen}>
          <div className="modal-popup create-business-type-modal">
            <header className="modal-popup-header">
              <h3>Create New Salutation</h3>
              <button onClick={this.onCloseModal} className="close">
                <img src="icons/close_black_24px.svg" className="icon" alt="Close" />
              </button>
            </header>

            <div
              className="modal-popup-body"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <fieldset>
                <legend>Salutation</legend>
                <input
                  type="text"
                  name="salutationValue"
                  className="input-field"
                  value={salutationValue}
                  onChange={this.handleChange}
                  placeholder="Mr. Mrs. etc"
                />
              </fieldset>

              <fieldset>
                <legend>Code Value</legend>
                <input
                  type="text"
                  name="salutationCodeValue"
                  className="input-field"
                  value={salutationCodeValue}
                  onChange={this.handleChange}
                  placeholder="001, 002 etc"
                />
              </fieldset>

              <div className="actions" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  disabled={this.isSalutationPristine()}
                  className="button submit-button"
                  onClick={this.handleSaveSalutation}
                >
                  Save
                </button>
                <button type="cancel" className="button" onClick={this.onCloseModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  salutations: state.salutations
})

export default connect(mapStateToProps)(HotelSalutations)
