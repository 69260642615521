/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import React from 'react'
import { connect } from 'react-redux'

import EditUser from './EditUser'

class CreateNewUser extends React.Component {
  render() {
    const { dispatch, user } = this.props

    return <EditUser dispatch={dispatch} user={user} />
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(CreateNewUser)
